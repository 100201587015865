<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Departamento: {{ myProfileData.dept_name }}</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item">
            <router-link to="/">Inicio</router-link>
          </li>
          <li class="breadcrumb-item">Professor</li>
          <li class="breadcrumb-item active">Perfil</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8 col-lg-12 col-md-12">
      <div class="card">
        <div class="row">
          <div class="col-md-4">
            <div class="card-header">
              <h3 class="card-title">Perfil pessoal</h3>
            </div>
            <div class="card-body">
              <!-- <img src="" class="img-card img-fluid" alt="" />
              <button class="btn btn-md btn-primary btn-block">
                carreagar foto de perfil
              </button> -->
              <!-- <hr /> -->
              <br />
              <ul class="list-unstyled">
                <li class="mb-2">
                  <h4 class="">{{ myProfileData.fullName }}</h4>
                  <small class="text-center">{{ myProfileData.email }}</small>
                </li>
                <li class="mb-2">
                  <strong class="">Data de Nascimento: </strong
                  ><span class="">{{ myProfileData.birthDate }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Endere&ccedil;o: </strong
                  ><span class="">{{ myProfileData.address_location }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">G&eacute;nero: </strong
                  ><span class="">{{ myProfileData.gender }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Formado na (o): </strong
                  ><span class="">{{
                    myProfileData.last_school_frequency
                  }}</span>
                </li>
              </ul>
              <button class="btn btn-warning btn-md">
                Actualizar dados do perfil
              </button>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card-header">
              <h3 class="card-title">Informa&ccedil;&otilde;es adicionais</h3>
            </div>
            <div class="card-body">
              <table class="table">
                <tr>
                  <th style="width: 20%">Disciplinas que leciona</th>
                  <td>
                    <span class="justify-content-between">
                      <span
                        class="badge badge-primary badge-lg m-1"
                        v-for="(disc, index) in disciplinas"
                        :key="index"
                        >{{ disc.subject_name }}</span
                      >
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Turmas alocadas</th>
                  <td>
                    <span class="justify-content-between">
                      <span
                        class="badge badge-primary badge-lg m-1"
                        v-for="(tur, index) in turmas"
                        :key="index"
                      >
                        {{ tur.classmate_name }}
                      </span>
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12 col-xxl-8 col-lg-8">
      <div class="card">
        <div class="card-header justify-content-center">
          <h2 class="card-title">Actividades Internas e arquivos anexados</h2>
        </div>
        <div class="card-body">
          <table class="table table-bordeless table-stripped">
            <thead>
              <tr>
                <th>Ultimas Aulas dada</th>
                <th>Avaliacoes</th>
                <th>Nr total de Alunos</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Anexos</td>
                <td>Anexos</td>
                <td>3400</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <Loading :loadingData="loading"></Loading>
</template>
<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import store from "@/store";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";
import TeacherDashStoreModule from "./TeacherDashStoreModule";
import Loading from "@/components/Loading.vue";

const person = ref({});
const departamento = ref({});
const user = ref({});
const disciplinas = ref({});
const turmas = ref({});
const myProfileData = ref({});
const loading = ref(false);
async function getTeacherProfile() {
  try {
    loading.value = true;

    const profile = JSON.parse(localStorage.getItem("myProfile"));

    if (profile == null) {
      const resp = await TeacherDashStoreModule.actions.getMyDatas();

      myProfileData.value = resp.data.data;
      user.value = resp.data.user;
      disciplinas.value = resp.data.data.classmate_subject;
      turmas.value = resp.data.data.classmate_subject;

      localStorage.setItem("myProfile", JSON.stringify(resp.data.data));
      return;
    }

    myProfileData.value = profile;
    user.value = profile.user;
    disciplinas.value = profile.classmate_subject;
    turmas.value = profile.classmate_subject;
  } catch (error) {
  } finally {
    loading.value = false;
  }
}

async function getFormulasSubject() {
  const resp = await TeacherDashStoreModule.actions.getClassmateFormula();
  // console.log(resp.data.data);
  store.dispatch("weightSubject/add_weights", resp.data.data);
}

onMounted(() => {
  getTeacherProfile();
  getFormulasSubject();
});
</script>
