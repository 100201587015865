import axios from "axios";

 
export default {
  namespaced: true,

  state: {
    assessmentResults: [],

  },

  getters: {},
  mutations: {

    Set_assessmentResult(state, payload){
      state.assessmentResults = payload;
    }
  },
  actions: {
    getAssessmentResults(){
        // axios.get('get-')
    },
    assessments({ commit }, payload) {
      commit("Set_assessmentResult", payload);
    },
  },
};
