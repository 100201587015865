import axios from "axios";
import authHeader from "@/Api/AuthHeader";


const URL_API =  process.env.VUE_APP_URL_API;


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createCourseDisciplina(ctx, DataSubject) {
      return new Promise((resolve, reject) => {
        axios
          .post(URL_API+"disciplinas", DataSubject, { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getSubjects() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+"getAllSubject", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getCourses() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+"getAllCourses", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },


    viewSubject(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+"disciplinas/" + id, { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    updateSubject(ctx, dataSubject) {
      return new Promise((resolve, reject) => {
        axios
          .put(URL_API+"disciplinas/" + dataSubject.id, dataSubject.data, {
            headers: authHeader(),
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    deleteSubject(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(URL_API+"disciplinas/" + id, { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
