<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashboard</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Jassa</a></li>
            <li class="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>
    <!-- end page-title -->
    <div class="row">
      <div class="col-sm-6 col-xl-3 disabled" v-if="dashboardArea == true">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-cube-outline bg-primary text-white"></i>
            </div>
            <div>
              <h5 class="font-16" >Sessoes Activas</h5>
            </div>
            <h3 class="mt-4">43,225</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                style="width: 75%"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">75%</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3 disabled" v-else>
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-cube-outline bg-primary text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Sessoes Activas</h5>
            </div>
            <h3 class="mt-4 text-line-through">0,00</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-primary"
                role="progressbar"
                style="width: 75%"
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">75%</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3" v-if="dashboardArea == true">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="icon-spread bg-warning text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Inscrições/renovações</h5>
            </div>
            <h3 class="mt-4">447</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                style="width: 68%"
                aria-valuenow="68"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">68%</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3" v-else>
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="icon-spread bg-warning text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Inscrições/renovações</h5>
            </div>
            <h3 class="mt-4 text-line-through">0</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-warning"
                role="progressbar"
                style="width: 68%"
                aria-valuenow="68"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">68%</span>
            </p>
          </div>
          <div class="sem-permissao"></div>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3" v-if="dashboardArea == true">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="fas fa-users bg-danger text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Total Estudantes Inactivos</h5>
            </div>
            <h3 class="mt-4">76,382</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-danger"
                role="progressbar"
                style="width: 88%"
                aria-valuenow="88"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">88%</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3" v-else>
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="mdi mdi-briefcase-check bg-success text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Total Receitas</h5>
            </div>
            <h3 class="mt-4 text-line-through">0,00</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                style="width: 88%"
                aria-valuenow="88"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">88%</span>
            </p>
          </div>
          <div class="sem-permissao"></div>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3" v-if="dashboardArea == true">
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="fas fa-users bg-success text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Total de Estudantes activos</h5>
            </div>
            <h3 class="mt-4">86%</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-success"
                role="progressbar"
                style="width: 82%"
                aria-valuenow="82"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">82%</span>
            </p>
          </div>
        </div>
      </div>

      <div class="col-sm-6 col-xl-3" v-else>
        <div class="card">
          <div class="card-heading p-4">
            <div class="mini-stat-icon float-right">
              <i class="fas fa-users bg-succcess text-white"></i>
            </div>
            <div>
              <h5 class="font-16">Total de Estudantes activos</h5>
            </div>

            <h3 class="mt-4 text-line-through">0,00</h3>
            <div class="progress mt-4" style="height: 4px">
              <div
                class="progress-bar bg-succcess"
                role="progressbar"
                style="width: 82%"
                aria-valuenow="82"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <p class="text-muted mt-2 mb-0">
              Previous period<span class="float-right">82%</span>
            </p>
          </div>
          <div class="sem-permissao"></div>
        </div>
      </div>
    </div>

    <!-- end row -->
    <div class="row">
      <div class="col-xl-4" v-if="dashboardArea == true" id="sessions">
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Actividades Recentes</h4>
            <ol class="activity-feed mb-0">
              <li class="feed-item">
                <div class="feed-item-list">
                  <p class="text-muted mb-1">Now</p>
                  <p class="font-15 mt-0 mb-0">
                    Jassa magna Jassa, risus posted a new article:
                    <b class="text-primary">Forget UX Rowland</b>
                  </p>
                </div>
              </li>
              <li class="feed-item">
                <p class="text-muted mb-1">Yesterday</p>
                <p class="font-15 mt-0 mb-0">
                  Jassa posted a new article:
                  <b class="text-primary">Designer Alex</b>
                </p>
              </li>
              <li class="feed-item">
                <p class="text-muted mb-1">2:30PM</p>
                <p class="font-15 mt-0 mb-0">
                  Jassa, Jassa, Jassa Commented
                  <b class="text-primary"> Developer Moreno</b>
                </p>
              </li>
              <li class="feed-item pb-1">
                <p class="text-muted mb-1">12:48 PM</p>
                <p class="font-15 mt-0 mb-2">
                  Jassa, Jassa Commented <b class="text-primary">UX Murphy</b>
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div class="col-xl-4" v-else>
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4 text-line-through">
              Actividades Recentes
            </h4>
            <ol class="activity-feed mb-0">
              <li class="feed-item">
                <div class="feed-item-list">
                  <p class="font-15 mt-0 mb-0">Sem Dados</p>
                </div>
              </li>
            </ol>
          </div>
          <div class="sem-permissao"></div>
        </div>
      </div>
    </div>
    <!-- START ROW -->
    <!-- <div class="row">
      <div class="col-xl-12">
        <div class="card m-b-30">
          <div class="card-body">
            <h4 class="mt-0 header-title mb-4">Active Deals</h4>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Location</th>
                    <th scope="col" colspan="2">Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Jassa</td>
                    <td><span class="badge badge-success">Delivered</span></td>
                    <td>$9,420,000</td>
                    <td>
                      <div>
                        <img
                          src="assets/images/user-2.jpg"
                          alt=""
                          class="thumb-md rounded-circle mr-2"
                        />
                        Jassa
                      </div>
                    </td>
                    <td>Ludhiana</td>
                    <td>15/1/2021</td>
                    <td>
                      <div>
                        <a href="#" class="btn btn-primary btn-sm">Edit</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Jassa</td>
                    <td><span class="badge badge-warning">Pending</span></td>
                    <td>$3,120,000</td>
                    <td>
                      <div>
                        <img
                          src="assets/images/user-3.jpg"
                          alt=""
                          class="thumb-md rounded-circle mr-2"
                        />
                        Jassa
                      </div>
                    </td>
                    <td>Ludhiana</td>
                    <td>16/1/2021</td>
                    <td>
                      <div>
                        <a href="#" class="btn btn-primary btn-sm">Edit</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Jassa</td>
                    <td><span class="badge badge-success">Delivered</span></td>
                    <td>$6,360,000</td>
                    <td>
                      <div>
                        <img
                          src="assets/images/user-4.jpg"
                          alt=""
                          class="thumb-md rounded-circle mr-2"
                        />
                        Jassa
                      </div>
                    </td>
                    <td>Ludhiana</td>
                    <td>17/1/2021</td>
                    <td>
                      <div>
                        <a href="#" class="btn btn-primary btn-sm">Edit</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Jassa</td>
                    <td><span class="badge badge-danger">Cancel</span></td>
                    <td>$5,200,000</td>
                    <td>
                      <div>
                        <img
                          src="assets/images/user-5.jpg"
                          alt=""
                          class="thumb-md rounded-circle mr-2"
                        />
                        Jassa
                      </div>
                    </td>
                    <td>Ludhiana</td>
                    <td>18/1/2021</td>
                    <td>
                      <div>
                        <a href="#" class="btn btn-primary btn-sm">Edit</a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Jassa</td>
                    <td><span class="badge badge-success">Delivered</span></td>
                    <td>$7,250,000</td>
                    <td>
                      <div>
                        <img
                          src="assets/images/user-6.jpg"
                          alt=""
                          class="thumb-md rounded-circle mr-2"
                        />
                        Jassa
                      </div>
                    </td>
                    <td>Ludhiana</td>
                    <td>19/1/2021</td>
                    <td>
                      <div>
                        <a href="#" class="btn btn-primary btn-sm">Edit</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- END ROW -->
  </div>
</template>


<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const store = useStore();

const dashboardArea = store.state.auth.role_permission.dashboard.read;

// const driverObj = driver();
// driverObj.highlight({
//   element: '#sessions',
//   popover: {
//     title: 'Animated Tour Example',
//     description: 'Here is the code example showing animated tour. Let\'s walk you through it.',
//     side: "right",
//     align: 'start'
//   }
// });
</script>