<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Avaliação</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Professor</li>
          <li class="breadcrumb-item active">Avaliação</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-xxl-8 col-lg-8">
      <div class="card">
        <div class="card-body">
          <div class="pt-3">
            <div class="settings-form">
              <h4 class="text-primary mb-3">
                Lançamento das Notas de Frequência
              </h4>
              <span><strong>Disciplina</strong>: {{ subject.name }}</span>
              <div class="float-right col-lg-3 mb-1">
                <select
                  class="form-control"
                  style="border-radius: 16px"
                  @change="onChangeAssessment_type"
                  v-model="assessment_type"
                >
                  <option value="">Selecionar avaliação</option>
                  <option
                    :value="f.assessment_type"
                    v-for="(f, index) in formulas"
                    :key="index"
                  >
                    {{ f.assessment_type }}
                  </option>
                </select>
              </div>

              <form @submit.prevent="submitAssessments">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th id="mainHeaderTable" scope="col">
                          Nº DO ESTUDANTE
                        </th>
                        <th id="mainHeaderTable" scope="col">
                          NOME DO ESTUDANTE
                        </th>
                        <th id="mainHeaderTable" scope="col">MINI-TESTE</th>
                        <th id="mainHeaderTable" scope="col">AVAL-1</th>
                        <th id="mainHeaderTable" scope="col">AVAL-2</th>
                        <th id="mainHeaderTable" scope="col">TRAB-1</th>
                        <th id="mainHeaderTable" scope="col">TRAB-2</th>
                        <th id="mainHeaderTable" scope="col">TRAB-3</th>
                        <th id="mainHeaderTable" scope="col">MÉD. FREQ</th>
                        <th id="mainHeaderTable" scope="col">
                          RESULTADO FREQUÊNCIA
                        </th>
                        <!-- <th
                          id="mainHeaderTable"
                          scope="col"
                          v-for="form in labels"
                          :key="form"
                        >
                          {{ form.assessment_type }}
                        </th> -->
                        <th id="mainHeaderTable" scope="col">EX. NORMAL</th>
                        <th id="mainHeaderTable" scope="col">RECORRÊNCIA</th>
                        <th id="mainHeaderTable" scope="col">MÉD. FINAL</th>
                        <th id="mainHeaderTable" scope="col">
                          RESULTADO FINAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(students, index) in dataStudents"
                        :key="index"
                      >
                        <td>{{ students.student_card_id }}</td>
                        <td>{{ students.fullName }}</td>

                        <td>
                          <input
                            v-if="students.miniTeste != undefined"
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.mini_teste_weight"
                            @input="weight_mini_teste(students)"
                            v-model="students.miniTeste"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                          <input
                            v-else
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.mini_teste_weight"
                            @input="weight_mini_teste(students)"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                        </td>
                        <td>
                          <input
                            v-if="students.teste1 != undefined"
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.teste_1_weight"
                            @input="weight_teste_1(students)"
                            v-model="students.teste1"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                          <input
                            v-else
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.teste_1_weight"
                            @input="weight_teste_1(students)"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                        </td>
                        <td>
                          <input
                            v-if="students.teste2 != undefined"
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.teste_2_weight"
                            @input="weight_teste_2(students)"
                            v-model="students.teste2"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                          <input
                            v-else
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.teste_2_weight"
                            @input="weight_teste_2(students.student_id)"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                        </td>

                        <td>
                          <input
                            v-if="students.trabalho1 != undefined"
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.trabalho_1_weight"
                            @input="weight_trabalho_1(students)"
                            v-model="students.trabalho1"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                          <input
                            v-else
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.trabalho_1_weight"
                            @input="weight_trabalho_1(students)"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                        </td>
                        <td>
                          <input
                            v-if="students.trabalho2 != undefined"
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.trabalho_2_weight"
                            @input="weight_trabalho_2(students)"
                            v-model="students.trabalho2"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                          <input
                            v-else
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.trabalho_2_weight"
                            @input="weight_trabalho_2(students)"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                        </td>
                        <td>
                          <input
                            v-if="students.trabalho3 != undefined"
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.trabalho_3_weight"
                            @input="weight_trabalho_3(students)"
                            v-model="students.trabalho3"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                          <input
                            v-else
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.trabalho_3_weight"
                            @input="weight_trabalho_3(students)"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            :value="students_average(students).average"
                            style="width: 50px; font-size: 12px; padding: 10px"
                          />
                        </td>

                        <td>
                          <input
                            v-if="students.resultaFreq != undefined"
                            type="text"
                            class="form-control"
                            disabled
                            :value="students_average(students).result"
                            style="width: 140px; font-size: 12px; padding: 10px"
                          />
                        </td>
                        <td>
                          <input
                            v-if="students.exame_normal != undefined"
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.exame_normal_weight"
                            @input="stundentAssessmentExame(students)"
                            v-model="students.exame_normal"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                          <input
                            v-else
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.exame_normal_weight"
                            @input="stundentAssessmentExame(students)"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                        </td>
                        <td>
                          <input
                            v-if="students.recorrencia != undefined"
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.recorrencia_weight"
                            @input="stundentAssessmentExame(students)"
                            v-model="students.recorrencia"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                          <input
                            v-else
                            type="text"
                            class="form-control"
                            :disabled="disabled_field.recorrencia_weight"
                            @input="stundentAssessmentExame(students)"
                            style="width: 40px; font-size: 12px; padding: 10px"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            :value="
                              stundentAssessmentExame(students).finalAvarenge
                            "
                            style="width: 50px; font-size: 12px; padding: 10px"
                          />
                        </td>

                        <td>
                          <input
                            type="text"
                            class="form-control"
                            disabled
                            style="width: 140px; font-size: 12px; padding: 10px"
                            :value="
                              stundentAssessmentExame(students).textResult
                            "
                          />
                        </td>

                        <!-- <td>
                          <span @click="updateStudentAssessment(students)"
                            ><a
                              href="javascript:void(0)"
                              style="text-decoration: underline"
                            >
                              Actualizar</a
                            ></span
                          >
                        </td> -->
                      </tr>
                      <tr v-if="dataStudents.length == 0">
                        <td colspan="14" style="text-align: center">
                          <span style="font-style: italic">
                            Ainda sem estudantes
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <Button
                  type="success"
                  class="float-right"
                  @click.prevent="submitAssessments"
                  v-if="dataStudents.length > 0"
                >
                  Salvar
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Actualizar Nota do Estudante</h5>
          <button
            type="button"
            @click="closeModal"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="float-right">
          <div class="float-right col-lg-3 mt-2">
            <select
              class="form-control"
              style="border-radius: 16px"
              @change="onChangeAssessment_type"
              v-model="assessment_type"
            >
              <option value="">Selecionar avaliação</option>
              <option
                :value="f.assessment_type"
                v-for="(f, index) in formulas"
                :key="index"
              >
                {{ f.assessment_type }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitAssessments">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Codigo Estudante</th>
                    <th scope="col">Estudante</th>
                    <th scope="col" v-for="form in labels" :key="form">
                      {{ form.assessment_type }}
                    </th>
                    <th scope="col">Media</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(students, index) in DataAssessemntStudent"
                    :key="index"
                  >
                    <td>{{ students.id }}</td>
                    <td>{{ students.fullName }}</td>

                    <td>
                      <input
                        v-if="students.miniTeste != undefined"
                        type="text"
                        class="form-control col-lg-6"
                        :disabled="disabled_field.mini_teste_weight"
                        @input="weight_mini_teste(students)"
                        v-model="students.miniTeste"
                      />
                      <input
                        v-else
                        type="text"
                        class="form-control col-lg-6"
                        :disabled="disabled_field.mini_teste_weight"
                        @input="weight_mini_teste(students)"
                      />
                    </td>
                    <td>
                      <input
                        v-if="students.teste1 != undefined"
                        type="text"
                        class="form-control col-lg-6"
                        :disabled="disabled_field.teste_1_weight"
                        @input="weight_teste_1(students)"
                        v-model="students.teste1"
                      />
                      <input
                        v-else
                        type="text"
                        class="form-control col-lg-6"
                        :disabled="disabled_field.teste_1_weight"
                        @input="weight_teste_1(students)"
                      />
                    </td>
                    <td>
                      <input
                        v-if="students.teste2 != undefined"
                        type="text"
                        class="form-control col-lg-6"
                        :disabled="disabled_field.teste_2_weight"
                        @input="weight_teste_2(students)"
                        v-model="students.teste2"
                      />
                      <input
                        v-else
                        type="text"
                        class="form-control col-lg-6"
                        :disabled="disabled_field.teste_2_weight"
                        @input="weight_teste_2(students.student_id)"
                      />
                    </td>
                    <td>
                      <input
                        v-if="students.teste3 != undefined"
                        type="text"
                        class="form-control col-lg-6"
                        :disabled="disabled_field.teste_3_weight"
                        @input="weight_teste_3(students)"
                        v-model="students.teste3"
                      />

                      <input
                        v-else
                        type="text"
                        class="form-control col-lg-6"
                        :disabled="disabled_field.teste_3_weight"
                        @input="weight_teste_3(students)"
                      />
                    </td>
                    <td>
                      <input
                        v-if="students.trabalho1 != undefined"
                        type="text"
                        class="form-control col-lg-6"
                        :disabled="disabled_field.trabalho_1_weight"
                        @input="weight_trabalho_1(students)"
                        v-model="students.trabalho1"
                      />
                      <input
                        v-else
                        type="text"
                        class="form-control col-lg-6"
                        :disabled="disabled_field.trabalho_1_weight"
                        @input="weight_trabalho_1(students)"
                      />
                    </td>
                    <td>
                      <input
                        v-if="students.trabalho1 != undefined"
                        type="text"
                        class="form-control col-lg-6"
                        disabled
                        @input="weight_trabalho_2(students)"
                        v-model="students.trabalho2"
                      />
                      <input
                        v-else
                        type="text"
                        class="form-control col-lg-6"
                        disabled
                        @input="weight_trabalho_2(students)"
                      />
                    </td>
                    <td>
                      <input
                        v-if="students.trabalho3 != undefined"
                        type="text"
                        class="form-control col-lg-6"
                        disabled
                        @input="weight_trabalho_3(students)"
                        v-model="students.trabalho3"
                      />
                      <input
                        v-else
                        type="text"
                        class="form-control col-lg-6"
                        disabled
                        @input="weight_trabalho_3(students)"
                      />
                    </td>
                    <td>
                      <span>{{ students_average(students) }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <Button
            type="success"
            :loading="loading"
            class="float-right"
            @click.prevent="updateStudentAssessments"
          >
            Salvar
          </Button>
          <button
            @click="closeModal"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Nao
          </button>
        </div>
      </div>
    </div>
  </div>
  <Loading :loadingData="loading"></Loading>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import TeacherDashStoreModule from "../TeacherDashStoreModule";
import toastr from "toastr";
import store from "@/store";
import student from "@/store/student";
import Loading from "../../../components/Loading.vue";
import SubjectStoreModule from "@/pages/subject/SubjectStoreModule";

const dataStudents = ref([]);
const assessment_type = ref("TESTE-1");
const labels = ref([]);
const assessmentResults = ref([]);
const isDelete = ref(false);
const assessment = ref([]);
const loading = ref(false);
const DataAssessemntStudent = ref({});
const weight = ref({
  mini_teste_weight: 0,
  teste_1_weight: 0,
  teste_2_weight: 0,
  teste_3_weight: 0,
  trabalho_1_weight: 0,
  trabalho_2_weight: 0,
  trabalho_3_weight: 0,
});

// const results = ref(store.state.auth.assessmentResults.assessmentResults);

const assessmentStudentResult = computed(
  () => store.state.assessmentResults.assessmentResults
);

const disabled_field = ref({
  mini_teste_weight: true,
  teste_1_weight: true,
  teste_2_weight: true,
  teste_3_weight: true,
  trabalho_1_weight: true,
  trabalho_2_weight: true,
  trabalho_3_weight: true,
  exame_normal_weight: true,
  recorrencia_weight: true,
});

const route = useRoute();
const formulas = ref([]);
const subject = ref({});
const final_result = ref("");
const selectedAssessment = ref(null);
function onChangeAssessment_type(event) {
  console.log(event.target.value);
  switchTestToEnable(event.target.value);
}

/**
 * Buscar a disciplina em avaliacao
 * Author:Octavio Manuel Cossa
 *
 */
async function getSubjectAvaluation() {
  try {
    loading.value = true;

    const resp = await SubjectStoreModule.actions.viewSubject(
      route.params.subject_id
    );
    subject.value = resp.data.data;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

/***
 * Data: 04-04-24
 * author: Octavio Manuel Cossa
 * calcula media final(exame normal e recorrencia)
 */
function stundentAssessmentExame(student) {
  let averange = 0;
  let finalAverange = null;
  let textResult = "";

  averange = student.exame_normal * 70 + students_average(student).average * 70;
  if (students_average(student).average >= 14) {
    return { finalAvarenge: null, textResult: null };
  }
  finalAverange = averange / 140;

  if (finalAverange < 10) {
    textResult = "Reprovado";
  } else {
    textResult = "Aprovado";
  }

  console.log("media final", finalAverange);
  return { finalAvarenge: finalAverange, textResult: textResult };
}

/**
 *
 * calcula  media notas do estudante
 * author: Octavio Manuel Cossa
 * */

function students_average(students) {
  let average = 0;
  let result = "";
  let percentage = null;
  let sumOfPercentage = null;
  labels.value.forEach((element) => {
    if (element.percentage === null) {
      percentage = 0;
    } else {
      percentage = element.percentage;
    }
    sumOfPercentage += parseInt(percentage);
  });

  if (students.miniTeste === undefined) {
    miniTeste = 0;
    students.miniTeste = {
      assessment_value: 0,
      assessment_percentage: store.state.weightSubject.subject_weight.miniTeste,
    };
  }

  if (students.teste1 === undefined) {
    students.teste1 = {
      assessment_value: 0,
      assessment_percentage: store.state.weightSubject.subject_weight.teste1,
    };
  }

  if (students.teste2 === undefined) {
    students.teste2 = {
      assessment_value: 0,
      assessment_percentage: store.state.weightSubject.subject_weight.teste2,
    };
  }

  if (students.trabalho1 === undefined) {
    students.trabalho1 = {
      assessment_value: 0,
      assessment_percentage: store.state.weightSubject.subject_weight.trabalho1,
    };
  }

  if (students.trabalho2 === undefined) {
    students.trabalho2 = {
      assessment_value: 0,
      assessment_percentage: store.state.weightSubject.subject_weight.trabalho2,
    };
  }

  if (students.trabalho3 === undefined) {
    students.trabalho3 = {
      assessment_value: 0,
      assessment_percentage: store.state.weightSubject.subject_weight.trabalho3,
    };
  }

  average = (
    (parseFloat(students.miniTeste) *
      parseInt(store.state.weightSubject.subject_weight.miniTeste) +
      parseFloat(students.teste1) *
        parseInt(store.state.weightSubject.subject_weight.teste1) +
      parseFloat(students.teste2) *
        parseInt(store.state.weightSubject.subject_weight.teste2)) /
    (parseInt(store.state.weightSubject.subject_weight.miniTeste) +
      parseInt(store.state.weightSubject.subject_weight.teste1) +
      parseInt(store.state.weightSubject.subject_weight.teste2))
  ).toFixed(1);

  students.resultaFreq = average;

  if (average < 10) {
    result = "Excluído";
  } else if (average >= 10 && average <= 13.9) {
    result = "Admitido";
  } else {
    result = "Dispensado";
  }
  return { average: average, result: result };
}

/**
 * Calcula a media final do estudante(Exame normal)
 * data:15-03-24
 * author: Octavio Manuel Cossa
 */
function calculate_students_average_exam(students) {
  let average = 0;
  let result = "";
  let percentage = null;

  average =
    (parseFloat(students.miniTeste) *
      parseInt(store.state.weightSubject.subject_weight.miniTeste) +
      parseFloat(students.teste1) *
        parseInt(store.state.weightSubject.subject_weight.teste1) +
      parseFloat(students.teste2) *
        parseInt(store.state.weightSubject.subject_weight.teste2)) /
    100;

  var final_average = 0;
  if (average >= 10 && average <= 13.9) {
    if (parseFloat(students.exame_normal) >= 0) {
      // alert("hello")
      final_average =
        (parseFloat(average) * 0.7 + parseFloat(students.exame_normal) * 0.7) /
        1.4;
      if (final_average < 10) {
        result = "Reprovado";
      } else {
        result = "Aprovado";
      }
    }
  }

  if (average >= 10 && average <= 13.9) {
    const final_average_exam_normal =
      (parseFloat(average) * 0.7 + parseFloat(students.exame_normal) * 0.7) /
      1.4;

    if (final_average_exam_normal < 10 && students.recorrencia != null) {
      console.log("ds");
      final_average =
        (parseFloat(average) * 0.7 + parseFloat(students.recorrencia) * 0.7) /
        1.4;

      if (final_average < 10) {
        result = "Reprovado";
      } else {
        result = "Aprovado";
      }
    }
  }

  return { average: final_average.toFixed(1), result: result };
}

/**
 * Calcula a media final do estudante(Recorrencia)
 * data:15-03-24
 * author: Octavio Manuel Cossa
 */
function calculate_students_average_exam_recorence(students) {
  let average = 0;
  let result = "";

  average =
    (parseFloat(students.miniTeste) *
      parseInt(store.state.weightSubject.subject_weight.miniTeste) +
      parseFloat(students.teste1) *
        parseInt(store.state.weightSubject.subject_weight.teste1) +
      parseFloat(students.teste2) *
        parseInt(store.state.weightSubject.subject_weight.teste2)) /
    100;

  var final_average = 0;
  if (students.exame_normal >= 10) {
    final_average =
      (parseFloat(average) * 0.7 + parseFloat(students.recorrecia) * 0.7) / 1.4;

    if (final_average < 10) {
      students.final_result = "Reprovado";
    } else {
      students.final_result = "Aprovado";
    }
  }

  return { average: final_average.toFixed(1), result: result };
}

/**
 * Responsavel por retornar os testes que devem ser avaliados e que foram
 * adicionado na formula
 * author: Octavio Manuel Cossa
 * */
async function getFormulasSubject() {
  await TeacherDashStoreModule.actions
    .getFormulaByClass(route.params.classmate_id, route.params.subject_id)
    .then((response) => {
      labels.value = response;
      let weight = {
        miniTeste: response.filter(
          (item) => item.assessment_type === "MINI-TESTE"
        )[0].percentage,
        teste1: response.filter((item) => item.assessment_type === "TESTE-1")[0]
          .percentage,
        teste2: response.filter((item) => item.assessment_type === "TESTE-2")[0]
          .percentage,
        // teste3: response.filter((item) => item.assessment_type === "TESTE-3")[0]
        // .percentage,
        trabalho1: response.filter(
          (item) => item.assessment_type === "TRABALHO-PESQUISA-1"
        )[0].percentage,
        trabalho2: response.filter(
          (item) => item.assessment_type === "TRABALHO-PESQUISA-2"
        )[0].percentage,
        trabalho3: response.filter(
          (item) => item.assessment_type === "TRABALHO-PESQUISA-3"
        )[0].percentage,
        exameNormal: response.filter(
          (item) => item.assessment_type === "EXAME-NORMAL"
        )[0].percentage,
        recorrencia: response.filter(
          (item) => item.assessment_type === "RECORRENCIA"
        )[0].percentage,
      };

      store.dispatch("weightSubject/add_weights", weight);

      response.forEach((element) => {
        if (element.percentage > 0) {
          formulas.value.push({
            id: element.id,
            assessment_type: element.assessment_type,
            percentage: element.percentage,
          });

          assessment_type.value = formulas.value[0].assessment_type;
          switchTestToEnable(formulas.value[0].assessment_type);
        }
      });
    });
}

/**
 * Responsavel por desabiltar os campos na grelha de notas
 * Data: 14-03-2024
 */
function switchTestToEnable(assessment_type) {
  switch (assessment_type) {
    case "MINI-TESTE":
      selectedAssessment.value = assessment_type;
      disabled_field.value.mini_teste_weight = false;
      disabled_field.value.teste_1_weight = true;
      disabled_field.value.teste_2_weight = true;
      disabled_field.value.teste_3_weight = true;
      disabled_field.value.trabalho_1_weight = true;
      disabled_field.value.trabalho_2_weight = true;
      disabled_field.value.trabalho_3_weight = true;
      disabled_field.value.exame_normal_weight = true;
      disabled_field.value.recorrencia_weight = true;

      assessment.value.forEach((element) => {
        element.assessment_type = "MINI-TESTE";
      });
      break;

    case "TESTE-1":
      selectedAssessment.value = assessment_type;
      disabled_field.value.teste_1_weight = false;
      disabled_field.value.mini_teste_weight = true;
      disabled_field.value.teste_2_weight = true;
      disabled_field.value.teste_3_weight = true;
      disabled_field.value.trabalho_1_weight = true;
      disabled_field.value.trabalho_2_weight = true;
      disabled_field.value.trabalho_3_weight = true;
      disabled_field.value.exame_normal_weight = true;
      disabled_field.value.recorrencia_weight = true;
      assessment.value.forEach((element) => {
        element.assessment_type = "TESTE-1";
      });
      break;

    case "TESTE-2":
      selectedAssessment.value = assessment_type;
      disabled_field.value.teste_2_weight = false;
      disabled_field.value.teste_1_weight = true;
      disabled_field.value.mini_teste_weight = true;
      disabled_field.value.teste_3_weight = true;
      disabled_field.value.trabalho_1_weight = true;
      disabled_field.value.trabalho_2_weight = true;
      disabled_field.value.trabalho_3_weight = true;
      disabled_field.value.exame_normal_weight = true;
      disabled_field.value.recorrencia_weight = true;
      assessment.value.forEach((element) => {
        element.assessment_type = "TESTE-2";
      });
      break;

    case "TRABALHO-PESQUISA-1":
      selectedAssessment.value = assessment_type;
      disabled_field.value.trabalho_1_weight = false;
      disabled_field.value.teste_3_weight = true;
      disabled_field.value.teste_2_weight = true;
      disabled_field.value.teste_1_weight = true;
      disabled_field.value.mini_teste_weight = true;
      disabled_field.value.trabalho_2_weight = true;
      disabled_field.value.trabalho_3_weight = true;
      disabled_field.value.exame_normal_weight = true;
      disabled_field.value.recorrencia_weight = true;
      assessment.value.forEach((element) => {
        element.assessment_type = "TRABALHO-PESQUISA-1";
      });
      break;
    case "TRABALHO-PESQUISA-2":
      selectedAssessment.value = assessment_type;
      disabled_field.value.trabalho_2_weight = false;
      disabled_field.value.trabalho_1_weight = true;
      disabled_field.value.teste_3_weight = true;
      disabled_field.value.teste_2_weight = true;
      disabled_field.value.teste_1_weight = true;
      disabled_field.value.mini_teste_weight = true;
      disabled_field.value.trabalho_3_weight = true;
      disabled_field.value.exame_normal_weight = true;
      disabled_field.value.recorrencia_weight = true;
      assessment.value.forEach((element) => {
        element.assessment_type = "TRABALHO-PESQUISA-2";
      });
      break;
    case "TRABALHO-PESQUISA-3":
      selectedAssessment.value = assessment_type;
      disabled_field.value.trabalho_3_weight = false;
      disabled_field.value.trabalho_2_weight = true;
      disabled_field.value.trabalho_1_weight = true;
      disabled_field.value.teste_3_weight = true;
      disabled_field.value.teste_2_weight = true;
      disabled_field.value.teste_1_weight = true;
      disabled_field.value.mini_teste_weight = true;
      disabled_field.value.exame_normal_weight = true;
      disabled_field.value.recorrencia_weight = true;
      assessment.value.forEach((element) => {
        element.assessment_type = "TRABALHO-PESQUISA-3";
      });
      break;
    case "EXAME-NORMAL":
      selectedAssessment.value = assessment_type;
      disabled_field.value.teste_3_weight = true;
      disabled_field.value.teste_2_weight = true;
      disabled_field.value.teste_1_weight = true;
      disabled_field.value.mini_teste_weight = true;
      disabled_field.value.trabalho_1_weight = true;
      disabled_field.value.trabalho_2_weight = true;
      disabled_field.value.trabalho_3_weight = true;
      disabled_field.value.exame_normal_weight = false;
      disabled_field.value.recorrencia_weight = true;

      assessment.value.forEach((element) => {
        element.assessment_type = "EXAME-NORMAL";
      });
      break;
    case "RECORRENCIA":
      selectedAssessment.value = assessment_type;
      disabled_field.value.teste_2_weight = true;
      disabled_field.value.teste_1_weight = true;
      disabled_field.value.mini_teste_weight = true;
      disabled_field.value.trabalho_1_weight = true;
      disabled_field.value.trabalho_2_weight = true;
      disabled_field.value.trabalho_3_weight = true;
      disabled_field.value.exame_normal_weight = true;
      disabled_field.value.recorrencia_weight = false;

      assessment.value.forEach((element) => {
        element.assessment_type = "RECORRENCIA";
      });
      break;
    default:
      console.log("Opcao nao encontrada");

      break;
  }
}

async function sendResults() {
  try {
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
  // console.log("estudante Avaliados", assessment.value);
}

function weight_mini_teste(student) {
  console.log(student);
  if (student.miniTeste != undefined) {
    if (student.miniTeste > 20 || student.miniTeste < 0) {
      student.miniTeste = 0;
      return toastr.info(
        "A nota nao pode ser inferior 0 ou superior que 20 valores"
      );
    }
  }
}

function weight_teste_1(student) {
  if (student.teste1 != undefined) {
    if (student.teste1 > 20 || student.teste1 < 0) {
      student.teste1 = 0;
      return toastr.info(
        "A nota nao pode ser inferior 0 ou superior que 20 valores"
      );
    }
  }
}
function weight_teste_2(student) {
  if (student.teste2 != undefined) {
    if (student.teste2 > 20 || student.teste2 < 0) {
      student.teste2 = 0;
      return toastr.info(
        "A nota nao pode ser inferior 0 ou superior que 20 valores"
      );
    }
  }
}
function weight_teste_3(student) {
  if (student.teste3 != undefined) {
    if (student.teste3 > 20 || student.teste3 < 0) {
      student.teste3 = 0;
      return toastr.info(
        "A nota nao pode ser inferior 0 ou superior que 20 valores"
      );
    }
  }
}

function weight_trabalho_1(student) {
  if (student.trabalho1 != undefined) {
    if (student.trabalho1 > 20 || student.trabalho1 < 0) {
      student.trabalho1 = 0;
      return toastr.info(
        "A nota nao pode ser inferior 0 ou superior que 20 valores"
      );
    }
  }
}

function weight_trabalho_2(student) {
  if (student.trabalho2 != undefined) {
    if (student.trabalho2 > 20 || student.trabalho2 < 0) {
      student.trabalho2 = 0;
      return toastr.info(
        "A nota nao pode ser inferior 0 ou superior que 20 valores"
      );
    }
  }
}

function weight_trabalho_3(student) {
  if (student.trabalho3 != undefined) {
    if (student.trabalho3 > 20 || student.trabalho3 < 0) {
      student.trabalho3 = 0;
      return toastr.info(
        "A nota nao pode ser inferior 0 ou superior que 20 valores"
      );
    }
  }
}

function weight_exame_normal(student) {
  if (student.exame_normal != undefined) {
    if (student.exame_normal > 20 || student.exame_normal < 0) {
      student.exame_normal = 0;
      return toastr.info(
        "A nota nao pode ser inferior 0 ou superior que 20 valores"
      );
    }
  }
}

function weight_recorrencia(student) {
  if (student.recorrecia != undefined) {
    if (student.recorrecia > 20 || student.recorrecia < 0) {
      student.recorrecia = 0;
      return toastr.info(
        "A nota nao pode ser inferior 0 ou superior que 20 valores"
      );
    }
  }
}

onMounted(() => {
  getStudentByClassMates();
  getFormulasSubject();
  getSubjectAvaluation();
});

// avaliacao dos estudantes
async function submitAssessments() {
  try {
    loading.value = true;

    // console.log(selectedAssessment.value);

    const resp = await TeacherDashStoreModule.actions.giveAssessmentToStudents(
      dataStudents.value,
      selectedAssessment.value
    );

    if (resp.data.status) {
      return toastr.success(resp.data.message);
    }
  } catch (error) {
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loading.value = false;
  }
}

// Actualiza a nota do estudante selecionado/especifico
async function updateStudentAssessments() {
  try {
    const resp = await TeacherDashStoreModule.actions.updateStudentResult(
      DataAssessemntStudent.value,
      selectedAssessment.value
    );
  } catch (error) {
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loading.value = false;
  }
}

async function getStudentByClassMates() {
  try {
    loading.value = true;
    const resp = await TeacherDashStoreModule.actions.getStudentByClassMates(
      route.params.classmate_id
    );

    console.log("====================================");
    console.log("estudantes", resp.data);
    console.log("====================================");

    // console.log("Dados do server=> ", resp.data);
    store.dispatch("assessmentResults/assessments", resp.data);

    let students = [];

    for (let index = 0; index < resp.data.length; index++) {
      students.push({
        id: index + 1,
        student_id: resp.data[index].id,
        student_card_id: resp.data[index].student_card_id,
        fullName: resp.data[index].person.fullName,
        miniTeste:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type == "MINI-TESTE" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "MINI-TESTE" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,
        teste1:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type === "TESTE-1" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "TESTE-1" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,
        teste2:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type === "TESTE-2" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "TESTE-2" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,
        teste3:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type === "TESTE-3" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "TESTE-3" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,
        trabalho1:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type === "TRABALHO-1" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "TRABALHO-1" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,
        trabalho2:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type === "TRABALHO-2" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "TRABALHO-2" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,
        trabalho3:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type === "TRABALHO-3" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "TRABALHO-3" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,
        exame_normal:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type === "EXAME-NORMAL" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "EXAME-NORMAL" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,
        recorrencia:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type === "RECORRENCIA" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "RECORRENCIA" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,

        mediaFreq:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type === "MEDIA-FREQUENCIA" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "MEDIA-FREQUENCIA" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,

        resultaFreq:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type === "RESULTADO-FREQUENCIA" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "RESULTADO-FREQUENCIA" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,
        mediaFinal:
          resp.data[index].assessment_result.filter(
            (item) =>
              item.assesment_type === "MEDIAL-FINAL" &&
              item.subject_id == parseInt(route.params.subject_id)
          )[0] == undefined
            ? 0
            : resp.data[index].assessment_result.filter(
                (item) =>
                  item.assesment_type === "MEDIAL-FINAL" &&
                  item.subject_id == parseInt(route.params.subject_id)
              )[0].assessment_value,
        final_result: "",
        subject_id: route.params.subject_id,
        classmate_id: route.params.classmate_id,
      });
    }

    // console.log("show", students);

    // for (let l = 0; l < resp.data[index].assessment_result.length; l++) {
    //   const estudentId = resp.data[index].assessment_result[l].estudent_id;

    //   if (resp.data[index].id == resp.data[index].assessment_result[l].estudent_id) {
    //      assessmentResults.value.push({
    //       student_id: resp.data[index].id,
    //       fullName: resp.data[index].pessoa.fullName,
    //       mini_teste: resp.data[index].assessment_result[l].assesment_type == "MINI-TESTE" ? resp.data[index].assessment_result[l].assessment_value : 0,
    //       teste_1: resp.data[index].assessment_result[l].assesment_type == "TESTE-1" ? resp.data[index].assessment_result[l].assessment_value : 0,
    //       teste_2: resp.data[index].assessment_result[l].assesment_type == "TESTE-2" ? resp.data[index].assessment_result[l].assessment_value : 0,
    //       teste_3: 0,
    //       trabalho_1: 0,
    //       trabalho_2: 0,
    //       trabalho_3: 0,
    //       average: 0,
    //       assessment_type: null,
    //     });

    //   }
    // }
    dataStudents.value = students;

    // assessment.value = assessmentResults.value.filter(
    //   (obj) => obj.student_id != 0
    // );

    // for (let index = 0; index < resp.data.length; index++) {
    //   assessmentResults.value.push({
    //     student_id: resp.data.data[index].id,
    //     fullName: resp.data.data[index].pessoa.fullName,
    //     mini_teste: 0,
    //     teste_1: 0,
    //     teste_2: 0,
    //     teste_3: 0,
    //     trabalho_1: 0,
    //     trabalho_2: 0,
    //     trabalho_3: 0,
    //     average: 0,
    //     assessment_type: null,
    //   });
    // }

    // assessment.value = assessmentResults.value.filter(
    //   (obj) => obj.student_id != 0
    // );
  } catch (error) {
  } finally {
    loading.value = false;
  }
}

function closeModal() {
  isDelete.value = false;
}

function updateStudentAssessment(students) {
  isDelete.value = true;

  let findStudent = dataStudents.value.filter(
    (item) => item.student_id == students.student_id
  );
  DataAssessemntStudent.value = findStudent;
  //  console.log("chek", item);
}
</script>

<style scoped>
tr {
  cursor: pointer;
}

#mainHeaderTable {
  font-size: 12px;
}
</style>
