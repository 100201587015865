import axios from "axios";
import authHeader from "@/Api/AuthHeader";
const URL_API =  process.env.VUE_APP_URL_API;


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: { 
    async createRoles(formData) {
        try {
          const response = await axios.post(URL_API+"roles", formData, {
            headers: authHeader(),
          });
          return response;
        } catch (error) {
          return error;
        }
      },

    getAllRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+"roles", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async deleteRole(id) {

         try {
          const response = await axios.delete(URL_API+"roles/" + id, {
            headers: authHeader(),
          });
          return response;
        } catch (error) {
          return error;
        }
      },
  },
};
