<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Jassa</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Detalhes das Turma</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="sendData">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="form-label">Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.name"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="form-label">Capacidade </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.capacity"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="form-label">Departamentos</label>
                  <select class="form-control" v-model="formData.department_id">
                    <option
                      :value="dept.id"
                      v-for="dept in departmentData"
                      :key="dept.id"
                    >
                      {{ dept.dep_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <label class="form-label">Descriçao da Turma</label>
                    <textarea
                    class="form-control"
                    rows="5"
                    v-model="formData.description"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <button type="submit" class="btn btn-primary float-right" v-if="loading == false">Actualizar</button>
                <button type="button" class="btn btn-primary float-right" v-else>Actualizando...</button>
                <!-- <button type="button" @click="backHistory" class="btn btn-light">Voltar</button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, onUnmounted } from "vue";
import { mapGetters } from "vuex";
import ClassMateStoreModule from "./ClassMateStoreModule";
import store from "@/store";
import toastr from "toastr";
import { useRoute } from "vue-router";

export default {
  setup() {
    const TURMA_MODULE_NAME = "Turma";

    if (!store.hasModule(TURMA_MODULE_NAME)) {
      store.registerModule(TURMA_MODULE_NAME, ClassMateStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(TURMA_MODULE_NAME)) {
        store.unregisterModule(TURMA_MODULE_NAME);
      }
    });

    const formData = ref(
      JSON.parse(
        JSON.stringify({
          name: "",
          capacity: "",
          description: "",
          department_id: null,
        })
      )
    );
    const loading = ref(false);
    const departmentData = ref([]);
    const idTurma = ref();
    const route = useRoute();

    async function sendData() {
      loading.value = true;

      if (
        formData.name == "" ||
        formData.description == "" ||
        formData.department_id == "" ||
        formData.capacity == ""
      ) {
        toastr.warning("Por favor preencha os campos obrigatorios");
        loading.value = false;

        return;
      }

      const resp =  await ClassMateStoreModule.actions.updateTurma({'id':route.params.id_class, 'data':formData.value})
      
      if (resp.data.status == true) {
        toastr.success(resp.data.message);
      }else{
        toastr.error(resp.data.message)
      }
      loading.value = false;
      
      // store
      //   .dispatch("Turma/updateTurma", {id: idTurma.value, data: formData.value})
      //   .then((response) => {
      //     console.log(response);
      //     toastr.success(response.data.success);
      //     loading.value = false;
      //    })
      //   .catch((error) => {
      //     console.log(error);
      //     toastr.error("Erro! Erro na requisicao de dados");
      //   });
    }

    function getDepartment() {
      store
        .dispatch("Turma/getDepartment")
        .then((response) => {
          console.log(response.data.data);
          departmentData.value = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function viewClassMates() {
 
      // idTurma.value = route.params.id_class;
      const resp = await ClassMateStoreModule.actions.viewClassMates(route.params.id_class);
      formData.value = resp.data[0];
      console.log(resp.data);
      // store
      //   .dispatch("Turma/viewClassMates", { id: this.$route.params.id_class })
      //   .then((response) => {
      //     console.log("teste", response.data);
      //     formData.value = response.data;
      //   })
      //   .catch((error) => {});
    }

    function clearFields() {
      formData.name = "";
      formData.description = "";
    }

    function backHistory(){

        history.back();
    }

    return {
      formData,
      sendData,
      loading,
      clearFields,
      getDepartment,
      departmentData,
      viewClassMates,
      idTurma,
      backHistory,
      route
       
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    this.getDepartment();
    this.viewClassMates();
  },
};
</script>
  