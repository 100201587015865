export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    // const expireationTime = JSON.parse(localStorage.getItem('token_expires_at'));
    // console.log(user.access_token)
  
    if (user && user.access_token ) {

      // const isTokenExpired = new Date(expireationTime) < new Date();
      // if (isTokenExpired) {
        // window.location.href = "/login";
      // }else{
        return { Authorization: 'Bearer ' + user.access_token };

      // }
    } else {
      return {};
    }
  }