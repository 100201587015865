import axios from "axios";
import authHeader from "@/Api/AuthHeader";
import { registerRuntimeCompiler } from "vue";

const URL_API = process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createTeacher(TeacherData) {
      return axios.post(URL_API + "professor", TeacherData, {
        headers: authHeader(),
      });
    },

    async fetchProvinces() {
      try {
        const response = await axios.get(URL_API + "provinces/", {
          headers: authHeader(),
        });

        return response;
      } catch (error) {
        return error;
      }
    },

    getAllCourses() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API + "api/getCourse", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async fetchDistricts(province_id) {
      try {
        const resp = await axios.get(URL_API + "district/" + province_id, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async fetchRoles() {
      try {
        const resp = await axios.get(URL_API + "roles", {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async alocateRole(roleData, teacher_id) {
      try {
        const resp = await axios.post(
          URL_API + "addTeacherRole",
          { role: roleData, teacher_id: teacher_id },
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    getAllTeachers(data) {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API + `professor?page=${data.page}&search=${data.search}`, {
            headers: authHeader(),
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchTeacher(data) {
      return axios.get(URL_API + `fetchTeacher?search=${data.search}`, {
        headers: authHeader(),
      });
    },

    async ShowTeacher(teacher_id) {
      try {
        const response = await axios.get(URL_API + "professor/" + teacher_id, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    async updateTeacher(formData) {
      return axios.patch(URL_API + `professor/${formData.id}`, formData, {
        headers: authHeader(),
      });
    },

    async alocateData(dataAlocate) {
      try {
        const resp = await axios.post(
          URL_API + "alocateDataProfessor",
          dataAlocate,
          {
            headers: authHeader(),
          }
        );

        return resp;
      } catch (error) {
        return error;
      }
    },

    async alocateTeacherToClassmate(dataAlocate, teacher_id) {
      try {
        const resp = await axios.post(
          URL_API + `assign-classmate-subject/${teacher_id}`,
          {
            subject_id: dataAlocate.subject_id,
            classmate_id: dataAlocate.classmate_id,
          },
          {
            headers: authHeader(),
          }
        );

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getSubjectsByTeacherId(teacher_id) {
      try {
        const resp = await axios.get(URL_API + "professor/" + teacher_id, {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async removeTeacherFromClassmate(classmates_id, teacher_id) {
      return axios.post(
        URL_API + "removeTeacherFromClassmate",
        { teacher_id: parseInt(teacher_id), classmates_id: classmates_id },
        { headers: authHeader() }
      );
    },

    async removeSubjectFromTeacher(subject_id, teacher_id) {
      try {
        const resp = await axios.post(
          URL_API + "removeSubjectFromTeacher",
          { teacher_id: teacher_id, subject_id: subject_id },
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async deleteTeacher(teacher_id) {
      return axios.delete(URL_API + `professor/${teacher_id}`, {
        headers: authHeader(),
      });
    },

    async getAllClassamateSubjects(classmate_id, course_id) {
      return axios.get(
        URL_API +
          `getAllClassamateSubjects?classmate_id=${classmate_id}&course_id=${course_id}`,
        { headers: authHeader() }
      );
    },
  },
};
