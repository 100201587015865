import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";

const URL_API =  process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getAllInscriptions(page) {
      try {
        const resp = await axios.get(URL_API+`inscriptions?page=${page.page}&tipo_inscricao=todas`, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getPendentsInscriptions() {
      try {
        const resp = await axios.get(
          URL_API+"inscriptions?tipo_inscricao=pendentes",
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getPayedInscriptions() {
      try {
        const resp = await axios.get(URL_API+"inscriptions?tipo_inscricao=pagas", {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getCandidateInscriptions(page) {
      try {
        const resp = await axios.get(
          URL_API+`inscriptions?page=${page.page}&tipo_inscricao=candidaturas`,
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getConcludedInscriptions() {
      try {
        const resp = await axios.get(
          URL_API+"inscriptions?tipo_inscricao=renovacoes-concluidas",
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getRenewPendentsInscriptions() {
      try {
        const resp = await axios.get(
          URL_API+"inscriptions?tipo_inscricao=renovacoes-pendentes",
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async aproveInscription(data, person_id) {
      return axios.post(URL_API+`change-state/${person_id}`, data, {
        headers: authHeader(),
      });
    },

    
  },
};
