import axios from "axios";
import authHeader from "@/Api/AuthHeader";

const URL_API = process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCourses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            URL_API +
              "cursos?page=" +
              queryParams.page +
              "&search=" +
              queryParams.search,
            { headers: authHeader() }
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getAllCourses() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API + "cursos", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async createCourse(CourseData) {
      return await axios.post(URL_API + "cursos", CourseData, {
        headers: authHeader(),
      });
    },
    async getDepartments() {
      try {
        const response = await axios.get(URL_API + "getAlldepartment", {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    async viewCourse(ctx, { id }) {
      return axios.get(URL_API + `cursos/${id}`, { headers: authHeader() });
    },

    async updateCourse(CourseData) {
      return axios.patch(
        URL_API + `cursos/${CourseData.course_id}`,
        CourseData,
        {
          headers: authHeader(),
        }
      );
    },

    // updateCourse(ctx, CourseData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .put("/api/cursos/" + CourseData.id, CourseData.data, {
    //         headers: authHeader(),
    //       })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },

    deleteCourse(id) {
      return axios.delete(URL_API + `cursos/${id}`, {
        headers: authHeader(),
      });
    },
    getCourseWithoutPaginate() {
      return axios.get(URL_API + "getAllCourses", { headers: authHeader() });
    },
  },
};
