<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <!-- <h4 class="page-title">Departamento: {{ departamento.dep_name }}</h4> -->
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item">
            <router-link to="/">Inicio</router-link>
          </li>
          <li class="breadcrumb-item">Estudante</li>
          <li class="breadcrumb-item active">Perfil</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8 col-lg-12 col-md-12">
      <div class="card">
        <div class="row">
          <div class="col-md-4">
            <div class="card-header">
              <h3 class="card-title">Perfil pessoal</h3>
            </div>
            <div class="card-body">
              <img src="" class="img-card img-fluid" alt="" />
              <button class="btn btn-md btn-primary btn-block">
                Dados do perfil
              </button>
              <hr />
              <br />

              <ul class="list-unstyled" v-if="person != null">
                <li class="mb-2">
                  <h4 class="">{{ person.fullName }}</h4>
                  <small class="text-center">{{ person.email }}</small>
                </li>
                <li class="mb-2">
                  <strong class="">Bilhete de Identidade: </strong
                  ><span class="">{{ person.doc_number }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Data de Nascimento: </strong
                  ><span class="">{{ person.birthDate }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Endere&ccedil;o: </strong
                  ><span class="">{{ person.address_location }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">G&eacute;nero: </strong
                  ><span class="">{{ person.gender }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Formado na (o): </strong
                  ><span class="">{{ person.last_school_frequency }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-8">
            <div class="card-header">
              <h3 class="card-title">Informa&ccedil;&otilde;es adicionais</h3>
            </div>
            <div class="card-body">
              <div
                class="bg-primary text-center text-white mt-4 p-2"
                style="border-radius: 3px"
              >
                <h4 style="font-size: inherit; font-family: inherit">
                  Dados da Turma
                </h4>
              </div>
              <br />
              <ul class="list-unstyled" v-if="person != null">
                <li class="mb-2">
                  <strong class="">Nome da Turma: </strong
                  ><span class="">{{ person.classmate_name }} </span>
                </li>
                <li class="mb-2">
                  <strong class="">Regime: </strong
                  ><span class=""> {{ person.classmate_scholar_shift }}</span>
                </li>

                <li class="mb-2">
                  <strong class="">Curso: </strong
                  ><span class=""> {{ person.course_name }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Nivel: </strong
                  ><span class=""> {{ person.classmate_level_name }}</span>
                </li>
                <li class="mb-2">
                  <strong class="">Ano de Turma: </strong
                  ><span class=""> </span>
                </li>
              </ul>
              <!-- <table class="table">
                <tr>
                  <th style="width:20%">Disciplinas que leciona</th>
                  <td>
                    <span class="justify-content-between">
                     </span> 
                  </td>
                </tr>
                <tr>
                  <th>Turmas alocadas</th>
                  <td>
                    <span class="justify-content-between">
                      
                     
                    </span>
                  </td>
                </tr>
               
                
              </table> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12 col-xxl-8 col-lg-8">
      <div class="card">
        <div class="card-header justify-content-center">
          <h2 class="card-title">Actividades Internas e arquivos anexados</h2>
        </div>
        <div class="card-body">
          <table class="table table-bordeless table-stripped">
            <thead>
              <tr>
                <th>Ultimas Aulas dada</th>
                <th>Avaliacoes</th>
                <th>Nr total de Alunos</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Anexos</td>
                <td>Anexos</td>
                <td>3400</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <Loading :loadingData="loading"></Loading>
</template>
<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import StudentStoreModule from "./StudentStoreModule";
import Loading from "@/components/Loading.vue";

const person = ref(null);
const loading = ref(false);
async function getStudentProfile() {
  try {
    loading.value = true;

    const resp = await StudentStoreModule.actions.getMyDatas();

    localStorage.setItem("myProfile", JSON.stringify(resp.data[0]));

    person.value = resp.data[0];
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  getStudentProfile();
});
</script>
