<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Alocar Professor</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Professores</li>
          <li class="breadcrumb-item active">Alocar Professores</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="row tab-content">
        <div id="list-view" class="tab-pane fade active show col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-8 col-lg-12 col-md-12">
                  <div class="card">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="card-header">
                          <h3 class="card-title">Perfil do Professor</h3>
                        </div>
                        <div class="card-body">
                          <ul class="list-unstyled">
                            <li class="mb-2">
                              <h4>
                                {{ userData.fullName }}
                              </h4>
                              <!-- <small class="text-white">{{userData.gender}}</small> -->
                            </li>
                            <li class="mb-2">
                              <strong class="">Sexo: </strong
                              ><span>{{ userData.gender }}</span>
                            </li>
                            <li class="mb-2">
                              <strong>Data de Nascimento: </strong
                              ><span class="">{{ userData.birthDate }}</span>
                            </li>
                            <li class="mb-2">
                              <strong>Endere&ccedil;o: </strong
                              ><span class="">{{
                                userData.address_location
                              }}</span>
                            </li>
                            <li class="mb-2">
                              <strong>Formado na (o): </strong
                              ><span>{{ userData.last_school_frequency }}</span>
                            </li>
                            <li class="mb-2">
                              <strong class="text-muted">Detalhes</strong>
                            </li>

                            <li class="mb-2">
                              <strong>Deficiente: </strong
                              ><span>{{
                                userData.is_deficient == 1 ? "Sim" : "Nao"
                              }}</span>
                            </li>

                            <li class="mb-2">
                              <strong>Detalhes da Deficiencia: </strong
                              ><span>{{
                                userData.deficient_type == null
                                  ? "-----"
                                  : userData.deficient_type
                              }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="card-header">
                          <h3 class="card-title">
                            Informa&ccedil;a&otilde; do Departamento
                          </h3>
                        </div>
                        <div class="card-body">
                          <ul class="list-unstyled">
                            <li class="mb-2">
                              <strong>Departamento: </strong
                              ><span>
                                {{
                                  userData.dept_name == null
                                    ? "--------"
                                    : userData.dept_name
                                }}</span
                              >
                            </li>
                            <li class="mb-2">
                              <strong>Descri&ccedil;a&otilde;: </strong
                              ><span>{{
                                userData.dept_description == null
                                  ? "--------"
                                  : userData.dept_description
                              }}</span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="card-header">
                          <h3 class="card-tittle">Mais informações</h3>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div
                              class="col-lg-3 ml-2 p-2 bg-card mt-2"
                              v-for="(ti, index) in userData.classmate_subject"
                              :key="index"
                            >
                              <div class="d-flex justify-content-end">
                                <span
                                  @click="
                                    removeClassmate(
                                      ti.classmate_id,
                                      ti.teacher_id,
                                      index
                                    )
                                  "
                                  class="fa fa-times text-danger fa-1x"
                                ></span>
                              </div>
                              <ul class="list-unstyled">
                                <li class="mb-2">
                                  <h4 class="text-white">
                                    {{ ti.subject_name }}
                                  </h4>
                                  <span>{{ ti.course_name }} </span>
                                </li>
                                <li class="mb-2">
                                  <strong class="text-white">Periodo: </strong
                                  ><span class="text-white">{{
                                    ti.classmate_scholar_shift
                                  }}</span>
                                </li>

                                <li class="mb-2">
                                  <strong class="text-white"
                                    >Nivel Academico: </strong
                                  ><span class="text-white">{{
                                    ti.level_name
                                  }}</span>
                                </li>

                                <li class="mb-2">
                                  <strong class="text-white">Turma: </strong
                                  ><span class="text-white badge">
                                    {{ ti.classmate_name }}
                                    <!-- <span
                                        class="ml-2 btn-remove"
                                        @click="removeClassmate(ti.classmate.id, index)"
                                      >
                                        <i class="fas fa-times text-danger"></i>
                                      </span> -->
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <button
                            class="float-right btn btn-success btn-md"
                            @click="openModal(2)"
                          >
                            Alocar <i class="fas fa-plus text-white"></i>
                          </button>
                        </div>
                      </div>

                      <!-- <div class="col-lg-6">
                        <div class="card-header">
                          <h3 class="card-tittle">Disciplinas Alocadas</h3>
                        </div>
                        <div class="card-body">
                          <table class="table">
                            <tr>
                              <th style="width: 60%">Disciplinas Alocadas</th>
                              <td>
                                <span v-if="subjectAlocate === undefined" class="text-muted">Ainda sem disciplinas</span>
                                <span v-else>
                                  <span
                                  v-for="(sub, index) in subjectAlocate"
                                  :key="index"
                                  class="p-1 m-2 justify-content-betwen badge badge-primary"
                                >
                                  {{ sub.name }}

                                  <span
                                    class="ml-2 btn-remove"
                                    @click="removeSubject(sub.id, index)"
                                  >
                                    <i class="fas fa-times text-danger"></i>
                                  </span>
                                </span>
                                </span>
                              </td>
                            </tr>
                            <th style="width: 20%"></th>
                            <td></td>
                          </table>
                          <button
                            class="float-right btn btn-success btn-sm"
                            @click="openModal(3)"
                          >
                            Alocar Disciplina
                            <i class="fas fa-plus text-white"></i>
                          </button>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-md-12 mt-5">
                <label class="form-label">Previlegio</label>
                <header class="row float-right addCursor" @click="openModal(4)">
                  <i class="fas fa-plus text-white"></i>
                </header>
              </div>
              <div class="form-group col-md-12 row">
                <div
                  class="departemanto"
                  v-for="(role, index) in RoleAlocate"
                  :key="index"
                >
                  <span class="text-white">
                    {{ role.name }}
                  </span>

                  <span class="btn-remove">
                    <i
                      class="fas fa-times text-danger"
                      @click="removeRole(role.id, index)"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="col-md-12 text-center mt-5" v-if="roles.length == 0">
                <span class="text-white" style="font-size: 11px"
                  >Ainda sem Previlegio...</span
                >
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="isClose" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Alocar {{ title }}</h5>
            <button
              type="button"
              @click="closeModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div
                class="col-lg-12 col-md-12 col-sm-12"
                v-if="title == 'Turmas'"
              >
                <div class="form-group">
                  <label class="form-label"
                    ><a
                      @click="openModalClassmate"
                      href="javascript:void(0)"
                      style="text-decoration: underline"
                    >
                      Selecione a turma na qual deseja alocar o professor*</a
                    ></label
                  >
                  <Select
                    disabled
                    v-model="classmatesDataAdd.classmate_id"
                    placeholder="Selecione a Turma"
                  >
                    <Option
                      :value="cl.id"
                      v-for="cl in classmatesData"
                      :key="cl.id"
                    >
                      {{ cl.name }}
                    </Option>
                  </Select>
                </div>
                <p v-if="classmate_message_error" style="color: red">
                  {{ classmate_message_error }}
                </p>
              </div>

              <div
                class="col-lg-12 col-md-12 col-sm-12"
                v-if="title == 'Turmas'"
              >
                <div class="form-group">
                  <label class="form-label"
                    >Selecione a disciplina que sera lecionada pelo professor na
                    turma*</label
                  >

                  <Select
                    v-model="classmatesDataAdd.subject_id"
                    placeholder="Selecione a Disciplina"
                  >
                    <Option
                      :value="sub.id"
                      v-for="sub in subjects"
                      :key="sub.id"
                    >
                      {{ sub.name }}
                    </Option>
                  </Select>
                </div>
                <p v-if="subject_message_error" style="color: red">
                  {{ subject_message_error }}
                </p>
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <Button
              v-if="title == 'Turmas'"
              type="success"
              @click="handleSubmitAlocateClassMate"
              class="btn btn-success"
            >
              {{ isAlocar == false ? "Alocar" : "Alocando..." }}
            </Button>

            <Button @click="closeModal" type="error" data-dismiss="modal">
              Fechar
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal para selecionar a turma -->
  <div id="modal-overlay" v-show="isSelectClassmate">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Alocar professor a turma</h5>
        <button
          type="button"
          @click="closeSlectClassmate"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div
            class="col-lg-3 mb-2 p-2 bg-card-class ml-1"
            v-for="(ti, index) in classmateDateils"
            :key="index"
          >
            <ul
              @click="selectClassmate(ti.id, ti.course_id)"
              style="cursor: pointer"
              class="list-unstyled mb-2"
            >
              <li class="mb-2">
                <span>{{ ti.course }} </span>
              </li>
              <li class="mb-2">
                <strong class="text-white">Periodo: </strong
                ><span class="text-white">{{ ti.period }}</span>
              </li>

              <li class="mb-2">
                <strong class="text-white">Nivel Academico: </strong
                ><span class="text-white">{{ ti.level }}</span>
              </li>

              <li class="mb-2">
                <strong class="text-white">Turma: </strong
                ><span class="text-white badge">
                  {{ ti.name }}
                </span>
              </li>
              <li class="mb-2">
                <strong class="text-white">Ano de Entrada: </strong
                ><span class="text-white badge">
                  {{ ti.entry_year }}
                </span>
              </li>
            </ul>
          </div>
        </div>

        <Page
          :total="totalClassmate"
          :model-value="current_page_classmate"
          :page-size="parseInt(per_page_classmate)"
          @on-change="getTurmaWithDetails"
          v-if="totalClassmate"
          class="d-flex justify-content-end mb-3"
        />
      </div>
      <div class="modal-footer">
        <button
          type="button"
          @click="closeModalClassmate"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Fechar
        </button>
      </div>
    </div>
  </div>

  <Loading :loadingData="loadingServerStundent"></Loading>
</template>

<script setup>
import { onMounted, ref } from "vue";
import TeacherStoreModule from "./TeacherStoreModule";
import { useRoute } from "vue-router";
import SubjectStoreModule from "../subject/SubjectStoreModule";
import toastr from "toastr";
import ClassMateStoreModule from "../Classmates/ClassMateStoreModule";
import DepartmentStoreModule from "../department/DepartmentStoreModule";
import Swal from "sweetalert2";
import Loading from "../../components/Loading.vue";

const userData = ref({
  fullName: "",
  address_location: "",
  alternative_email: "",
  gender: "",
  doc_type: "",
  doc_number: "",
  nactionality: "",
  birthDate: "",
  last_school_frequency: "",
  level_type: "",
  last_school_frequency_year: "",
  fathers_filliation: "",
  mothers_filliation: "",
  childhood_location: "",
  is_deficient: "",
  deficient_type: "",
  permissions: "",
  contact: "",
  alternative_contact: "",
  province_id: "",
  district_id: "",
  regimeSuperior: "",
});

const loadingServerStundent = ref(false);

const classmates = ref([]);
const classmatesData = ref([]);
const subjects = ref([]);
const subjectAlocate = ref([]);
const isClose = ref(false);
const isAlocar = ref(false);
const title = ref("");
const totalClassmate = ref();
const per_page_classmate = ref();
const current_page_classmate = ref();
const departmentData = ref([]);
const TeacherRole = ref();
const roles = ref([]);
const route = useRoute();
const isSelectClassmate = ref(false);
const classmateDateils = ref([]);
const classmateData = ref({
  id: "",
  person_id: "",
  course_id: "",
  level_id: "",
  semester_id: "",
  academic_year_id: "",
  classmate_id: "",
  entry_year: "",
  InscriptionType: "",
  scholar_shift: "",
});
const searchClassmate = ref(null);
const dataAlocate = {
  subject_id: "",
  subject_id: "",
  teacher_id: route.params.id_teacher,
};
const classmatesDataAdd = ref({
  classmate_id: "",
  subject_id: "",
  teacher_id: route.params.id_teacher,
});
const subject_message_error = ref("");
const classmate_message_error = ref("");

onMounted(() => {
  ShowProfessor();
  getSubjects();
  getAllDepartment();
  getAllClassmates();
  getTurmaWithDetails();
});

async function getTurmaWithDetails(page = 1) {
  try {
    loadingServerStundent.value = true;

    const resp = await ClassMateStoreModule.actions.getTurmaWithDetails({
      page: page,
      search: null,
    });
    classmateDateils.value = resp.data.data;
    totalClassmate.value = resp.data.meta.total;
    per_page_classmate.value = resp.data.meta.per_page;
    current_page_classmate.value = resp.data.meta.current_page;
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

function openModalClassmate() {
  isSelectClassmate.value = true;
}

function closeSlectClassmate() {
  isSelectClassmate.value = false;
}

async function selectClassmate(classmate_id, course_id) {
  try {
    let classmate = {};
    loadingServerStundent.value = true;

    classmate = classmateDateils.value.filter(
      (item) => item.id == classmate_id
    );

    classmatesDataAdd.value.classmate_id = classmate_id;

    const resp = await TeacherStoreModule.actions.getAllClassamateSubjects(
      classmate_id,
      course_id
    );
    console.log(resp);
    subjects.value = resp.data.subjects;

    isSelectClassmate.value = false;
  } catch (error) {
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loadingServerStundent.value = false;
  }
}

async function handleSubmitAlocateRole() {
  isAlocar.value = true;

  const resp = await TeacherStoreModule.actions.alocateRole(
    TeacherRole.value,
    route.params.id_teacher
  );

  isAlocar.value = false;
}
async function handleSubmitAlocate() {
  isAlocar.value = true;

  if (dataAlocate.value.suject_id) {
    isAlocar.value = false;
    subject_message_error.value = "O campo disciplina nao pode estar vazio!";

    return;
  }

  if (dataAlocate.value.classmate_id) {
    isAlocar.value = false;
    classmate_message_error.value = "O campo turma nao pode estar vazio!";
    return;
  }

  const resp = await TeacherStoreModule.actions.alocateData(dataAlocate);
  if (resp.status) {
    toastr.success(resp.message);
    ShowProfessor();
  } else {
    toastr.error("Ocorreu um erro ao tentar adicionar disciplinas");
  }
  // console.log(resp);
  isAlocar.value = false;
}

async function handleSubmitAlocateClassMate() {
  try {
    isAlocar.value = true;

    if (classmatesDataAdd.value.classmate_id == "") {
      isAlocar.value = false;
      classmate_message_error.value = "O campo turma nao pode estar vazio!";
      return;
    }

    if (classmatesDataAdd.value.subject_id == "") {
      isAlocar.value = false;
      subject_message_error.value = "O campo disciplina nao pode estar vazio!";

      return;
    }

    const resp = await TeacherStoreModule.actions.alocateTeacherToClassmate(
      classmatesDataAdd.value,
      route.params.id_teacher
    );

    console.log();
    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      ShowProfessor();
      classmatesDataAdd.value.classmate_id = "";
      classmatesDataAdd.value.subject_id = "";
      isClose.value = false;
    } else {
      if (typeof error.response != "undefined") {
        Object.keys(resp.data.errors).forEach((key) => {
          toastr.info(key, resp.data.errors[key]);
        });
      }
    }
    // console.log(resp);
  } catch (error) {
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    isAlocar.value = false;
  }
}

// async function getSubjectsByTeacherId(){

//   const resp = await TeacherStoreModule.actions.getSubjectsByTeacherId(route.params.id_teacher);
//   console.log(resp.data.data.subject);

// }

async function getRoles() {
  const resp = await TeacherStoreModule.actions.fetchRoles();
  roles.value = resp.data;
  userData.permission_id = resp.data[4].id;
  console.log(resp.data);
}

async function ShowProfessor() {
  const resp = await TeacherStoreModule.actions.ShowTeacher(
    route.params.id_teacher
  );
  userData.value = resp.data.data;

  console.log(resp);
}
async function getAllDepartment() {
  const resp = await DepartmentStoreModule.actions.getAlldepartment();
  departmentData.value = resp.data.data;
}

async function getAllClassmates() {
  const resp = await ClassMateStoreModule.actions.getTurmas();
  classmatesData.value = resp.data.data;
}

async function getSubjects() {
  const resp = await SubjectStoreModule.actions.getSubjects();
  subjects.value = resp.data.data;
}

async function removeTeacherFromClassmate(id, teacher_id, index) {
  try {
    loadingServerStundent.value = false;

    userData.value.classmate_subject.splice(index, 1);
    const resp = await TeacherStoreModule.actions.removeTeacherFromClassmate(
      id,
      teacher_id
    );

    if (resp.data.success === true) {
      toastr.success("O professor ja nao percence a turma.");
    }
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

async function removeClassmate(classmate_id, teacher_id, index) {
  Swal.fire({
    title: "Tem certeza que deseja eliminar este registro?",
    text: "Nao tera como reverter esta operacao!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim, Eliminar!",
    cancelButtonText: "Cancel!",
  }).then((result) => {
    if (result.isConfirmed) {
      removeTeacherFromClassmate(classmate_id, teacher_id, index);
    }
  });
}

async function removeSubject(subject_id, index) {
  subjectAlocate.value.splice(index, 1);
  const resp = await TeacherStoreModule.actions.removeSubjectFromTeacher(
    subject_id,
    route.params.id_teacher
  );
}

async function removeRole() {}

function closeModal() {
  isClose.value = false;
}

function openModal(id) {
  if (id == 3) {
    title.value = "Disciplinas";
  } else if (id == 2) {
    title.value = "Turmas";
  } else if (id == 3) {
    title.value = "Departamentos";
  } else {
    title.value = "Previlegio";
  }
  isClose.value = true;
}
</script>

<style scoped>
.departemanto {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 20px;
  margin-top: 14px;
  margin-left: 2px;
  background-color: #1a1c2a;
  color: #fff;
  width: 120px;
}

.bg-card {
  background-color: #1a1c2a;
  border-radius: 6px;
}

.bg-card span.fa.fa-times {
  cursor: pointer;
}

.departemanto span {
  color: #fff;
}
.btn-remove,
.addCursor {
  cursor: pointer;
}

.bg-card-class {
  background-color: #1a1c2a;
  border-radius: 6px;
}
</style>
