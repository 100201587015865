import axios from "axios";
import authHeader from '@/Api/AuthHeader'
const URL_API =  process.env.VUE_APP_URL_API;
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchDepartamentos(queryParams){
            return  axios.get(URL_API+'department?page='+queryParams.page+'&search='+queryParams.search, { headers: authHeader() })
             
        },

        getAlldepartment(){

            return new Promise((resolve, reject)=>{
                axios.get(URL_API+"getAlldepartment", {headers:authHeader()})
                .then(response=>resolve(response))
                .catch(error=>reject(error))
            })
        },
 

        createDepartamento(DepartmentDatas){
            
                return axios.post(`${URL_API}department`, DepartmentDatas, {
                    headers:authHeader()
                })
 
            
        },

        async viewDepartamento( id ){

            try {
              const response = await axios.get(URL_API+'department/'+id,{ headers: authHeader() })
            return response;
            } catch (error) {
                return error;
            }
          
        },
        
        async getEmployee(){
            try {
                const response = await axios.get(URL_API+'getEmployee', { headers: authHeader() });
                return response;
            } catch (error) {
                return error;
            }
          
        },
 
       updateDepartment(dataDepartment){

            return axios.patch(URL_API+`department/${dataDepartment.dept_id}`, dataDepartment, {headers:authHeader()})
                
           
 
        },

        async deleteDepartment(id){

            try {
                const response = await axios.delete(URL_API+"department/" + id, {
                    headers: authHeader(),
                  });
                  return response;
                
            } catch (error) {
                
            }
        }

        

     }
}