<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title">
          <h4>Adicionar Departamentos</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="index.html">Inicio</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">Departamentos</a>
          </li>
          <li class="breadcrumb-item active">
            <a href="javascript:void(0);">Adicionar Departamentos</a>
          </li>
        </ol>
      </div>
    </div>
    <!-- End row -->
  </div>

  <div class="row">
    <div class="col-xl-12 col-xxl-12 col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">Adicionar Departamento</h5>
        </div>
        <div class="card-body">
          <form @submit.prevent="sendDatas">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome do Departamento</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formDatas.dep_name"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Chefe do Departamento</label>
                  <select
                    v-model="formDatas.person_id"
                    class="form-control custom-select"
                  >
                    <option v-for="p in employees" :key="p.id" :value="p.id">
                      {{ p.fullName }}
                    </option>
                  </select>
                  <!-- <v-select v-model="formDatas.person_id"  :options="employees" :reduce="employees => employees.id"/> -->
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Detalhes Departamento </label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="formDatas.description"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <button type="submit" class="btn btn-primary  float-right">{{loading == false ? "Adicionar" : "Adicionando..."}}</button>
                <!-- <button type="button" class="btn btn-light">
                  Voltar {{ token }}
                </button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import bridge from "@/Api/bridge";
// import vSelect from "vue-select";
import { ref, onUnmounted } from "vue";
import { mapGetters } from "vuex";
import DepartmentStoreModule from "./DepartmentStoreModule";
import store from "@/store";
import toastr from "toastr";
export default {
  data() {
    return {
      data: {
        dep_name: "",
        description: "",
      },
    };
  },
  // components: {
  //   vSelect
  // },
  setup() {
    const DEPARTAMENTO_MODULE_NAME = "Departamento";
    // Register module
    if (!store.hasModule(DEPARTAMENTO_MODULE_NAME)) {
      store.registerModule(DEPARTAMENTO_MODULE_NAME, DepartmentStoreModule);
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTAMENTO_MODULE_NAME)) {
        store.unregisterModule(DEPARTAMENTO_MODULE_NAME);
      }
    });
    const formDatas = {
      dep_name: "",
      description: "",
      person_id: null,
    };

    const employees = ref(null);
    const loading = ref(false)

    function getReponsavel() {
      store
        .dispatch("Departamento/getEmployee")
        .then((response) => {
          employees.value = response.data;
        })
        .catch((error) => {
          console.log(error);
          toastr.error("Erro! Erro na aquisicao da dados");
        });
    }

   async  function sendDatas() {
      loading.value = true;

      if(formDatas.dep_name == "" || formDatas.description == "" || formDatas.person_id == ""){

        toastr.warning("Por favor preencha os campos obrigatorios");
        loading.value = false
        return;
      }

      const response = await DepartmentStoreModule.actions.createDepartamento(formDatas);
       
        toastr.success(response)
        loading.value = false
        clearFields();
      
          
    }

    function clearFields(){

      formDatas.dep_name = ""
      formDatas.description = ""
      formDatas.person_id = ""
    }

    return {
      formDatas,
      getReponsavel,
      employees,
      sendDatas,
      loading,
      clearFields
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },

  created() {
    this.getReponsavel();
  },
};
</script>
