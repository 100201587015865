<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Jassa</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Detalhes dos Cursos</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="sendData">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label"
                    >Nome do Curso</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.name"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Departamento do Curso</label>
                  <select class="form-control" v-model="formData.department_id">
                    <option v-for="d in departments" :value="d.id" :key="d.id">
                      {{ d.dep_name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Descricao do Curso</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="formData.description"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <button type="submit" class="btn btn-primary float-right">
                  {{ loading == false ? "Actualiazar" : "Actualizando..." }}
                </button>
                <!-- <button type="submit" class="btn btn-light">Voltar</button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted } from "vue";
import { mapGetters } from "vuex";
import CoursesStoreModule from "./CourseStoreModule";
import store from "@/store";
import toastr from "toastr";
// import { useRoute } from "vue-router";
export default {
  setup() {
    // const route = useRoute();
    const CURSO_MODULE_NAME = "Curso";
    // console.log("ID", route.params.id);
    if (!store.hasModule(CURSO_MODULE_NAME)) {
      store.registerModule(CURSO_MODULE_NAME, CoursesStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(CURSO_MODULE_NAME)) {
        store.unregisterModule(CURSO_MODULE_NAME);
      }
    });

    var formData = ref(
      JSON.parse(
        JSON.stringify({
          name: "",
          description: "",
          department_id: null,
        })
      )
    );

    const courseData = ref({});

    const departments = ref(null);
    const loading = ref(false);
    const id_course = ref(null);

    async function getAllDepartment() {
      
      const resp = await CoursesStoreModule.actions.getDepartments();
      departments.value = resp.data.data;
      console.log(resp.data.data);
      // store
      //   .dispatch("Curso/getDepartments")
      //   .then((response) => {
      //     departments.value = response.data;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }

    function viewCourse() {
      // console.log("ID", this.$route.params.id_course);
      id_course.value = this.$route.params.id_course;

      store
        .dispatch("Curso/viewCourse", { id: this.$route.params.id_course })
        .then((response) => {
          formData.value = response.data;
          console.log(formData.value);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function sendData() {
      loading.value = true;
 
      const resp = await CoursesStoreModule.actions.updateCourse({'data' :formData.value, 'id': id_course.value });
      if(resp.data.status == true){
        toastr.success(resp.data.message);
      }else{
        toastr.error(resp.data.message);
        
      }
      loading.value = false;


      // toastr.success(resp.data.success);
      //  store
      //   .dispatch("Curso/updateCourse",  {data :formData.value, id: id_course.value })
      //   .then((response) => {
      //     console.log(response);
      //     toastr.success(response.data.success);
      //     loading.value = false;
      //   });
    }

    return {
      getAllDepartment,
      departments,
      loading,
      formData,
      sendData,
      viewCourse,
      courseData,
      id_course,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    this.getAllDepartment();
    this.viewCourse();
  },
};
</script>
