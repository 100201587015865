<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Minhas Turmas</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Turmas</li>
          <li class="breadcrumb-item active">Minhas Turmas</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <span
          class="alert-danger message-alert"
          style="
            width: 100%;
            padding: 5px;
            border-radius: 4pt;
            font-size: 12pt;
            font-weight: bold;
          "
          v-if="error_message !== ''"
        >
          {{ error_message }}
        </span>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-8 col-lg-12 col-md-12">
      <div class="card" v-for="(c, index) in classmates" :key="index">
        <div class="row">
          <div class="col-md-12">
            <!-- <div class="card-header">
              <h3 class="card-title">
                Perfil pessoal
              </h3>
            </div> -->
            <div class="card-body mb-2">
              <!-- <img src="" class="img-card img-fluid" alt=""> -->
              <!-- <button class="btn btn-md btn-primary btn-block">carreagar foto de perfil</button> -->
              <h4 class="text-muted">
                1 Ano {{ c.level_name }} em {{ c.course_name }}
              </h4>
              <hr />
              <br />
              <ul class="list-unstyled">
                <li class="mb-2">
                  <h4 class="">
                    <i class="mdi mdi-chair-school text-primary"></i>
                    {{ c.classmate_name }}
                    <!-- <span style="visibility: hidden;">
                      {{getFormulaByclassmate(c.classmate.id, c.subject.id)}}
                    
                    </span> -->
                  </h4>
                  <small class="text-center"
                    >{{ c.classmate_entry_year }}, Semstre
                    {{ c.classmate_semester_id }}, {{ c.level_name }}
                  </small>
                </li>
                <li class="mb-2">
                  <strong class="">{{ c.subject_name }} </strong
                  ><span class="text-white"></span>
                </li>

                <li class="mb-2">
                  <Button
                    type="primary"
                    class="btn btn-primary btn-md"
                    :loadingEvaluate="loadingEvaluate"
                    @click="addWeightForAssessments(c.subject, c.classmate_id)"
                  >
                    Peso das Avaliações
                  </Button>
                  <!-- :to="{
                      name: 'assessment_studant',
                      params: {
                        subject_id: c.subject.id,
                        classmate_id: c.classmate.id,
                      },
                    }" -->
                  <Button
                    type="success"
                    @click="evaluateStudent(c.subject, c.classmate_id)"
                    class="btn btn-success btn-md ml-2"
                  >
                    Avaliar estudante
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6">
              <h5 class="page-title">Lista de Turmas</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
              <input
                type="text"
                class="form-control"
                v-model="search"
                placeholder="Pesquisar..."
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Turma</th>
                  <th scope="col">Ano Entrada</th>
                  <th scope="col">Nivel Academico</th>
                  <th scope="col">Descricao</th>
                  <th scope="col" colspan="2">Accao</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(tur, index) in turmas" :key="tur.id">
                  <td>{{index + 1}}</td>
                  <td>{{tur.name}}</td>
                  <td>{{tur.entry_year}}</td>
                  <td>{{tur.description}}</td>
                  <td><router-link :to="{name:'classmateDateils', params:{'id_classmate':tur.id}}">Detalhes</router-link></td>
                </tr>
              </tbody>
            </table>

           </div>
        </div>
      </div>
    </div>
  </div> -->

  <div v-show="isClose" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Informar o peso das avaliações</h5>
          <button
            type="button"
            @click="closeModal"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <select class="form-control" @change="SelectedAssissments">
                  <option value="MINI-TESTE" selected>MINI-TESTE</option>
                  <option value="TESTE-1">TESTE-1</option>
                  <option value="TESTE-2">TESTE-2</option>
                  <option value="TESTE-3">TESTE-3</option>
                  <option value="TRABALHO-PESQUISA 1">
                    TRABALHO-PESQUISA 1
                  </option>
                  <option value="TRABALHO-PESQUISA 2">
                    TRABALHO-PESQUISA 2
                  </option>
                  <option value="TRABALHO-PESQUISA 3">
                    TRABALHO-PESQUISA 3
                  </option>
                </select>
              </div>
            </div>
          </div> -->
          <form>
            <div class="col-lg-12 mb-4 text-info">
              <h3 class="font-14">Horas de Contacto</h3>
            </div>

            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label class="form-label">Mini Teste </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="weights[0].percentage"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group">
                  <label class="form-label">Teste 1</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="weights[1].percentage"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group">
                  <label class="form-label">Teste 2 </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="weights[2].percentage"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 mb-4 text-info">
                <h3 class="font-14 text-danger">
                  Horas de Estudo Independente
                </h3>
              </div>

              <div class="col-lg-4">
                <div class="form-group">
                  <label class="form-label">TRABALHO PESQUISA 1 </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="weights[3].percentage"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group">
                  <label class="form-label">TRABALHO PESQUISA 2 </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="weights[4].percentage"
                  />
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group">
                  <label class="form-label">TRABALHO PESQUISA 3 </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="weights[5].percentage"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            @click="handleSubmitWeight"
            class="btn btn-success"
          >
            Salvar
          </button>

          <button
            @click="closeModal"
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            Nao
          </button>
        </div>
      </div>
    </div>
  </div>
  <Loading :loadingData="loading"></Loading>
</template>

<script setup>
import { onMounted, ref } from "vue";
import TeacherDashStoreModule from "@/pages/TeacherDashboard/TeacherDashStoreModule";
import { useRoute } from "vue-router";
import toastr from "toastr";
import { useStore } from "vuex";
import router from "@/router";
import Loading from "@/components/Loading.vue";
import store from "@/store";

const st = useStore();
const classmates = ref([]);
const isClose = ref(false);
var countTrabPercent = ref(1);
var countTestPercent = ref(1);
const assessment_type = ref([]);
const assessment_type_works = ref([]);
const error_message = ref("");
const subject = ref([
  {
    subject_id: "",
  },
]);
const loading = ref(false);
const loadingEvaluate = ref(false);

const classmate = ref([
  {
    classmate_id: "",
  },
]);

const valor = ref();

const weights = ref([
  //   MINI_TESTE:  null,
  //   TESTE_1: null,
  //   TESTE_2: null,
  //   TESTE_3: null,
  //   TRABALHO_PESQUISA_1: null,
  //   TRABALHO_PESQUISA_2: null,
  //   TRABALHO_PESQUISA_3: null,
  // }

  {
    assessment_type: "MINI-TESTE",
    id: "",
    percentage: null,
  },
  {
    id: "",
    assessment_type: "TESTE-1",
    percentage: null,
  },
  {
    id: "",
    assessment_type: "TESTE-2",
    percentage: null,
  },

  {
    id: "",
    assessment_type: "TRABALHO-PESQUISA-1",
    percentage: null,
  },
  {
    id: "",
    assessment_type: "TRABALHO-PESQUISA-2",
    percentage: null,
  },
  {
    id: "",
    assessment_type: "TRABALHO-PESQUISA-3",
    percentage: null,
  },
  {
    id: "",
    assessment_type: "EXAME-NORMAL",
    percentage: null,
  },

  {
    id: "",
    assessment_type: "RECORRENCIA",
    percentage: null,
  },
]);
const testData = ref([
  {
    id: "",
    assessment_type: "MINI-TESTE",
    percentage: "0",
  },
]);

const assessment_home_works = ref([
  {
    id: "",
    assessment_type: "TRABALHO-PESQUISA 1",
    percentage: "0",
  },
]);

const datatTeste = ref({
  testData: [
    {
      titulo: "",
      percentegemTeste1: "",
    },
  ],

  trabalho: [
    {
      titulo: "",
      percentegemTrabalho: 0,
    },
  ],
});

function findAssessmentValue(subject_id, classmate_id) {
  // let classAssessment = {
  weights.value[0].percentage =
    store.state.weightSubject.subject_weight.filter(
      (item) =>
        item.assessment_type === "MINI-TESTE" &&
        item.classmate_id === classmate_id &&
        item.subject_id == subject_id
    )[0] == undefined
      ? null
      : parseInt(
          store.state.weightSubject.subject_weight.filter(
            (item) =>
              item.assessment_type === "MINI-TESTE" &&
              item.classmate_id === classmate_id &&
              item.subject_id == subject_id
          )[0].percentage
        );

  weights.value[1].percentage =
    store.state.weightSubject.subject_weight.filter(
      (item) =>
        item.assessment_type === "TESTE-1" &&
        item.classmate_id === classmate_id &&
        item.subject_id == subject_id
    )[0] == undefined
      ? null
      : parseInt(
          store.state.weightSubject.subject_weight.filter(
            (item) =>
              item.assessment_type === "TESTE-1" &&
              item.classmate_id === classmate_id &&
              item.subject_id == subject_id
          )[0].percentage
        );

  weights.value[2].percentage =
    store.state.weightSubject.subject_weight.filter(
      (item) =>
        item.assessment_type === "TESTE-2" &&
        item.classmate_id === classmate_id &&
        item.subject_id == subject_id
    )[0] == undefined
      ? null
      : parseInt(
          store.state.weightSubject.subject_weight.filter(
            (item) =>
              item.assessment_type === "TESTE-2" &&
              item.classmate_id === classmate_id &&
              item.subject_id == subject_id
          )[0].percentage
        );

  weights.value[3].percentage =
    store.state.weightSubject.subject_weight.filter(
      (item) =>
        item.assessment_type === "TRABALHO-PESQUISA-1" &&
        item.classmate_id === classmate_id &&
        item.subject_id == subject_id
    )[0] == undefined ||
    store.state.weightSubject.subject_weight.filter(
      (item) =>
        item.assessment_type === "TRABALHO-PESQUISA-1" &&
        item.classmate_id === classmate_id &&
        item.subject_id == subject_id
    )[0].percentage == null
      ? null
      : parseInt(
          store.state.weightSubject.subject_weight.filter(
            (item) =>
              item.assessment_type === "TRABALHO-PESQUISA-1" &&
              item.classmate_id === classmate_id &&
              item.subject_id == subject_id
          )[0].percentage
        );

  weights.value[4].percentage =
    store.state.weightSubject.subject_weight.filter(
      (item) =>
        item.assessment_type === "TRABALHO-PESQUISA-2" &&
        item.classmate_id === classmate_id &&
        item.subject_id == subject_id
    )[0] == undefined ||
    store.state.weightSubject.subject_weight.filter(
      (item) =>
        item.assessment_type === "TRABALHO-PESQUISA-2" &&
        item.classmate_id === classmate_id &&
        item.subject_id == subject_id
    )[0].percentage == null
      ? null
      : parseInt(
          store.state.weightSubject.subject_weight.filter(
            (item) =>
              item.assessment_type === "TRABALHO-PESQUISA-2" &&
              item.classmate_id === classmate_id &&
              item.subject_id == subject_id
          )[0].percentage
        );

  weights.value[5].percentage =
    store.state.weightSubject.subject_weight.filter(
      (item) =>
        item.assessment_type === "TRABALHO-PESQUISA-3" &&
        item.classmate_id === classmate_id &&
        item.subject_id == subject_id
    )[0] == undefined ||
    store.state.weightSubject.subject_weight.filter(
      (item) =>
        item.assessment_type === "TRABALHO-PESQUISA-3" &&
        item.classmate_id === classmate_id &&
        item.subject_id == subject_id
    )[0].percentage == null
      ? null
      : parseInt(
          store.state.weightSubject.subject_weight.filter(
            (item) =>
              item.assessment_type === "TRABALHO-PESQUISA-3" &&
              item.classmate_id === classmate_id &&
              item.subject_id == subject_id
          )[0].percentage
        );
}

onMounted(() => {
  getTurmas();
});

async function evaluateStudent(subject_id, classmate_id) {
  try {
    loading.value = true;
    console.log(subject_id, classmate_id);

    const weights = await TeacherDashStoreModule.actions.getFormulaByClass(
      classmate_id,
      subject_id
    );

    if (weights.length == 0) {
      return toastr.info("Por favor adicione os pesos da disciplinas!");
    }

    router.push({
      name: "assessment_studant",
      params: {
        subject_id: subject_id,
        classmate_id: classmate_id,
      },
    });
    console.log("s", weights);
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

function inputPercentage(index, type) {
  assessment_type.value.push({
    id: index,
    assessment_type: type,
    percentage: valor,
  });
}

function SelectedAssissments(evt) {
  if (
    evt.target.value == "TESTE-1" ||
    evt.target.value == "TESTE-2" ||
    evt.target.value == "TESTE-3" ||
    evt.target.value == "MINI-TESTE"
  ) {
    countTestPercent.value += 1;
    // const found = assessment_type.value.includes(evt.target.value);
    const found = testData.value.find((item) =>
      item.assessment_type.includes(evt.target.value)
    );

    if (testData.value.length > 0) {
      if (found) {
        toastr.info(`o ${evt.target.value} ja existe`);
      } else {
        // assessment_type.value.push(evt.target.value);

        testData.value.push({
          id: "",
          assessment_type: evt.target.value,
          percentage: "",
        });
      }
    } else {
      // assessment_type.value.push(evt.target.value);
      testData.value.push({
        id: "",
        assessment_type: evt.target.value,
        percentage: "",
      });
    }
  } else if (
    evt.target.value == "TRABALHO-PESQUISA 1" ||
    evt.target.value == "TRABALHO-PESQUISA 2" ||
    evt.target.value == "TRABALHO-PESQUISA 3"
  ) {
    countTrabPercent.value += 1;
    const found2 = assessment_home_works.value.find((item) =>
      item.assessment_type.includes(evt.target.value)
    );

    if (assessment_home_works.value.length > 0) {
      if (found2) {
        toastr.info(`o ${evt.target.value} ja existe`);
      } else {
        assessment_home_works.value.push({
          id: "",
          assessment_type: evt.target.value,
          percentage: "",
        });
      }
    } else {
      alert("hello");
      assessment_home_works.value.push({
        id: "",
        assessment_type: evt.target.value,
        percentage: "",
      });
    }
  }
}

async function getTurmas() {
  try {
    loading.value = true;
    const profile = JSON.parse(localStorage.getItem("myProfile"));
    if (profile == null) {
      const resp = await TeacherDashStoreModule.actions.getTurmas();
      classmates.value = resp.data.data.classmate_subject;
    }
    classmates.value = profile.classmate_subject;
    console.log(profile.classmate_subject);
  } catch (error) {
  } finally {
    loading.value = false;
  }
}

function addWeightForAssessments(subject_id, classmate_id) {
  isClose.value = true;
  subject.value[0].subject_id = subject_id;
  classmate.value[0].classmate_id = classmate_id;
  findAssessmentValue(subject_id, classmate_id);
}

function closeModal() {
  isClose.value = false;
}

async function handleSubmitWeight() {
  try {
    loading.value = true;

    var assessment_type = [...testData.value, ...assessment_home_works.value];

    const resp = await TeacherDashStoreModule.actions.submitData(
      weights.value,
      classmate.value,
      subject.value
    );

    if (resp.data.status === true) {
      error_message.value = null;
      closeModal();
      // error_message.value = resp.response.data.error_message;
      toastr.success(resp.data.message);
    }
  } catch (error) {
    console.log(error);
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loading.value = false;
  }
}

async function getFormula() {
  const resp = await TeacherDashStoreModule.actions.getAllFormulas();
  classmateFormula.value = resp.data;
  console.log(resp);
}

async function removeDataTest(id, index) {
  const resp = await TeacherDashStoreModule.actions.DeleteDataTest(id);

  classmateFormula.value.splice(index, 1);
  console.log(resp);
}

async function editDataTest(id, index) {
  isEdit.value = true;
  classmateFormula.value.forEach((element) => {
    if (element.id === id) {
      testDataEdit.value.assessment_type = element.assessment_type;
      testDataEdit.value.percentage = element.percentage;
      testDataEdit.value.id = element.id;
    }
  });
}

async function updateTestData(id) {
  loading.value = true;

  const resp = await TeacherDashStoreModule.actions.updateFormula(
    testDataEdit.value,
    id
  );
  if (resp.data == 1) {
    getFormula();
    isEdit.value = false;
  }
  loading.value = false;
}

function addPercenteTeste() {
  countTestPercent.value += 1;
  datatTeste.value.testData.push({ titulo: "", percentegemTeste1: "" });
}

function remove(index) {
  //  datatTeste.value.testData.splice(index, 1);
  assessment_home_works.value.splice(index, 1);
  countTestPercent.value -= 1;
}

function addPercenteTrabalho() {
  countTrabPercent.value += 1;
  datatTeste.value.trabalho.push({ percentegemTrabalho: "" });
}

function removeTrabalho(index) {
  assessment_home_works.value.splice(index, 1);
  countTrabPercent.value -= 1;
}
</script>
