import axios from "axios";
import authHeader from "@/Api/AuthHeader";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {


    async createNewLecture(formData){

        try {
            const resp = axios.post('/api/create-new-lecture', formData, {headers:authHeader()})

            return resp;
        } catch (error) {
            
        }
    }

  }

}