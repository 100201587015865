<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Todas Disciplinas</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Disciplinas</li>
          <li class="breadcrumb-item active">Lista Disciplinas</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="mb-2 col text-center text-sm-left align-items-center">
              <a
                href="javascript:void(0)"
                class="btn mr-1 btn-primary text-center text-sm-left text-white"
                @click="openModalAddNewClassmate"
              >
                <span class="fas fa-plus-circle"></span> Adicionar</a
              >
            </div>

            <div class="mb-2 col-12 col-sm col-lg-4 align-items-center">
              <form class=" ">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Pesquisar..."
                    v-model="searchQuery"
                  />
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary">
                      <i class="fa fa-search"></i>
                    </button>

                    <button type="button" class="btn ml-1 bg-primary">
                      <i class="fa fa-search-plus text-white"></i>
                      <span class="text-white">Busca avançada </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table id="example5" class="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Ano</th>
                  <th>Semestre</th>
                  <th class="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(disc, index) in disciplinas" :key="index">
                  <td>{{ 1 + index }}</td>
                  <td>{{ disc.name }}</td>
                  <td>{{ disc.academic_year_subject }}&#176;</td>
                  <td>{{ disc.semester_name }}&#176;</td>

                  <td aria-colindex="3" role="cell" class="w-140px text-center">
                    <div class="action-column">
                      <a
                        @click="view(index, disc.id)"
                        class="btn btn-sm mr-1 btn-info btn-xs"
                        style="font-size: 13px"
                        ><i class="fa fa-eye text-white"></i>
                      </a>
                      <a
                        @click="edit(index, disc.id)"
                        class="btn btn-sm mr-1 btn-warning btn-xs"
                        style="font-size: 13px"
                        ><i class="fa fa-edit text-white"></i>
                      </a>
                      <a
                        @click="confirmBeforeDelete(index, disc.id)"
                        class="btn btn-sm mr-1 btn-danger btn-xs"
                        style="font-size: 13px"
                        ><i class="fa fa-times text-white"></i>
                      </a>
                    </div>
                  </td>

                  <!-- <td>
                    <router-link :to="{name:'editSubject', params:{id_subject:disc.id}}" class="btn btn-sm btn-primary"><i class="fas fa-edit"></i></router-link> &nbsp;
                     <a href="javascript:void(0);"
                    @click="isdeleteSubject(disc.id, index)"
                    class="btn btn-sm btn-danger"><i class="fas fa-trash"></i></a> 
                  </td> -->
                </tr>
              </tbody>
            </table>

            <Page
              :total="total"
              :model-value="current_page"
              :page-size="parseInt(per_page)"
              @on-change="getDisciplinas"
              v-if="total > 0"
              class="float-right"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalAddNewDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Nova Disciplina</h5>
        <button
          type="button"
          @click="closeModalDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendData">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar Curso</label>
                <select
                  v-model="formData.course_id"
                  class="form-control custom-select"
                >
                  <option v-for="c in course" :value="c.id" :key="c.id">
                    {{ c.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar Semestre</label>
                <select
                  v-model="formData.semester_id"
                  class="form-control custom-select"
                >
                  <option v-for="s in semesters" :value="s.id" :key="s">
                    {{ s.semester_name }}&#176;
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nome da Disciplinas</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.name"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar o ano</label>
                <select
                  v-model="formData.academic_year_id"
                  class="form-control custom-select"
                >
                  <option v-for="a in academicYear" :key="a.id" :value="a.id">
                    {{ a.designation }}&#176;
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Descricao da Disciplinas</label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="formData.description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <Button
          type="success"
          @click="addNewRow"
          data-dismiss="modal"
          :loading="loadingServerStundent"
        >
          Adicionar
        </Button>

        <Button
          type="error"
          @click="closeModalDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalAddUpdateDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Actualizar dados da disciplina</h5>
        <button
          type="button"
          @click="closeModalUpdateDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendData">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar Curso</label>
                <select
                  v-model="formData.course_id"
                  class="form-control custom-select"
                >
                  <option v-for="c in course" :value="c.id" :key="c.id">
                    {{ c.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar Semestre</label>
                <select
                  v-model="formData.semester_id"
                  class="form-control custom-select"
                >
                  <option v-for="s in semesters" :value="s.id" :key="s">
                    {{ s.semester_name }}&#176;
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nome da Disciplinas</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.name"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar Ano</label>
                <select
                  v-model="formData.academic_year_id"
                  class="form-control custom-select"
                >
                  <option v-for="a in academicYear" :key="a.id" :value="a.id">
                    {{ a.designation }}&#176;
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Descricao da Disciplinas</label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="formData.description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <Button
          type="success"
          @click="updateRow"
          data-dismiss="modal"
          :loading="loadingServerStundent"
        >
          Actualizar
        </Button>

        <Button
          type="error"
          @click="closeModalUpdateDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalViewDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Visualizar detalhes da disciplina</h5>
        <button
          type="button"
          @click="closeModalViewteDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendData">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar Curso</label>
                <select
                  disabled
                  v-model="formData.course_id"
                  class="form-control custom-select"
                >
                  <option v-for="c in course" :value="c.id" :key="c.id">
                    {{ c.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar Semestre</label>
                <select
                  disabled
                  v-model="formData.semester_id"
                  class="form-control custom-select"
                >
                  <option v-for="s in semesters" :value="s.id" :key="s">
                    {{ s.semester_name }}&#176;
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nome da Disciplinas</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="formData.name"
                />
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar o ano</label>
                <select
                  disabled
                  v-model="formData.academic_year_id"
                  class="form-control custom-select"
                >
                  <option v-for="a in academicYear" :key="a.id" :value="a.id">
                    {{ a.designation }}&#176;
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Descricao da Disciplinas</label>
                <textarea
                  disabled
                  class="form-control"
                  rows="5"
                  v-model="formData.description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <Button
          type="error"
          @click="closeModalViewteDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>

  <Loading :loadingData="loadingServerStundent"></Loading>
</template>

<script setup>
import { ref, onUnmounted, watch, onMounted } from "vue";
import SubjectStoreModule from "./SubjectStoreModule";
import store from "@/store";
import toastr from "toastr";
import Loading from "../../components/Loading.vue";
import Swal from "sweetalert2";

const disciplinas = ref([]);
const searchQuery = ref("");
const search = ref("");

const loadingServerStundent = ref(false);
const isModalAddNewDept = ref(false);
const isModalAddUpdateDept = ref(false);
const isModalViewDept = ref(false);

const total = ref(null);
const perPage = ref(10);
const current_page = ref();
const semesters = ref([]);
const course = ref([]);
const academicYear = ref([]);
const formData = ref({
  course_id: null,
  name: "",
  description: "",
  semester_id: null,
  academic_year_id: null,
  subject_id: "",
});

function clearInputs() {
  (formData.value.course_id = null),
    (formData.value.name = ""),
    (formData.value.description = ""),
    (formData.value.semester_id = null),
    (formData.value.academic_year_id = null),
    (formData.value.subject_id = "");
}

const getDisciplinas = async (page = 1) => {
  loadingServerStundent.value = true;

  await SubjectStoreModule.actions
    .fetchDisciplinas({
      search: searchQuery.value,
      page: page,
    })
    .then((response) => {
      disciplinas.value = response.data.data;
      total.value = response.data.meta.total;
      perPage.value = response.data.meta.perPage;
      current_page.value = response.data.meta.current_page;
    })
    .catch((error) => {
      console.log(error);
      toastr.error(
        "Erro Na busca de dados do departamento ou contacte o administrador"
      );
    })
    .finally(() => {
      loadingServerStundent.value = false;
    });
};

const fetchSubject = async (page = 1) => {
  loadingServerStundent.value = true;

  await SubjectStoreModule.actions
    .fetchSubject({
      search: searchQuery.value,
      page: page,
    })
    .then((response) => {
      disciplinas.value = response.data.data;
      total.value = response.data.meta.total;
      perPage.value = response.data.meta.perPage;
      current_page.value = response.data.meta.current_page;
    })
    .catch((error) => {
      console.log(error);
      toastr.error(
        "Erro Na busca de dados do departamento ou contacte o administrador"
      );
    })
    .finally(() => {
      loadingServerStundent.value = false;
    });
};

watch(searchQuery, (newValue, oldValue) => {
  if (newValue.length >= 4 || oldValue.length >= 4) {
    fetchSubject();
  } else {
    if (newValue.length == 0 || oldValue.length == 0) {
      fetchSubject();
    }
  }
});

function view(index, id) {
  isModalViewDept.value = true;

  formData.value.name = disciplinas.value[index].name;
  formData.value.description = disciplinas.value[index].description;
  formData.value.semester_id = disciplinas.value[index].semester_id;
  formData.value.academic_year_id = disciplinas.value[index].academic_year_id;
  formData.value.course_id = disciplinas.value[index].course_id;

  formData.value.subject_id = id;
}
function closeModalViewteDept() {
  isModalViewDept.value = false;
  clearInputs();
}

function closeModalDept() {
  isModalAddNewDept.value = false;
  clearInputs();
}
function closeModalUpdateDept() {
  isModalAddUpdateDept.value = false;
  clearInputs();
}

function edit(index, id) {
  isModalAddUpdateDept.value = true;

  formData.value.name = disciplinas.value[index].name;
  formData.value.description = disciplinas.value[index].description;
  formData.value.semester_id = disciplinas.value[index].semester_id;
  formData.value.academic_year_id = disciplinas.value[index].academic_year_id;
  formData.value.course_id = disciplinas.value[index].course_id;
  formData.value.subject_id = id;
}

async function updateRow() {
  try {
    loadingServerStundent.value = true;

    var subject = disciplinas.value.find(
      (item) =>
        item.name == formData.value.name && item.id != formData.value.subject_id
    );

    if (subject) {
      return toastr.info("Esta disciplina ja existe.");
    }

    const resp = await SubjectStoreModule.actions.updateSubject(formData.value);

    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      isModalAddUpdateDept.value = false;
      clearInputs();
    } else {
      toastr.error(resp.data.message);
    }
  } catch (error) {
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loadingServerStundent.value = false;
  }
}

async function addNewRow() {
  try {
    loadingServerStundent.value = true;

    var subject = disciplinas.value.find(
      (item) => item.name == formData.value.name
    );

    if (subject) {
      return toastr.info("Esta disciplina ja existe.");
    }

    const resp = await SubjectStoreModule.actions.createDisciplina(
      formData.value
    );

    if (resp.data.success == true) {
      disciplinas.value.push(resp.data.data[0]);
      toastr.success(resp.data.message);
      isModalAddNewDept.value = false;

      clearInputs();
    } else {
      toastr.error(resp.data.message);
    }

    console.log(resp.data.data);
  } catch (error) {
    console.log(error);
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loadingServerStundent.value = false;
  }
}

async function deleteRow(index, id) {
  try {
    loadingServerStundent.value = true;
    disciplinas.value.splice(index, 1);

    const response = await SubjectStoreModule.actions.deleteSubject(id);
    if (response.data == 1) {
      Swal.fire({
        title: "Eliminado!",
        text: "Registro Eliminado com Sucesso.",
        icon: "success",
      });
    }

    console.log(response.data);
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}
function openModalAddNewClassmate() {
  isModalAddNewDept.value = true;
}

function confirmBeforeDelete(index, id) {
  Swal.fire({
    title: "Tem certeza que deseja eliminar este registro?",
    text: "Nao tera como reverter esta operacao!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim, Eliminar!",
    cancelButtonText: "Cancel!",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteRow(index, id);
    }
  });
}

async function getSemester() {
  try {
    loadingServerStundent.value = true;
    const resp = await SubjectStoreModule.actions.getSemester();

    semesters.value = resp.data;
  } catch (error) {
    console.log();
  } finally {
    loadingServerStundent.value = false;
  }
}

async function getAcademicYear() {
  try {
    loadingServerStundent.value = true;
    const resp = await SubjectStoreModule.actions.getAcademicYear();

    academicYear.value = resp.data.data;
  } catch (error) {
    console.log();
  } finally {
    loadingServerStundent.value = false;
  }
}

async function FilterCourse() {
  try {
    loadingServerStundent.value = true;

    const resp = await SubjectStoreModule.actions.getAllCourrse();
    course.value = resp.data;
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

onMounted(() => {
  getDisciplinas();
  getSemester();
  getAcademicYear();
  FilterCourse();
});
</script>
