<template>
  <div class="authincation h-100">
    <div class="container h-100">
      <div class="car col-lg-8 mx-auto m-2">
        <div class="row justify-content-center h-100 p-5 align-items-center">
          <div class="card col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="text-center mb-4 mt-4">
                    <img
                      src="assets/images/isupec.png"
                      width="180"
                      height="180"
                      alt=""
                    />
                  </div>
                  <div class="auth-form">
                    <!-- <h4 class="text-center mb-4">Acesse a sua conta</h4> -->
                    <form @submit.prevent="handleSubmitButton">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label><strong>Email</strong></label>
                          <input
                            type="text"
                            class="form-control col-lg-12"
                            v-model="data.email"
                            @input="validateEmail"
                          />
                        </div>
                        <p v-if="emailError" style="color: red">
                          {{ emailError }}
                        </p>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label><strong>Senha</strong></label>
                          <input
                            type="password"
                            class="form-control col-lg-12"
                            v-model="data.password"
                            @input="onUserTypePassword"
                          />
                        </div>
                        <p v-if="passwordError" style="color: red">
                          {{ passwordError }}
                        </p>
                      </div>
                      <div
                        class="form-row d-flex justify-content-between mt-4 mb-2 col-lg-11"
                      >
                        <div class="form-group">
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              class="custom-control-label"
                              for="basic_checkbox_1"
                              >Lembrar-me</label
                            >
                          </div>
                        </div>
                        <div class="form-group">
                          <router-link to="/recover_password"
                            >Esqueceu a senha?</router-link
                          >
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="text-center">
                          <Button
                            type="button mx-auto"
                            class="btn-block"
                            style="
                              background-color: #ed7431;
                              color: #fff;
                              border: none;
                              border-radius: 6px;
                              padding-top: 8px;
                              padding-bottom: 8px;
                            "
                            v-on:click="handleSubmitButton"
                            :loading="loading"
                          >
                            Autenticar
                          </Button>
                        </div>
                      </div>
                    </form>
                    <div class="new-account mt-3 text-center">
                      <p>
                        Ainda não fez a sua inscrição?
                        <router-link style="color: #0093dd" to="/inscription"
                          >Clique aqui</router-link
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import toastr from "toastr";
import axios from "axios";
import store from "@/store";
export default {
  data() {
    return {
      data: {
        email: "",
        // email: 'admin@ispocel.ac.mz',
        password: "",
        // password: 'adm1n@1234'
      },
      loading: false,
      URL_API: process.env.VUE_APP_URL_API,
      URL: process.env.VUE_APP_URL,
      emailError: "",
      passwordError: "",
    };
  },

  methods: {
    ...mapActions({
      signIn: "auth/login",
    }),

    async handleSubmitButton() {
      this.loading = true;

      if (this.data.email == "") {
        this.loading = false;

        return (this.emailError = "O campo email não pode estar vazio!");
      }
      if (this.data.password == "") {
        this.loading = false;

        return (this.passwordError = "O campo senha não pode estar vazio!");
      }

      if (this.emailError !== null) {
        this.loading = false;

        return (this.emailError =
          "Por favor, insira um endereço de e-mail válido.");
      }
      await axios.get(this.URL + "sanctum/csrf-cookie").then((response) => {
        console.log(response);
        axios
          .post(this.URL_API + "login", this.data)
          .then(({ data }) => {
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("token_expires_at", new Date().getTime() + 2);
            toastr.success("Bem vindo!! acedeu com sucesso");

            this.signIn(data);
          })
          .catch((response) => {
            toastr.error(
              "Erro de autenticacao verifique suas credenciais ou contacte o administrador" +
                response.data
            );
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    checkToken() {
      if (store.state.auth.authenticated) {
        switch (store.state.auth.user.data.role[0]) {
          case "Teacher":
            window.location.href = "/teacher";

            break;
          case "student":
            window.location.href = "/Student";

            break;
          case "Admin":
            window.location.href = "/dashboard";

            break;
          case "Secretaria":
            window.location.href = "/dasboard";

            break;
          case "Testers":
            window.location.href = "/dashboard";

            break;
          case "Master":
            window.location.href = "/dashboard";

            break;
          default:
            break;
        }
      }
    },
    validateEmail() {
      // Expressão regular para validar o formato de um e-mail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Verifica se o e-mail atende ao formato esperado
      if (!emailRegex.test(this.data.email)) {
        this.emailError = "Por favor, insira um endereço de e-mail válido.";
      } else {
        this.emailError = null;
      }
    },
    onUserTypePassword() {
      if (this.password !== "") {
        this.passwordError = null;
      }
    },
  },

  created() {
    // check user token and redirect to the right way.
    this.checkToken();
  },
};
</script>
