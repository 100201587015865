<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <th scope="col">Curso</th>
          <th scope="col">Nivel Academico</th>
          <th scope="col">Ano Academico</th>
          <th scope="col">Semestre</th>
          <th scope="col">Tipo de Inscricao</th>
          <th scope="col">Ano de Entrada</th> 
          <th scope="col">Estudante Activo</th>
          <!-- <th scope="col">Ações</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, index) in inscriptionsData" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ i.fullName }}</td>
          <td>{{ i.course }}</td>
          <td>{{ i.level }}</td>
          <td>{{ i.academic_year }}&#176;</td>
          <td>{{ i.semester }}</td>
          <td>{{ i.inscriptionType }}</td>
          <td>{{ i.ano_entrada }}</td> 
          <td v-if="i.isActive == 0">
            <span class="badge badge-danger">Inactivo</span>
          </td>
          <td v-else><span class="badge badge-success">Activo</span></td>
          <!-- <td>
            <div class="action-column">
              <a
              @click="editInscriprion(index, i.id)"
              class="btn btn-sm mr-1 btn-warning btn-xs"
              style="font-size: 13px"
              ><i class="fa fa-edit text-white"></i>
            </a>
             </div>
          </td> -->
        </tr>
        <tr v-if="inscriptionsData.length == 0">
       
          <td colspan="12" class="text-center" style="font-style:italic"><span>Ainda nao tem registro</span></td>
        </tr>
      </tbody>
    </table>
    <Page
    :total="total"
    :model-value="current_page"
    :page-size="parseInt(per_page)"
    @on-change="getAllInscriptions"
    v-if="total > 0"
    class="float-right"
  />
  </div>
  <Loading :loadingData="loading"></Loading>
</template>
<script setup>
import { onMounted, ref } from "vue";
import InscriptionStoreModule from "../inscriptions/InscriptionStoreModule.js";
import Loading from "../../components/Loading.vue";
const inscriptionsData = ref([]);
const loading = ref(false);
const total = ref(null);
const current_page = ref(null);
const per_page = ref(null);
onMounted(() => {
  getAllInscriptions();
});

async function getAllInscriptions(page=1) {
  try {
    loading.value = true;
    const resp = await InscriptionStoreModule.actions.getAllInscriptions({
      page:page
    });
    inscriptionsData.value = resp.data.data;
    total.value = resp.data.meta.total;
    per_page.value = resp.data.meta.per_page;
    current_page.value = resp.data.meta.current_page;
     
  } catch (error) {
    console.log(error);
  }finally{
    loading.value = false;
  }
}

function editInscriprion(index, id){
  console.log("inscription");
}
</script>
 