<template>
  <div class="left side-menu">
    <div class="slimscroll-menu" id="remove-scroll">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu" id="side-menu">
          <li class="menu-title">Menu</li>
          <li>
            <router-link to="/dashboard" class="waves-effect">
              <i class="icon-accelerator"></i
              ><span class="badge badge-success badge-pill float-right"></span>
              <span> Dashboard </span>
            </router-link>
          </li>

          <li v-if="activitiesModule == true">
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="mdi mdi-calendar"></i
              ><span>
                Actividades
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li>
                <router-link to="/activities">Todas Actividades</router-link>
              </li>
              <!-- <li>
                <router-link to="/activities/add">Adicionar </router-link>
              </li> -->
            </ul>
          </li>

          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fa fa-list-alt"></i>
              <span>
                Cadastros
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span>
              </span>
            </a>

            <ul class="submenu">
              <li v-if="deparmentModule == true">
                <router-link to="/departments"
                  ><span class="fas fa-building" style="font-size: 10px"></span>
                  Departamentos</router-link
                >
              </li>
              <li v-if="employeesModule == true">
                <router-link to="/employees"
                  ><span class="fas fa-tshirt" style="font-size: 10px"></span>
                  Funcionarios</router-link
                >
              </li>

              <li v-if="courseModule == true">
                <router-link to="/courses"
                  ><span
                    class="fas fa-graduation-cap"
                    style="font-size: 10px"
                  ></span>
                  Cursos</router-link
                >
              </li>
              <li v-if="classmateModule == true">
                <router-link to="/classmates"
                  ><span
                    class="mdi mdi-chair-school"
                    style="font-size: 10px"
                  ></span>
                  Turmas</router-link
                >
              </li>
              <li v-if="subjectModule == true">
                <router-link to="/subject"
                  ><span class="fas fa-book" style="font-size: 10px"></span>
                  Disciplinas</router-link
                >
              </li>
              <li>
                <a href="javascript:void(0);" class="waves-effect"
                  ><i class="fa fa fa-clipboard" style="font-size: 10px"></i>
                  <span>
                    Auxiliares
                    <span class="float-right menu-arrow"
                      ><i class="mdi mdi-chevron-right"></i
                    ></span>
                  </span>
                </a>
                <ul class="submenu">
                  <li v-if="precedenceModule == true">
                    <router-link to="/subject/precedences"
                      ><span class="fa fa-share" style="font-size: 10px"></span>
                      Precedencias</router-link
                    >
                  </li>
                </ul>
              </li>
              <!-- fa fa icon-workshirt -->
            </ul>
          </li>

          <!-- <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-building"></i
              ><span>
                Departamentos
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/add-department">Adicionar </router-link></li>
              <li><router-link to="/all-departments">Todos Departamentos</router-link></li>
            </ul>
          </li> -->

          <!-- <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-graduation-cap"></i
              ><span>
                Cursos
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/add-course">Adicionar</router-link></li>
              <li><router-link to="/all-courses">Listar</router-link></li>
            </ul>
          </li> -->
          <!-- <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-book"></i
              ><span>
                Disciplinas
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/subject">Adicionar</router-link></li>
              <li><router-link to="/all-subject">Listar</router-link></li>
              <li><router-link to="/subject/precedences">Precedencias</router-link></li>
            </ul>
          </li> -->
          <!-- <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-book"></i
              ><span>
                Curso x Disciplinas
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/subject_course">Adicionar</router-link></li>
              <li><router-link to="/all">Listar</router-link></li>
            </ul>
          </li> -->

          <!-- <li>

            

            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-building"></i>
              <span>
                Turmas
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span>
              </span>
            </a>
            <ul class="submenu">
              <li><router-link to="/classmates">Adicionar</router-link></li>
              <li><router-link to="/all-classmates">Listar</router-link></li>
            </ul>
          </li> -->
          <li v-if="teacherModule == true">
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-chalkboard-teacher"></i>
              <span>
                Professores
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span>
              </span>
            </a>
            <ul class="submenu">
              <li><router-link to="/teachers">Gerenciar</router-link></li>
            </ul>
          </li>
          <li v-if="studentModule == true">
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-users"></i
              ><span>
                Estudantes
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/all-students">Gerenciar</router-link></li>
            </ul>
          </li>

          <li v-if="inscriptionModule == true">
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="icon-spread"></i
              ><span>
                Inscrições
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li>
                <router-link to="/all-inscriprions">Gerenciar</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-coins"></i
              ><span>
                Pagamentos
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li v-if="paymentModule == true">
                <router-link to="/payments">Pagamentos</router-link>
              </li>
              <li v-if="extractModule == true">
                <router-link to="/payments/extract">Extracto</router-link>
              </li>
              <li v-if="debtModule == true">
                <router-link to="/payments/debt">Dividas</router-link>
              </li>
              <li>
                <router-link to="/payments/paymentInscriptions_renews"
                  >Renovações/Inscrições</router-link
                >
              </li>
              <li v-if="otherServiceModule == true">
                <router-link to="/extract">Outros Servicos</router-link>
              </li>
            </ul>
          </li>
          <li v-if="finesModule == true">
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fa fa-gavel"></i
              ><span>
                Multas
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><a href="#">Adicionar</a></li>
              <li><a href="#">Listar</a></li>
            </ul>
          </li>
          <li v-if="serviceModule == true">
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-hand-holding"></i
              ><span>
                Serviços
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li>
                <router-link to="/services">Gerenciar Serviços</router-link>
              </li>
            </ul>
          </li>
          <li v-if="manageReferenceModule == true">
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-receipt"></i
              ><span>
                Ger. Referencias
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li>
                <router-link to="/services/references">Gerenciar</router-link>
              </li>
            </ul>
          </li>

          <li v-if="confirmPaymentModule == true">
            <router-link to="/confirm_payment" class="waves-effect"
              ><i class="icon-upload"></i
              ><span> Confirmar Pag. </span></router-link
            >
          </li>
          <li v-if="supportModule == true">
            <a to="/support" class="waves-effect"
              ><i class="fa fa-comments"></i>
              <span>
                Atendimento
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span>
              </span>
            </a>

            <ul class="submenu">
              <li>
                <a to="/settings"
                  ><span class="fa fa fa-phone-square"></span> Atendimento</a
                >
              </li>
              <li>
                <a to="/settings"
                  ><span class="fa fa-headphones"></span> Formas</a
                >
              </li>
              <li>
                <a href="#"><span class="fa fa fa-pen"></span> Assunto</a>
              </li>
              <li>
                <a href="#"><span class="fa fa fa-list"></span> Situação</a>
              </li>
            </ul>
          </li>
          <li>
            <a to="/settings" class="waves-effect"
              ><i class="fas fa-cogs"></i>
              <span>
                Definições
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span>
              </span>
            </a>

            <ul class="submenu">
              <li v-if="monthly_fees == true">
                <router-link to="/entities"
                  ><span class="fa fa-coins"></span> Entidades</router-link
                >
              </li>
              <li v-if="monthly_fees == true">
                <router-link to="/settings"
                  ><span class="fa fa-coins"></span> Taxas de Matricula &
                  Mensalidades</router-link
                >
              </li>
              <li v-if="companyLogoModule == true">
                <a to="/settings"
                  ><span class="fa fa-image"></span> Marca da empresa</a
                >
              </li>
              <li v-if="users == true">
                <router-link to="/users"
                  ><span class="fas fa-user"></span> Usuários</router-link
                >
              </li>
              <li v-if="companyProfileModule == true">
                <a href="#"
                  ><span class="fa fa-briefcase"></span> Dados da empresa</a
                >
              </li>
              <li v-if="emailModelModule == true">
                <a href="#"
                  ><span class="fa fa-envelope-open"></span> Modelos de
                  e-mails</a
                >
              </li>
            </ul>
          </li>
          <!-- <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><font-awesome-icon icon="fa-solid fa-user-secret" /><span>
                Funcoes
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/add-roles">Adicionar</router-link></li>
              <li><router-link to="all-roles">Listar</router-link ></li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><font-awesome-icon icon="fa-solid fa-user-secret" /><span>
                Previlegios
                <span class="float-right menu-arrow"
                  ><i class="mdi mdi-chevron-right"></i
                ></span> </span
            ></a>
            <ul class="submenu">
              <li><router-link to="/add-permissions">Adicionar</router-link></li>
              <li><a href="#">Listar</a></li>
            </ul>
          </li> -->
        </ul>
      </div>
      <!-- Sidebar -->
      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
</template>
 

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";

const store = useStore();

const dashboardArea = store.state.auth.role_permission.dashboard.read;
const deparmentModule =
  store.state.auth.role_permission.registers.departments.read;
const employeesModule =
  store.state.auth.role_permission.registers.employees.read;
const classmateModule =
  store.state.auth.role_permission.registers.classmates.read;
const courseModule = store.state.auth.role_permission.registers.courses.read;
const subjectModule = store.state.auth.role_permission.registers.subjects.read;
const precedenceModule =
  store.state.auth.role_permission.registers.precedences.read;
const activitiesModule = store.state.auth.role_permission.activities.read;
const teacherModule = store.state.auth.role_permission.teacher.read;
const studentModule = store.state.auth.role_permission.student.read;
const inscriptionModule = store.state.auth.role_permission.inscriptions.read;
const debtModule = store.state.auth.role_permission.payments.debt.read;
const extractModule = store.state.auth.role_permission.payments.extracts.read;
const otherServiceModule =
  store.state.auth.role_permission.payments.otherServices.read;
const paymentModule = store.state.auth.role_permission.payments.payments.read;
const renewInscriptionModule =
  store.state.auth.role_permission.payments.renew_inscritpion.read;
const finesModule = store.state.auth.role_permission.fines.read;
const serviceModule = store.state.auth.role_permission.services.read;
const manageReferenceModule = store.state.auth.role_permission.activities.read;
const confirmPaymentModule = store.state.auth.role_permission.activities.read;
const supportModule = store.state.auth.role_permission.support.read;
const companyLogoModule =
  store.state.auth.role_permission.definitions.company_logo.read;
const companyProfileModule =
  store.state.auth.role_permission.definitions.company_profile.read;
const emailModelModule =
  store.state.auth.role_permission.definitions.emailModel.read;
const monthly_fees =
  store.state.auth.role_permission.definitions.monthly_fees.read;
const users = store.state.auth.role_permission.definitions.users.read;

// store.state.auth.authenticated

onMounted(() => {
  console.log(store.state.auth.role_permission);
});
</script>