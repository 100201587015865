<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Todas Funcoes</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Funcoes</li>
          <li class="breadcrumb-item active">Lista Funcoes</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="row tab-content">
        <div id="list-view" class="tab-pane fade active show col-lg-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-sm">
                  <h4 class="card-title">Todos Funcoes</h4>
                </div>

                <div class="col-sm-6">
                  <router-link
                    to="/add-roles"
                    class="btn btn-primary float-right"
                    >+ Adicionar Funcoes</router-link
                  >
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="form-group float-right m-2">
                <form action="" role="search" class="app-search">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Pesquisar..."
                    v-model="search"
                  />
                  <button type="submit"><i class="fa fa-search"></i></button>
                </form>
              </div>
              <div class="table-responsive">
                <table id="example3" class="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nome</th>
                      <th>Accoes</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(roles, index) in dataRoles" :key="roles.id">
                      <td>{{ index + 1 }}</td>
                      <td>{{ roles.name }}</td>
                      <td>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-primary"
                          ><i class="fas fa-edit"></i
                        ></a>
                        <a
                          @click="isdeleteRole(roles.id, index)"
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger ml-1"
                          ><i class="fas fa-trash-alt"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isDelete" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Eliminar</h5>
            <button
              type="button"
              @click="closeModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Tem certeza que deseja eliminar esta funçao?</p>
          </div>
          <div class="modal-footer">
            <button type="button" @click="deleteRole()" class="btn btn-danger">
              {{ isDeleting == false ? "Eliminar" : "Eliminando..." }}
            </button>
            <button
              @click="closeModal"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Nao
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import RolesStoreModule from "./RolesStoreModule";
import toastr from "toastr";

export default {
  setup() {
    const dataRoles = ref([]);
    const search = ref("");
    const isDelete = ref(false);
    const index = ref(null);
    const Role_id = ref();
    const isDeleting = ref(false);

    async function getAllRoles() {
      const resp = await RolesStoreModule.actions.getAllRoles();
      dataRoles.value = resp.data;
      console.log(resp.data);
    }

    async function deleteRole() {
      isDeleting.value = true;
      
      const response = await RolesStoreModule.actions.deleteRole(Role_id.value);

      if (response.data.success == true) {
        dataRoles.value.splice(index.value, 1);
        isDeleting.value = false;
        isDelete.value = false;

        toastr.success("Funcao adicionada com sucesso");
      }
    }
    function isdeleteRole(id, i) {
      isDelete.value = true;
      index.value = i;
      Role_id.value = id;
    }

    function closeModal() {
      isDelete.value = false;
    }

    return {
      dataRoles,
      getAllRoles,
      search,
      closeModal,
      isDelete,
      deleteRole,
      Role_id,
      isdeleteRole,
      index,
      isDeleting,
    };
  },

  created() {
    this.getAllRoles();
  },
};
</script>
