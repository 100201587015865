<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Jassa</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row justify-content-center">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Detalhes das Disciplinas</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="sendData">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecionar Curso</label>
                  <select v-model="formData.course_id" class="form-control custom-select">
                    <option v-for="c in course" :value="c.id" :key="c.id">{{c.name}}</option>
                  </select>
                </div>
              </div>
             
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecionar Semestre</label>
                  <select v-model="formData.semester_id" class="form-control custom-select">
                    <option v-for="s in semesters" :value="s.id" :key="s">{{s.semester_name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Disciplinas</label>
                  <input type="text" class="form-control" v-model="formData.name" />
                </div>
              </div>

                <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecionar Ano Academico</label>
                  <select  v-model="formData.academic_year_id" class="form-control custom-select">
                    <option   v-for="a in academicYear" :key="a.id"  :value="a.id">{{a.designation}}</option>
                 
                  </select>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Descricao da Disciplinas</label>
                  <textarea class="form-control" rows="5" v-model="formData.description"></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <button type="submit" class="btn btn-primary float-right">{{ loading == false ? "Adicionar" :'Adicionando...'}} </button>
                <!-- <button type="submit" class="btn btn-light">Voltar</button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted } from 'vue'
import { mapGetters } from 'vuex'
import SubjectStoreModule from './SubjectStoreModule';
import store from '@/store';
import toastr from 'toastr'
// import vSelect from "vue-select";
export default {
  // components: {
  //   vSelect
  // },
  setup() {
    const DISCIPLINA_MODULE_NAME = "Disciplina";

    if (!store.hasModule(DISCIPLINA_MODULE_NAME)) {
      store.registerModule(DISCIPLINA_MODULE_NAME, SubjectStoreModule);
    }


    onUnmounted(() => {
      if (store.hasModule(DISCIPLINA_MODULE_NAME)) {
        store.unregisterModule(DISCIPLINA_MODULE_NAME)
      }
    });

    const formData = {
      course_id: null,
      name: "",
      description: "",
      semester_id:null,
      academic_year_id:null
    }
    const semesters = ref([])
    const loading = ref(false)
    const course = ref([]);
     const academicYear = ref([]);

    const form = ref(JSON.parse(JSON.stringify(formData)))

  async function FilterCourse() {
    try {
      await store.dispatch('Disciplina/getAllCourrse').then((response) => {
        course.value = response.data
      })
      
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem();
        window.location.href = "/login";
      }
    }
    }

    async function getSemester() {
        const resp =  await SubjectStoreModule.actions.getSemester();

        semesters.value = resp.data
        console.log("semetres", resp.data);
    }

    
    async function getAcademicYear() {
        const resp =  await SubjectStoreModule.actions.getAcademicYear();

        academicYear.value = resp.data.data
        console.log("semetres", resp.data.data);
    }
    

async function filterSemester(){
  await store.dispatch('Disciplina/getSemesters').then(response => {
    semesters.value = response.data
  })
}
    async function sendData() {

      loading.value = true;
 
      if (formData.name == "" || formData.description == "" || formData.course_id == null) {

        toastr.warning("Por favor preencha os campos obrigatorios");
        loading.value = false;

        return;
      }
      // console.log(form.value)

      const response = await SubjectStoreModule.actions.createDisciplina(formData);
        console.log(response.data[0]);   
      toastr.success(response.data[0])
          loading.value = false
          clearFields();

      // store
      //   .dispatch("Disciplina/createDisciplina", form.value)
      //   .then((response) => {
      //     // console.log(response)
      //     toastr.success(response.data[0])
      //     loading.value = false
      //     clearFields();
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //     toastr.error("Erro! Erro na requisicao de dados")
      //   })
    }

   

    function clearFields() {
      formData.name = "";
      formData.description = ""
    }

    return {
      formData,
      sendData,
      loading,
      clearFields,
      FilterCourse,
      course,
      form,
      getSemester,
      semesters,
      getAcademicYear,
      academicYear,
      filterSemester,
      semesters, 
    }

  },
  computed: {
    ...mapGetters(['token'])
  },
  created() {
    this.FilterCourse()
    this.getSemester()
    this.getAcademicYear()
    this.filterSemester()
 
  },
};
</script>
