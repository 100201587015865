<template>
  <div id="estudantes">
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Todas Inscrições</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Inicio</a></li>
            <li class="breadcrumb-item">Inscrições</li>
            <li class="breadcrumb-item active">Lista Inscrições</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>

    <!-- START ROW -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card m-b-30">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-9">
                <h4 class="mt-2 header-title mb-4">Todas Inscrições</h4>
              </div>
              <div class="col-lg-3">
                <select
                  class="form-control"
                  style="border-radius: 16px"
                  @change="getInscriptionByStatus"
                >
                  <option value="Todas">Todas</option>
                  <option value="pagas">Pagas</option>
                  <option value="pendentes">Pendentes</option>
                  <option value="candidaturas">Candidaturas</option>
                  <option value="renovacoes-concluidas">
                    Renovacoes-concluidas
                  </option>
                  <option value="renovacoes-pendentes">
                    Renovacoes-pendentes
                  </option>
                </select>
              </div>
            </div>
            <RouterView></RouterView>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const route = useRouter();
const teacherLoadingData = ref(true);
async function getInscriptionByStatus(evt) {
  console.log(evt.target.value);
  if (evt.target.value == "Todas") {
    route.push("/all-inscriprions");
  } else if (evt.target.value == "pagas") {
    route.push("/payedInscriptions");
  } else if (evt.target.value == "pendentes") {
    route.push("/pendentsInscriptions");
  } else if (evt.target.value == "candidaturas") {
    route.push("/candidate");
  } else if (evt.target.value == "renovacoes-concluidas") {
    route.push("/concludedRenew");
  } else if (evt.target.value == "renovacoes-pendentes") {
    route.push("/RenewPendents");
  }
}
</script>
 