<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title">
          <h4>Perfil</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="index.html">Inicio</a></li>
          <li class="breadcrumb-item">
            <a href="javascript:void(0);">Admin</a>
          </li>
          <li class="breadcrumb-item active">
            <a href="javascript:void(0);">Perfil</a>
          </li>
        </ol>
      </div>
    </div>
    <!-- End row -->
  </div>
  <div class="row">
    <div class="col-xl-12 col-xxl-12 col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3">
              <label for="">Nome</label>
              <div class="form-group">
                <input
                  type="text"
                  disabled
                  v-model="users.fullName"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-lg-3">
              <label for="">Sexo</label>
              <div class="form-group">
                <input
                  type="text"
                  disabled
                  v-model="users.gender"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-lg-3">
              <label for="">Data Nascimento</label>
              <div class="form-group">
                <input
                  type="text"
                  v-model="users.birthDate"
                  disabled
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <label for="">Tipo de Documento</label>
              <div class="form-group">
                <input
                  type="text"
                  v-model="users.doc_type"
                  disabled
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-lg-3">
              <label for="">Numero do Documeno</label>
              <div class="form-group">
                <input
                  type="text"
                  v-model="users.doc_number"
                  disabled
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-lg-3">
              <label for="">Grupo</label>
              <div class="form-group">
                <input
                  type="text"
                  v-model="role"
                  disabled
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-lg-3">
              <label for="">Membro desde</label>
              <div class="form-group">
                <input
                  type="text"
                  v-model="users.created_at"
                  disabled
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-lg-12">
              <label for="">Dados de Acesso</label>
              <div class="col-lg-12 p-3 border rounded">
                <div class="row">
                  <div class="col-lg-3">
                    <label for="">Email</label>
                    <div class="form-group">
                      <input type="text" disabled @input="validateEmail" v-model="email" class="form-control" />
                    </div>
                    <p v-if="emailError" style="color: red">{{ emailError }}</p>

                  </div>

                  <div class="col-lg-3">
                    <label for="">Senha Actual</label>
                    <div class="form-group">
                      <div class="input-group-append">
                        <input
                          @input="validatecurrentPassword"
                          :type="showPasswordCurrent ? 'text' : 'password'"
                          v-model="userForm.currentPassword"
                          class="form-control"
                        />
                        <span class="p-viewer" @click="showCurrentPasswordClick">
                          <i
                            v-if="showPasswordCurrent == true"
                            class="fa fa-eye"
                            aria-hidden="true"
                          >
                          </i>
                          <i v-else class="fa fa-eye-slash" aria-hidden="true"> </i>
                        </span>
                      </div>
                    </div>
                    
                    <p v-if="CurrentpasswordError" style="color: red">{{ CurrentpasswordError }}</p>

                  </div>

                  <div class="col-lg-3">
                    <label for="">Nova Senha</label>
                    <div class="form-group">
                      <div class="input-group-append">
                        <input
                          @input="validateNewPassword"
                          :type="showPassword ? 'text' : 'password'"
                          v-model="userForm.newPassword"
                          class="form-control"
                        />
                        <span class="p-viewer" @click="showPasswordClick">
                          <i
                            v-if="showPassword == true"
                            class="fa fa-eye"
                            aria-hidden="true"
                          >
                          </i>
                          <i v-else class="fa fa-eye-slash" aria-hidden="true"> </i>
                        </span>
                      </div>
                    </div>
                    <p v-if="passwordError" style="color: red">{{ passwordError }}</p>

                  </div>

                  <div class="col-lg-3">
                    <label for="">Confirmar Senha</label>
                    <div class="form-group">
                      <div class="input-group-append">
                        <input
                          @input="validatePassword"
                          :type="showPasswordConf ? 'text' : 'password'"
                          v-model="userForm.confirmPassword"
                          class="form-control"
                        />
                        <span class="p-viewer" @click="showConfPasswordClick">
                          <i
                            v-if="showPasswordConf == true"
                            class="fa fa-eye"
                            aria-hidden="true"
                          >
                          </i>
                          <i v-else class="fa fa-eye-slash" aria-hidden="true"> </i>
                        </span>
                      </div>
                      <p v-if="conf_pass_message" style="color: red">{{ conf_pass_message }}</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button @click="submitForm" class="btn btn-success">Actualizar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loading :loadingData="loadingServerStundent"></Loading>

</template>

<script setup>
import Loading from "../../components/Loading.vue";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import UserStoreModule from "../Users/UserStoreModule";
import toastr from "toastr";

const loadingServerStundent = ref(false);
const store = useStore();
const users = store.state.auth.user.data.person;
const email = store.state.auth.user.data.email;
const role = ref(store.state.auth.user.data.role[0]);


const showPassword = ref(false);
const showPasswordConf = ref(false);
const showPasswordCurrent = ref(false);
const passwordValid = ref(false);
const conf_pass_message = ref("");
const passwordError = ref("");
const CurrentpasswordError = ref(""); 
const emailError = ref("");


const userForm = ref({
  currentPassword: "",
  newPassword: "", 
  confirmPassword: "",
  email: store.state.auth.user.data.email, 
  userId:store.state.auth.user.data.person.user_id
});
 

function showCurrentPasswordClick() {
  if (userForm.value.currentPassword == "") {
    return toastr.info("por favor informe senha");
  }
  showPasswordCurrent.value = !showPasswordCurrent.value;
}


function showPasswordClick() {
  if (userForm.value.password == "") {
    return toastr.info("por favor informe senha");
  }
  showPassword.value = !showPassword.value;
}

function showConfPasswordClick() {
  if (userForm.value.confirmPassword == "") {
    return toastr.info("por favor informe senha");
  }
  showPasswordConf.value = !showPasswordConf.value;
}


async function submitForm(){
  try {
    loadingServerStundent.value = true;

    if (userForm.value.newPassword != userForm.value.confirmPassword) {
        loadingServerStundent.value = false; 
       conf_pass_message.value =
        "A senha de confirmação fornecida não está correta";
      return;
     }

     const resp = await UserStoreModule.actions.updateDataAccess(userForm.value);

     if (resp.data.success == true) {
        toastr.success(resp.data.message);
        userForm.value.newPassword = "";
        userForm.value.confirmPassword = "";
        userForm.value.currentPassword = "";
     } 
  } catch (error) {
    
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }


  }finally{
    loadingServerStundent.value = false;

  }
}


function validateEmail() {
  // Expressão regular para validar o formato de um e-mail
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Verifica se o e-mail atende ao formato esperado
  if (!emailRegex.test(userForm.value.email)) {
    emailError.value = "Por favor, insira um endereço de e-mail válido.";
  } else {
    emailError.value = null;
  }
}


function validatecurrentPassword() {
  // Requisitos mínimos de segurança
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(userForm.value.currentPassword);
  const hasLowerCase = /[a-z]/.test(userForm.value.currentPassword);
  const hasDigit = /\d/.test(userForm.value.currentPassword);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(userForm.value.currentPassword);

  // Verifica se a senha atende aos critérios
  if (
    userForm.value.currentPassword.length < minLength ||
    !hasUpperCase ||
    !hasLowerCase ||
    !hasDigit ||
    !hasSpecialChar
  ) {
    CurrentpasswordError.value =
      "A senha deve ter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, números e caracteres especiais.";
  } else {
    passwordValid.value = true;
    CurrentpasswordError.value = null;
  }
}

function validateNewPassword() {
  // Requisitos mínimos de segurança
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(userForm.value.newPassword);
  const hasLowerCase = /[a-z]/.test(userForm.value.newPassword);
  const hasDigit = /\d/.test(userForm.value.newPassword);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(userForm.value.newPassword);

  // Verifica se a senha atende aos critérios
  if (
    userForm.value.newPassword.length < minLength ||
    !hasUpperCase ||
    !hasLowerCase ||
    !hasDigit ||
    !hasSpecialChar
  ) {
    passwordError.value =
      "A senha deve ter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, números e caracteres especiais.";
  } else {
    passwordValid.value = true;
    passwordError.value = null;
  }
}

function validatePassword() {
  // Requisitos mínimos de segurança
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(userForm.value.confirmPassword);
  const hasLowerCase = /[a-z]/.test(userForm.value.confirmPassword);
  const hasDigit = /\d/.test(userForm.value.confirmPassword);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(userForm.value.confirmPassword);

  // Verifica se a senha atende aos critérios
  if (
    userForm.value.confirmPassword.length < minLength ||
    !hasUpperCase ||
    !hasLowerCase ||
    !hasDigit ||
    !hasSpecialChar
  ) {
    conf_pass_message.value =
      "A senha deve ter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, números e caracteres especiais.";
  } else {
    passwordValid.value = true;
    conf_pass_message.value = null;
  }
}
</script>
<style scoped>
.p-viewer,
.p-viewer2 {
  margin-left: -20px;
  margin-top: 10px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
</style>