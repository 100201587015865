<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Aula</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Professor</li>
          <li class="breadcrumb-item active">Aula</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-12 col-xxl-8 col-lg-8">
      <div class="card">
        <div class="card-body">
          <div class="pt-3">
            <div class="settings-form">
              <h4 class="text-primary mb-3">Adicionar Nova Aula</h4>
              <form @submit.prevent="handleSendFormData">
                <div class="row">
                  <div class="col-lg-12 col-md-6 col-sm-12">
                    <div class="form-group">
                      <label>Titulo*</label>
                      <input
                        v-model="lesson.title"
                        type="text"
                        placeholder="Titulo"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group">
                      <label class="form-label">Selecione o Curso*</label>
                      <select class="form-control" v-model="lesson.course_id">
                        <option value="Class">Selecione o Curso</option>
                        <option :value="c.id" v-for="c in courses" :key="c.id">
                          {{ c.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12 mt-3">
                    <div class="form-group">
                      <label class="form-label">Selecione a Disciplina*</label>
                      <select
                        class="form-control"
                        v-model="lesson.subject_id"
                        placeholder="Selecione a Disciplina"
                      >
                        <option
                          :value="sub.id"
                          v-for="sub in subjects"
                          :key="sub.id"
                        >
                          {{ sub.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Descricao</label>
                      <input
                        v-model="lesson.description"
                        type="text"
                        placeholder="Descricao"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Data de Validade*</label>
                      <input
                        v-model="lesson.expiresAt"
                        type="date"
                        placeholder="Descricao"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <div v-if="previewURL">
                        <embed
                          :src="previewURL"
                          type="application/pdf"
                          width="20%"
                          height="200px"
                        />
                      </div>
                      <label for="fileUpload" style="cursor: pointer">
                        Carregar ficheiro*
                        <img
                          src="assets/images/upload.svg"
                          width="15"
                          height="15"
                          alt=""
                        />
                      </label>
                      <input
                        @change="onChangeFileHandle"
                        type="file"
                        class="form-control"
                        id="fileUpload"
                        hidden
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="custom-checkbox" style="margin-left: 1.5em">
                        <input
                          type="checkbox"
                          id="LectureAvailable"
                          v-model="lesson.is_available"
                          class="custom-control-input"
                        />
                        <label
                          for="LectureAvailable"
                          class="custom-control-label text-white"
                          >Aula disponivel?</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <div class="custom-checkbox" style="margin-left: 1.5em">
                        <input
                          type="checkbox"
                          id="LectureAssessment"
                          v-model="lesson.is_assessment"
                          class="custom-control-input"
                        />
                        <label
                          for="LectureAssessment"
                          class="custom-control-label text-white"
                          >E uma avaliacao?</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  class="btn btn-primary float-right"
                  type="submit"
                  v-if="loading == false"
                >
                  Adicionar
                </button>
                <button
                  class="btn btn-primary float-right"
                  type="button"
                  v-else
                >
                  Adicionando...
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import LectureStoreModule from "./LectureStoreModule";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";
import SubjectStoreModule from "@/pages/subject/SubjectStoreModule";
import toastr from "toastr";
export default {
  setup() {
    const lesson = ref({
      title: "",
      description: "",
      file_attachement: null,
      is_available: false,
      course_id: null,
      expiresAt: null,
      subject_id: null,
      is_assessment: false,
      level_id: 1,
    });

    const previewURL = ref();
    const fileUpload = ref();
    const loading = ref(false);
    const courses = ref([]);
    const subjects = ref([]);

    onMounted(() => {
      getAllCourses();
      getSubjects();
    });

    async function handleSendFormData() {
      try {
        loading.value = true;

        if (
          lesson.value.title == "" ||
          lesson.value.course_id == null ||
          lesson.value.subject_id == null ||
          lesson.value.expiresAt == null
        ) {
          loading.value = false;
          toastr.info("Por favor preencha os campos obrigatorios");

          return;
        }

        let formData = new FormData();
        formData.append("title", lesson.value.title);
        formData.append("description", lesson.value.description);
        formData.append("is_available", lesson.value.is_available);
        formData.append("course_id", lesson.value.course_id);
        formData.append("file_attachement", lesson.value.file_attachement);
        formData.append("expiresAt", lesson.value.expiresAt);
        formData.append("subject_id", lesson.value.subject_id);
        formData.append("is_assessment", lesson.value.is_assessment);
        formData.append("level_id", lesson.value.level_id);

        const resp = axios
          .post("/api/create-new-lecture", formData, {
            headers: authHeader(),
          })
          .then((response) => {
            console.log(response);
            if (response.data.status == true) {
              toastr.success(response.data.message);
            } else {
              toastr.error(response.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            loading.value = false;
          });
        // if (resp.data.status == true) {
        //   toastr.success(resp.data.message);
        // }else{
        //   toastr.error(resp.data.message);

        // }
        // loading.value = false;

        // console.log(resp);

        // const resp = await LectureStoreModule.actions.createNewLecture(
        //   lesson.value
        // );
      } catch (error) {}
    }
     
    async function getSubjects() {
      const resp = await SubjectStoreModule.actions.getSubjects();
      subjects.value = resp.data.data;
    }
    async function getAllCourses() {
      await axios
        .get("/api/getAllCourses")
        .then((response) => {
          // console.log(response);
          courses.value = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    function onChangeFileHandle(evt) {
      lesson.value.file_attachement = evt.target.files[0];
      console.log(lesson.value.file_attachement);
      previewDocument();
    }

    function previewDocument() {
      if (fileUpload) {
        const reader = new FileReader();
        reader.onload = (event) => {
          previewURL.value = event.target.result;
        };

        reader.readAsDataURL(lesson.value.file_attachement);
      }
    }

    return {
      lesson,
      onChangeFileHandle,
      previewURL,
      fileUpload,
      previewDocument,
      handleSendFormData,
      loading,
      courses,
      getAllCourses,
      subjects,
      getSubjects,
    };
  },
};
</script>
