<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Jassa</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Detalhes das Disciplinas</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="sendData">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Disciplinas</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formDatas.name"
                  />
                </div>
              </div>

              <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecionar Curso</label>
                  <select v-model="formDatas.course_id" class="form-control custom-select">
                    <option v-for="c in course" :key="c.id" :value="c.id">{{c.name}}</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Descricao da Disciplinas</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="formDatas.description"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <button type="submit" class="btn btn-primary float-right">
                  {{ loading == false ? "Actualizar" : "Actualizando..." }}
                </button>
                <!-- <button @click="backHistory" class="btn btn-light">
                  Voltar
                </button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted, onMounted } from "vue";
import { mapGetters } from "vuex";
import SubjectStoreModule from "./SubjectStoreModule";
import store from "@/store";
import toastr from "toastr";
import { useRoute } from "vue-router";

export default {
  setup() {
    const DISCIPLINA_MODULE_NAME = "Disciplina";

    if (!store.hasModule(DISCIPLINA_MODULE_NAME)) {
      store.registerModule(DISCIPLINA_MODULE_NAME, SubjectStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(DISCIPLINA_MODULE_NAME)) {
        store.unregisterModule(DISCIPLINA_MODULE_NAME);
      }
    });

    const formDatas = ref(
      JSON.parse(
        JSON.stringify({
          name: "",
          description: "",
          course_id:null
        })
      )
    );
    const loading = ref(false);
    let id_subject = ref();
    const route = useRoute();
    const course = ref([]);

    onMounted(()=>{
      FilterCourse()
    })
    async function FilterCourse() {
      await store.dispatch('Disciplina/getAllCourrse').then((response) => {
        course.value = response.data
        console.log(response);
      })
    }

    async function viewSubject() {
      id_subject.value = route.params.id_subject;

      const resp = await SubjectStoreModule.actions.viewSubject(id_subject.value);
      formDatas.value = resp.data.data;
      formDatas.value.course_id = resp.data.data.course[0].id

      console.log(resp.data.data.course[0].id)
 
      // store
      //   .dispatch("Disciplina/viewSubject", {
      //     id: this.$route.params.id_subject,
      //   })
      //   .then((response) => {
      //     console.log(response);
      //     formDatas.value = response.data.data;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     toastr.error("Erro! Erro na aquisicao da dados");
      //   });
    }

    async function sendData() {
      loading.value = true;

      if (formDatas.value.name == "") {
        toastr.warning("Por favor preencha os campos obrigatorios");
        loading.value = false;

        return;
      }

      const resp = await SubjectStoreModule.actions.updateSubject({'id':route.params.id_subject, "data":formDatas.value});
      console.log(resp.data.message);
      if (resp.data.status == true) {
        toastr.success(resp.data.message);
      }else{
        toastr.error(resp.data.message);

      }
      loading.value = false;

      // store
      //   .dispatch("Disciplina/updateSubject", {
      //     id: id_subject.value,
      //     data: formDatas.value,
      //   })
      //   .then((response) => {
      //     console.log(response);
      //     toastr.success(response.data[0]);
      //     loading.value = false;
      //   })
      //   .catch((error) => {
      //     loading.value = false;

      //     console.log(error);
      //     toastr.error("Erro! Erro na requisicao de dados");
      //   });
    }

    const backHistory = () => {
      history.back();
    };
    return {
      formDatas,
      sendData,
      loading,
      viewSubject,
      id_subject,
      backHistory,
      route,
      course,
      FilterCourse
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },

  created() {
    this.viewSubject();
  },
};
</script>
