import router from "@/router";
import axios from "axios";
// import router from "@/router";
export default {
  namespaced: true,
  state: {
    student: {},

  },
  getters: {
  
    student(state) {
      return state.student;
    },
  },
  mutations: {
 
    SET_STUDENT(state, value) {
      state.student = value;
    },
  },
  actions: {
    student({ commit }, data) {
    //    axios
    //     .get("/api/student/"+data, {
    //       headers: {
    //         Authorization: "Bearer " + data.access_token,
    //       },
    //     })
    //     .then(({ data }) => {
          commit("SET_STUDENT", data);
         
        // })
        // .catch((error) => {
        //     console.log(error);
        //   });
    },

 
  },
};
