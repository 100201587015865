<template>
  <div class="wrapper">
    <MyNavbarVue />
    <MySideBarTeacher />
    <div class="content-page">
      <div class="content">
        <RouterView></RouterView>
      </div>
    </div>
  </div>
</template>

<script>
import MyNavbarVue from "@/layouts/MyNavbar.vue";
import MySideBarTeacher from "@/layouts/MySideBarTeacher.vue";
import TeacherDashStoreModule from "./TeacherDashStoreModule";

export default {
  components: {
    MyNavbarVue,
    MySideBarTeacher,
  },

  methods: {
    async getFormulaByTeacher() {
      try {
        const resp = await TeacherDashStoreModule.actions.getFormulaByClass();
        console.log(resp);
      } catch (error) {
        console.log();
      }
    },
  },

  created() {
    this.getFormulaByTeacher();
  },
};
</script>