<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Gerenciar Turmas</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Turmas</li>
          <li class="breadcrumb-item active">Lista Turmas</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="mb-2 col text-center text-sm-left align-items-center">
              <a
                href="javascript:void(0)"
                class="btn mr-1 btn-primary text-center text-sm-left text-white"
                @click="openModalAddNewClassmate"
              >
                <span class="fas fa-plus-circle"></span> Adicionar</a
              >
            </div>

            <div class="mb-2 col-12 col-sm col-lg-4 align-items-center">
              <form class=" ">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Pesquisar..."
                    v-model="searchQuery"
                  />
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary">
                      <i class="fa fa-search"></i>
                    </button>

                    <button type="button" class="btn ml-1 bg-primary">
                      <i class="fa fa-search-plus text-white"></i>
                      <span class="text-white">Busca avançada </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table id="example5" class="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Curso</th>
                  <th>Nivel</th>
                  <th>Regime</th>
                  <th>Capacidade</th>
                  <th>Ano de Entrada</th>
                  <th class="w-140px text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(cl, index) in classMates" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ cl.name }}</td>
                  <td>{{ cl.course_name }}</td>
                  <td>{{ cl.level_name }}</td>
                  <td>{{ cl.scholar_shift }}</td>
                  <td>{{ cl.capacity }}</td>
                  <td>{{ cl.entry_year }}</td>

                  <td aria-colindex="3" role="cell" class="w-140px text-center">
                    <div class="action-column">
                      <a
                        @click="viewClassmate(index, cl.id)"
                        class="btn btn-sm mr-1 btn-info btn-xs"
                        style="font-size: 13px"
                        ><i class="fa fa-eye text-white"></i>
                      </a>
                      <a
                        @click="editClassmate(index, cl.id)"
                        class="btn btn-sm mr-1 btn-warning btn-xs"
                        style="font-size: 13px"
                        ><i class="fa fa-edit text-white"></i>
                      </a>
                      <a
                        @click="confirmBeforeDelete(index, cl.id)"
                        class="btn btn-sm mr-1 btn-danger btn-xs"
                        style="font-size: 13px"
                        ><i class="fa fa-times text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>

                <tr v-if="classMates.length == 0">
                  <td colspan="7" class="text-center">
                    <span class="text-center" style="font-style: italic"
                      >Ainda nao tem registos</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <Page
              :total="total"
              :model-value="current_page"
              :page-size="per_page"
              @on-change="getAllClassMates"
              v-if="total > 0"
              class="float-right"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalAddNewDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Nova Turma</h5>
        <button
          type="button"
          @click="closeModalDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendData">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="form-label">Nome</label>
                <input
                  placeholder="Nome da Turma"
                  type="text"
                  class="form-control"
                  v-model="formData.name"
                  @input="onUserTypeClassmateName"
                />
              </div>
              <p v-if="classmateName_message_error" style="color: red">
                {{ classmateName_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="form-label">Capacidade</label>
                <input
                  placeholder="Capacidade da Turma"
                  type="text"
                  class="form-control"
                  v-model="formData.capacity"
                  @input="onUserTypeCapacity"
                  @keypress="numberOnly"
                  pattern="/^[0-9]+$/"
                  maxlength="2"
                />
              </div>
              <p v-if="capacity_message_error" style="color: red">
                {{ capacity_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="form-label">Departamentos</label>

                <Select
                  @on-select="onUserChangeDepart"
                  v-model="formData.department_id"
                  placeholder="Buscar departamento"
                  filterable
                  not-found-text="Departamento nao encontrado"
                >
                  <Option
                    :value="dept.id"
                    v-for="dept in departmentData"
                    :key="dept.id"
                  >
                    {{ dept.dep_name }}
                  </Option>
                </Select>
              </div>
              <p v-if="dept_message_error" style="color: red">
                {{ dept_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecione o Curso</label>
                <Select
                  v-model="formData.course_id"
                  @on-select="onUsertypeCourse"
                  placeholder="Buscar Curso"
                  filterable
                  not-found-text="Curso nao encontrado"
                >
                  <Option :value="c.id" v-for="c in courses" :key="c.id">
                    {{ c.name }}
                  </Option>
                </Select>
              </div>
              <p v-if="course_message_error" style="color: red">
                {{ course_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nivel Academico*</label>
                <Select
                  v-model="formData.level_id"
                  @on-select="onUsertypeLevel"
                  placeholder="Buscar Nivel"
                  filterable
                  not-found-text="Nivel nao encontrado"
                >
                  <Option value="">Selecione o nivel</Option>
                  <Option
                    :value="l.id"
                    v-for="(l, index) in levels"
                    :key="index"
                  >
                    {{ l.name }}
                  </Option>
                </Select>
              </div>
              <p v-if="level_message_error" style="color: red">
                {{ level_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Ano Academico*</label>
                <Select
                  v-model="formData.academic_year_id"
                  @on-select="onUsertypeAcademicYear"
                  placeholder="Buscar Ano Academico"
                  filterable
                  not-found-text="Ano Academico nao encontrado"
                >
                  <Option value="">Selecione o ano academico</Option>
                  <Option
                    :value="a.id"
                    v-for="(a, index) in academic_year"
                    :key="index"
                  >
                    {{ a.designation }}&#176;
                  </Option>
                </Select>
              </div>
              <p v-if="academic_message_error" style="color: red">
                {{ academic_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Ano de Entrada*</label>
                <Select
                  v-model="formData.entry_year"
                  @on-select="onUsertypeEntryYear"
                  placeholder="Buscar Ano de Entrada"
                  filterable
                  not-found-text="Ano de Entrada nao encontrado"
                >
                  <Option value="">Ano de Entrada*</Option>
                  <Option :value="y" v-for="y in years" :key="y">
                    {{ y }}
                  </Option>
                </Select>
              </div>
              <p v-if="entryYear_message_error" style="color: red">
                {{ entryYear_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Regime*</label>
                <Select
                  v-model="formData.scholar_shift"
                  @on-select="onUsertypeRegime"
                  placeholder="Buscar regime"
                  filterable
                  not-found-text="regime nao encontrado"
                >
                  <Option value="LABORAL">LABORAL</Option>
                  <Option value="POS-LABORAL">POS-LABORAL</Option>
                </Select>
              </div>
              <p v-if="regime_message_error" style="color: red">
                {{ regime_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar semestre</label>
                <Select
                  v-model="formData.semester_id"
                  @on-select="onUsertypeSemester"
                  placeholder="Buscar semestre"
                  filterable
                  not-found-text="semestre nao encontrado"
                >
                  <Option v-for="s in semesters" :key="s.id" :value="s.id">
                    {{ s.semester_name }}&#176;
                  </Option>
                </Select>
              </div>
              <p v-if="semester_message_error" style="color: red">
                {{ semester_message_error }}
              </p>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Descrição da Turma</label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="formData.description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <Button
          type="success"
          @click="addNewClassmate"
          data-dismiss="modal"
          :loading="loadingServerStundent"
        >
          Adicionar
        </Button>

        <Button
          type="error"
          @click="closeModalDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalAddUpdateDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Actualizar dados da turma</h5>
        <button
          type="button"
          @click="closeModalUpdateDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendData">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="form-label">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.name"
                  @input="onUserTypeClassmateName"
                />
              </div>
              <p v-if="classmateName_message_error" style="color: red">
                {{ classmateName_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="form-label">Capacidade</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.capacity"
                  @input="onUserTypeCapacity"
                  @keypress="numberOnly"
                  pattern="/^[0-9]+$/"
                  maxlength="2"
                />
              </div>
              <p v-if="capacity_message_error" style="color: red">
                {{ capacity_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="form-label">Departamentos</label>
                <Select
                  @on-select="onUserChangeDepart"
                  v-model="formData.department_id"
                  placeholder="Buscar departamento"
                  filterable
                  not-found-text="Departamento nao encontrado"
                >
                  <Option
                    :value="dept.id"
                    v-for="dept in departmentData"
                    :key="dept.id"
                  >
                    {{ dept.dep_name }}
                  </Option>
                </Select>
              </div>
              <p v-if="dept_message_error" style="color: red">
                {{ dept_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecione o Curso</label>
                <Select
                  @on-select="onUsertypeCourse"
                  placeholder="Buscar Curso"
                  filterable
                  not-found-text="Curso nao encontrado"
                  v-model="formData.course_id"
                >
                  <Option :value="c.id" v-for="c in courses" :key="c.id">
                    {{ c.name }}
                  </Option>
                </Select>
              </div>
              <p v-if="course_message_error" style="color: red">
                {{ course_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nivel Academico*</label>
                <Select
                  @on-select="onUsertypeLevel"
                  placeholder="Buscar Nivel"
                  filterable
                  not-found-text="Nivel nao encontrado"
                  v-model="formData.level_id"
                >
                  <Option value="">Selecione o nivel</Option>
                  <Option
                    :value="l.id"
                    v-for="(l, index) in levels"
                    :key="index"
                  >
                    {{ l.name }}
                  </Option>
                </Select>
              </div>
              <p v-if="level_message_error" style="color: red">
                {{ level_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Ano Academico*</label>
                <Select
                  @on-select="onUsertypeAcademicYear"
                  placeholder="Buscar Ano Academico"
                  filterable
                  not-found-text="Ano Academico nao encontrado"
                  v-model="formData.academic_year_id"
                >
                  <Option value="">Selecione o ano academico</Option>
                  <Option
                    :value="a.id"
                    v-for="(a, index) in academic_year"
                    :key="index"
                  >
                    {{ a.designation }}&#176;
                  </Option>
                </Select>
              </div>
              <p v-if="academic_message_error" style="color: red">
                {{ academic_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Ano de Entrada*</label>
                <Select
                  v-model="formData.entry_year"
                  @on-select="onUsertypeEntryYear"
                  placeholder="Buscar Ano de Entrada"
                  filterable
                  not-found-text="Ano de Entrada nao encontrado"
                >
                  <Option
                    v-for="(yyyy, index) in years"
                    :value="yyyy"
                    :key="index"
                    >{{ yyyy }}</Option
                  >
                </Select>
              </div>
              <p v-if="entryYear_message_error" style="color: red">
                {{ entryYear_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Regime*</label>
                <Select
                  @on-select="onUsertypeRegime"
                  placeholder="Buscar regime"
                  filterable
                  not-found-text="regime nao encontrado"
                  v-model="formData.scholar_shift"
                >
                  <Option value="LABORAL">LABORAL</Option>
                  <Option value="POS-LABORAL">POS-LABORAL</Option>
                </Select>
              </div>
              <p v-if="regime_message_error" style="color: red">
                {{ regime_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar semestre</label>
                <Select
                  @on-select="onUsertypeSemester"
                  placeholder="Buscar semestre"
                  filterable
                  not-found-text="semestre nao encontrado"
                  v-model="formData.semester_id"
                >
                  <Option v-for="s in semesters" :key="s.id" :value="s.id">
                    {{ s.semester_name }}&#176;
                  </Option>
                </Select>
              </div>
              <p v-if="semester_message_error" style="color: red">
                {{ semester_message_error }}
              </p>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Descrição da Turma</label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="formData.description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <Button
          type="success"
          @click="updateClassmateData"
          data-dismiss="modal"
          :loading="loadingServerStundent"
        >
          Actualizar
        </Button>

        <Button
          type="error"
          @click="closeModalUpdateDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalViewDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Visualizar detalhes da Turma</h5>
        <button
          type="button"
          @click="closeModalViewteDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendData">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="form-label">Nome</label>
                <input
                  type="text"
                  disabled
                  class="form-control"
                  v-model="formData.name"
                />
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="form-label">Capacidade</label>
                <input
                  disabled
                  type="text"
                  class="form-control"
                  v-model="formData.capacity"
                />
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="form-group">
                <label class="form-label">Departamentos</label>
                <select
                  disabled
                  class="form-control"
                  v-model="formData.department_id"
                >
                  <option
                    :value="dept.id"
                    v-for="dept in departmentData"
                    :key="dept.id"
                  >
                    {{ dept.dep_name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecione o Curso</label>
                <select
                  disabled
                  class="form-control"
                  v-model="formData.course_id"
                >
                  <option :value="c.id" v-for="c in courses" :key="c.id">
                    {{ c.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nivel Academico*</label>
                <select
                  disabled
                  class="form-control"
                  v-model="formData.level_id"
                >
                  <option value="">Selecione o nivel</option>
                  <option
                    :value="l.id"
                    v-for="(l, index) in levels"
                    :key="index"
                  >
                    {{ l.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-4 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Ano Academico*</label>
                <select
                  disabled
                  class="form-control"
                  v-model="formData.academic_year_id"
                >
                  <option value="">Selecione o ano academico</option>
                  <option
                    :value="a.id"
                    v-for="(a, index) in academic_year"
                    :key="index"
                  >
                    {{ a.designation }}&#176;
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Ano de Entrada*</label>
                <select
                  disabled
                  class="form-control"
                  v-model="formData.entry_year"
                >
                  <option value="">Ano de Frequencia*</option>
                  <option :value="y" v-for="y in years" :key="y">
                    {{ y }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Regime*</label>
                <select
                  disabled
                  class="form-control"
                  v-model="formData.scholar_shift"
                >
                  <option value="LABORAL">LABORAL</option>
                  <option value="POS-LABORAL">POS-LABORAL</option>
                </select>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Selecionar semestre</label>
                <select
                  v-model="formData.semester_id"
                  class="form-control custom-select"
                  disabled
                >
                  <option v-for="s in semesters" :key="s.id" :value="s.id">
                    {{ s.semester_name }}&#176;
                  </option>
                </select>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Descrição da Turma</label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="formData.description"
                  disabled
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <Button
          type="error"
          @click="closeModalViewteDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Fechar
        </Button>
      </div>
    </div>
  </div>
  <Loading :loadingData="loadingServerStundent"></Loading>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import store from "@/store";
import ClassMateStoreModule from "./ClassMateStoreModule";
import SubjectStoreModule from "../subject/SubjectStoreModule";
import Loading from "../../components/Loading.vue";
import Swal from "sweetalert2";
import DepartmentStoreModule from "../department/DepartmentStoreModule";
import StudentModuleService from "../students/StudentModuleService";
import CourseAndSubjectModule from "../CourseAndSubject/CourseAndSubjectModule";
import toastr from "toastr";
import { numberOnly } from "../../modules/customers.js";

const formData = ref({
  name: "",
  description: "",
  department_id: null,
  capacity: null,
  level_id: null,
  entry_year: "",
  course_id: null,
  academic_year_id: null,
  scholar_shift: null,
  semester_id: null,
  classmate_id: null,
});
const loadingServerStundent = ref(false);

const classMates = ref([]);
const searchQuery = ref("");
const isModalAddNewDept = ref(false);
const isModalAddUpdateDept = ref(false);
const isModalViewDept = ref(false);
const total = ref(null);
const perPage = ref(10);
const current_page = ref();
const departmentData = ref([]);
const levels = ref([]);
const courses = ref([]);
const academic_year = ref([]);
const semesters = ref([]);

const classmateName_message_error = ref("");
const capacity_message_error = ref("");
const dept_message_error = ref("");
const course_message_error = ref("");
const level_message_error = ref("");
const entryYear_message_error = ref("");
const semester_message_error = ref("");
const academic_message_error = ref("");
const regime_message_error = ref("");

function viewClassmate(index, id) {
  isModalViewDept.value = true;

  formData.value.name = classMates.value[index].name;
  formData.value.description = classMates.value[index].description;
  formData.value.department_id = classMates.value[index].department_id;
  formData.value.capacity = classMates.value[index].capacity;
  formData.value.level_id = classMates.value[index].level_id;
  formData.value.entry_year = parseInt(classMates.value[index].entry_year);
  formData.value.course_id = classMates.value[index].course_id;
  formData.value.academic_year_id = classMates.value[index].academic_year_id;
  formData.value.scholar_shift = classMates.value[index].scholar_shift;
  formData.value.semester_id = classMates.value[index].semester_id;
}
function closeModalViewteDept() {
  isModalViewDept.value = false;
  clearInput();
}

function closeModalUpdateDept() {
  isModalAddUpdateDept.value = false;
  clearInput();
}

function closeModalDept() {
  isModalAddNewDept.value = false;
  clearInput();
}
function editClassmate(index, id) {
  isModalAddUpdateDept.value = true;

  let findClassmate = classMates.value.find((item) => item.id === id);

  console.log(findClassmate);

  formData.value.name = findClassmate.name;
  formData.value.description = findClassmate.description;
  formData.value.department_id = findClassmate.department_id;
  formData.value.capacity = findClassmate.capacity;
  formData.value.level_id = findClassmate.level_id;
  formData.value.entry_year = parseInt(findClassmate.entry_year);
  formData.value.course_id = parseInt(findClassmate.course_id);
  formData.value.academic_year_id = parseInt(findClassmate.academic_year_id);
  formData.value.scholar_shift = findClassmate.scholar_shift;
  formData.value.semester_id = findClassmate.semester_id;
  formData.value.classmate_id = id;
}
function clearInput() {
  formData.value.capacity = null;
  formData.value.classmate_id = null;
  formData.value.course_id = null;
  formData.value.department_id = null;
  formData.value.description = null;
  formData.value.name = null;
  formData.value.scholar_shift = null;
}
async function addNewClassmate() {
  try {
    loadingServerStundent.value = true;

    if (formData.value.name == "" || formData.value.name == null) {
      loadingServerStundent.value = false;

      classmateName_message_error.value =
        "O campo nome da turma nao pode esta vazio";
      return;
    }

    if (formData.value.capacity == "" || formData.value.capacity == null) {
      loadingServerStundent.value = false;

      capacity_message_error.value = "O campo capacidade nao pode esta vazio";
      return;
    }

    if (
      formData.value.department_id == "" ||
      formData.value.department_id == null
    ) {
      loadingServerStundent.value = false;

      dept_message_error.value = "O campo departmento nao pode esta vazio";
      return;
    }

    if (formData.value.course_id == "" || formData.value.course_id == null) {
      loadingServerStundent.value = false;

      course_message_error.value = "O campo curso nao pode esta vazio";
      return;
    }

    if (formData.value.level_id == "" || formData.value.level_id == null) {
      loadingServerStundent.value = false;

      level_message_error.value = "O campo nivel nao pode esta vazio";
      return;
    }

    if (
      formData.value.academic_year_id == "" ||
      formData.value.academic_year_id == null
    ) {
      loadingServerStundent.value = false;

      academic_message_error.value =
        "O campo ano academico nao pode esta vazio";
      return;
    }

    if (formData.value.entry_year == "" || formData.value.entry_year == null) {
      loadingServerStundent.value = false;

      entryYear_message_error.value =
        "O campo ano de criacao nao pode esta vazio";
      return;
    }

    if (formData.value.scholar_shift == "") {
      loadingServerStundent.value = false;

      regime_message_error.value = "O campo ano regime nao pode esta vazio";
      return;
    }

    if (
      formData.value.semester_id == "" ||
      formData.value.semester_id == null
    ) {
      loadingServerStundent.value = false;

      semester_message_error.value = "O campo semestre nao pode esta vazio";
      return;
    }

    const resp = await ClassMateStoreModule.actions.createTurma(formData.value);

    if (resp.data.status == true) {
      classMates.value.push(resp.data.data[0]);
      toastr.success(resp.data.message);
      isModalAddNewDept.value = false;
      clearInput();
    }
  } catch (error) {
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loadingServerStundent.value = false;
  }
}

async function updateClassmateData() {
  try {
    loadingServerStundent.value = true;

    if (formData.value.name == "" || formData.value.name == null) {
      loadingServerStundent.value = false;
      classmateName_message_error.value =
        "O campo nome da turma nao pode esta vazio";
      return;
    }

    if (formData.value.capacity == "" || formData.value.capacity == null) {
      loadingServerStundent.value = false;
      capacity_message_error.value = "O campo capacidade nao pode esta vazio";
      return;
    }

    if (
      formData.value.department_id == "" ||
      formData.value.department_id == null
    ) {
      loadingServerStundent.value = false;
      dept_message_error.value = "O campo departmento nao pode esta vazio";
      return;
    }

    if (formData.value.course_id == "" || formData.value.course_id == null) {
      loadingServerStundent.value = false;
      course_message_error.value = "O campo curso nao pode esta vazio";
      return;
    }

    if (formData.value.level_id == "" || formData.value.level_id == null) {
      loadingServerStundent.value = false;
      level_message_error.value = "O campo nivel nao pode esta vazio";
      return;
    }

    if (
      formData.value.academic_year_id == "" ||
      formData.value.academic_year_id == null
    ) {
      loadingServerStundent.value = false;
      academic_message_error.value =
        "O campo ano academico nao pode esta vazio";
      return;
    }

    if (formData.value.entry_year == "" || formData.value.entry_year == null) {
      loadingServerStundent.value = false;
      entryYear_message_error.value =
        "O campo ano de criacao nao pode esta vazio";
      return;
    }

    if (formData.value.scholar_shift == "") {
      loadingServerStundent.value = false;

      regime_message_error.value = "O campo ano regime nao pode esta vazio";
      return;
    }

    if (
      formData.value.semester_id == "" ||
      formData.value.semester_id == null
    ) {
      loadingServerStundent.value = false;
      semester_message_error.value = "O campo semestre nao pode esta vazio";
      return;
    }

    // Buscar o index actualizado
    let findLevel = levels.value.find(
      (item) => item.id == formData.value.level_id
    );
    let course_name = courses.value.find(
      (item) => item.id == formData.value.course_id
    );
    let classMateIndex = classMates.value.findIndex(
      (item) => item.id == formData.value.classmate_id
    );

    const resp = await ClassMateStoreModule.actions.updateTurma(formData.value);

    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      classMates.value[classMateIndex].description = formData.value.description;
      classMates.value[classMateIndex].department_id =
        formData.value.department_id;
      classMates.value[classMateIndex].capacity = formData.value.capacity;
      classMates.value[classMateIndex].level_name = findLevel.name;
      classMates.value[classMateIndex].level_id = formData.value.level_id;
      classMates.value[classMateIndex].entry_year = formData.value.entry_year;
      classMates.value[classMateIndex].course_id = formData.value.course_id;
      classMates.value[classMateIndex].course_name = course_name.name;
      classMates.value[classMateIndex].academic_year_id =
        formData.value.academic_year_id;
      classMates.value[classMateIndex].scholar_shift =
        formData.value.scholar_shift;
      classMates.value[classMateIndex].semester_id = formData.value.semester_id;
      classMates.value[classMateIndex].name = formData.value.name;
      isModalAddUpdateDept.value = false;
    } else {
      toastr.error(resp.data.message);
    }
  } catch (error) {
    console.log(error);
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loadingServerStundent.value = false;
  }
}

async function getCourses() {
  try {
    loadingServerStundent.value = true;
    const resp = await CourseAndSubjectModule.actions.getCourses();
    courses.value = resp.data;
    console.log(resp.data);
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

async function getSemester() {
  try {
    loadingServerStundent.value = true;

    const resp = await SubjectStoreModule.actions.getSemester();

    semesters.value = resp.data;
    console.log("semetres", resp.data);
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

async function getAllClassMates(page = 1) {
  try {
    loadingServerStundent.value = true;

    const response = await ClassMateStoreModule.actions.getAllTurmas({
      search: searchQuery.value,
      page: page,
    });

    console.log(response.data);
    classMates.value = response.data.data;
    total.value = response.data.total;
    perPage.value = response.data.per_page;
    current_page.value = response.data.current_page;
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

watch(searchQuery, (newValue, oldValue) => {
  if (newValue.length >= 4 || oldValue.length >= 4) {
    getAllClassMates();
  } else {
    if (newValue.length == 0 || oldValue.length == 0) {
      getAllClassMates();
    }
  }
});

async function deleteTurma(index, id) {
  try {
    loadingServerStundent.value = true;
    classMates.value.splice(index, 1);

    const response = await ClassMateStoreModule.actions.deleteTurma(id);
    if (response.data > 0) {
      Swal.fire({
        title: "Eliminado!",
        text: "Registro Eliminado com Sucesso.",
        icon: "success",
      });
    }

    console.log(response.data);
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}
function openModalAddNewClassmate() {
  isModalAddNewDept.value = true;
}

function confirmBeforeDelete(index, id) {
  Swal.fire({
    title: "Tem certeza que deseja eliminar este registro?",
    text: "Nao tera como reverter esta operacao!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim, Eliminar!",
    cancelButtonText: "Cancel!",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteTurma(index, id);
    }
  });
}

async function getDepartment() {
  try {
    loadingServerStundent.value = true;

    const resp = await ClassMateStoreModule.actions.getDepartment();
    departmentData.value = resp.data.data;
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}
async function getLevels() {
  try {
    loadingServerStundent.value = true;

    const resp = await StudentModuleService.actions.getLevels();
    levels.value = resp.data;
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}
const years = ref([]);

function getYearTilCurrent() {
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= 1900; year--) {
    years.value.push(year);
  }
}

async function getAcademicYear() {
  try {
    loadingServerStundent.value = true;

    const resp = await ClassMateStoreModule.actions.getAcademicYear();
    academic_year.value = resp.data.data;
    console.log(resp);
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

function onUserTypeClassmateName() {
  if (formData.value.name !== "") {
    classmateName_message_error.value = "";
  }
}

function onUserTypeCapacity() {
  if (formData.value.capacity !== "") {
    capacity_message_error.value = "";
  }
}

function onUserChangeDepart(event) {
  console.log(event);
  formData.value.department_id = event.value;
  if (event.label !== "" && formData.value.department_id !== "") {
    dept_message_error.value = "";
  }
}

function onUsertypeCourse(event) {
  formData.value.course_id = event.value;
  if (event.label !== "" && formData.value.course_id !== "") {
    course_message_error.value = "";
  }
}

function onUsertypeLevel(event) {
  formData.value.level_id = event.value;
  if (event.label !== "" && formData.value.level_id !== "") {
    level_message_error.value = "";
  }
}

function onUsertypeAcademicYear(event) {
  formData.value.academic_year_id = event.value;
  if (event.label !== "" && formData.value.academic_year_id !== "") {
    academic_message_error.value = "";
  }
}

function onUsertypeEntryYear(event) {
  formData.value.entry_year = event.value;
  if (event.label !== "" && formData.value.entry_year !== "") {
    entryYear_message_error.value = "";
  }
}

function onUsertypeRegime(event) {
  formData.value.scholar_shift = event.value;
  if (event.label !== "" && formData.value.scholar_shift !== "") {
    regime_message_error.value = "";
  }
}

function onUsertypeSemester(event) {
  formData.value.semester_id = event.value;
  if (event.label !== "" && formData.value.semester_id !== "") {
    semester_message_error.value = "";
  }
}

onMounted(() => {
  getAllClassMates();
  getDepartment();
  getSemester();
  getYearTilCurrent();
  getLevels();
  getCourses();
  getAcademicYear();
});
</script>
