<template>
    <div class="page-title-box">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h4 class="page-title">Dashboard</h4>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-right">
            <li class="breadcrumb-item"><a href="#">Jassa</a></li>
            <li class="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
      <!-- end row -->
    </div>
  
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Detalhes das Disciplinas</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="sendData">
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="form-label">Selecione o Curso</label>
                    <select 
                      class="form-control"
                      v-model="formData.id_course"
                    >

                    <option :value="c.id" v-for=" c in courses" :key="c.id">{{c.name}}</option>
                    </select>
                    
                  </div>
                </div>
               
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label class="form-label">Selecione a Disciplinas</label>
                    <select 
                    class="form-control"
                    v-model="formData.id_subject"
                  >
                  <option :value="s.id" v-for="s in Subject" :key="s.id">{{s.name}}</option>
                   </select>
                  </div>
                </div>
  
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <button type="submit" class="btn btn-primary">{{loading == false? "Adicionar" :"Adcionando..."}}</button>
                  <button type="submit" class="btn btn-light">Voltar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {ref, onUnmounted} from 'vue'
  import {mapGetters} from 'vuex'
  import CourseAndSubjectModule from './CourseAndSubjectModule';
  import store from '@/store';
  import toastr from 'toastr'
  
  export default {
  
      setup(){
          const DISCIPLINA_MODULE_NAME = "Disciplina_Curso";
  
          if(!store.hasModule(DISCIPLINA_MODULE_NAME)){
              store.registerModule(DISCIPLINA_MODULE_NAME, CourseAndSubjectModule);
          }
  
  
          onUnmounted(()=>{
              if(store.hasModule(DISCIPLINA_MODULE_NAME)){
                  store.unregisterModule(DISCIPLINA_MODULE_NAME)
              }
          });
  
          const formData = {
              id_course:"",
              id_subject:""
          }
          const courses = ref([]);
          const Subject = ref([]);

          const loading = ref(false)
  
  
          function getCourses(){

            store.dispatch("Disciplina_Curso/getCourses")
                  .then((response)=>{
                      console.log(response)
                       loading.value = false
                      courses.value = response.data
                      clearFields();
                  })
                  .catch((error)=>{
                    loading.value = false
  
                      console.log(error)
                      toastr.error("Erro! Erro na requisicao de dados")
                  })
          }

          function getSubject(){
            store.dispatch("Disciplina_Curso/getSubjects")
                  .then((response)=>{
                      console.log(response)
                       loading.value = false
                      Subject.value = response.data
                      clearFields();
                  })
                  .catch((error)=>{
                    loading.value = false
  
                      console.log(error)
                      toastr.error("Erro! Erro na requisicao de dados")
                  })

          }
          function sendData(){
  
              loading.value = true;
  
              if(formData.id_course == "" || formData.id_subject == ""){
  
                  toastr.warning("Por favor preencha os campos obrigatorios");
                  loading.value = false;
  
                  return;
              }
  
              store
                  .dispatch("Disciplina/createCourseDisciplina", formData)
                  .then((response)=>{
                      console.log(response)
                      toastr.success(response.data.success)
                      loading.value = false
                      clearFields();
                  })
                  .catch((error)=>{
                    loading.value = false
  
                      console.log(error)
                      toastr.error("Erro! Erro na requisicao de dados")
                  })
          }
  
          function  clearFields(){
  
            
              formData.name = "";
              formData.description = ""
          }
  
          return {
              formData,
              sendData,
              loading,
              clearFields,
              getCourses,
              courses,
              getSubject,
              Subject
          }
  
      },
      computed:{
          ...mapGetters(['token'])
      },
      created(){
        this.getCourses();
        this.getSubject();
      }
  };
  </script>
  