<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Jassa</a></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Detalhes das Funçoes</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="addRoles">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nome da Funçao</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.name"
                  />
                </div>
              </div> 
             

              <div class="col-lg-12 col-md-12 col-sm-12">
                <button type="submit" class="btn btn-primary float-right">
                  {{ loading == false ? "Adicionar" : "Adicionando..." }}
                </button>
                <!-- <button type="submit" class="btn btn-light">Voltar</button> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toastr from 'toastr'
import { ref } from 'vue'
import RolesStoreModule from "./RolesStoreModule";

    export default {

        setup(){

            const formData = {
                name:""
            }
            const loading = ref(false)
            
            async function addRoles() {
              loading.value = true
              if(formData.name == ""){
              loading.value = false
                  
                toastr.warning("Por favor preencha o campo obrigatorio");
                return;
              }

              const resp = await RolesStoreModule.actions.createRoles(formData);

              if (resp.data.success==true) {
                toastr.success("Funcao adicionada com sucesso");
                formData.name = "";
                loading.value = false

              }else{
                toastr.info("Esta Funcao ja foi adicionada");
                loading.value = false


              }
              console.log(resp.data);
            }


            return{
                formData,
                loading,
                addRoles
            }
        }

    }
</script>
