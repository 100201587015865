<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Dashboard</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Incio</a></li>
          <li class="breadcrumb-item active">Detalhes das Turma</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Detalhes das Turma</h4>
        </div>
        <div class="card-body">
          <form @submit.prevent="sendData">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="form-label">Nome</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.name"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="form-label">Capacidade</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="formData.capacity"
                  />
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="form-group">
                  <label class="form-label">Departamentos</label>
                  <select class="form-control" v-model="formData.department_id">
                    <option
                      :value="dept.id"
                      v-for="dept in departmentData"
                      :key="dept.id"
                    >
                      {{ dept.dep_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione o Curso</label>
                  <select class="form-control" v-model="formData.course_id">
                    <option :value="c.id" v-for="c in courses" :key="c.id">
                      {{ c.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Nivel Academico*</label>
                  <select class="form-control" v-model="formData.level_id">
                    <option value="">Selecione o nivel</option>
                    <option
                      :value="l.id"
                      v-for="(l, index) in levels"
                      :key="index"
                    >
                      {{ l.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Ano Academico*</label>
                  <select
                    class="form-control"
                    v-model="formData.academic_year_id"
                  >
                    <option value="">Selecione o ano academico</option>
                    <option
                      :value="a.id"
                      v-for="(a, index) in academic_year"
                      :key="index"
                    >
                      {{ a.designation }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Ano de Entrada*</label>
                  <select class="form-control" v-model="formData.entry_year">
                    <option value="">Ano de Frequencia*</option>
                    <option :value="y" v-for="y in years" :key="y">
                      {{ y }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Turno*</label>
                  <select class="form-control" v-model="formData.period">
                    <option value="">Ano de Frequencia*</option>
                    <option value="Manhã">Manhã</option>
                    <option value="Tarde">Tarde</option>
                    <option value="Noturno">Noturno</option>
                  </select>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecionar semestre</label>
                  <select
                    v-model="formData.semester_id"
                    class="form-control custom-select"
                  >
                    <option v-for="s in semesters" :key="s.id" :value="s.id">
                      {{ s.semester_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Descrição da Turma</label>
                  <textarea
                    class="form-control"
                    rows="5"
                    v-model="formData.description"
                  ></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12">
                <button
                  v-if="loading == false"
                  type="submit"
                  class="btn btn-primary float-right"
                >
                  Adicionar
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-primary float-right"
                >
                  Adicionando...
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, onUnmounted, onMounted } from "vue";
import { mapGetters } from "vuex";
import ClassMateStoreModule from "./ClassMateStoreModule";
import store from "@/store";
import toastr from "toastr";
import StudentModuleService from "../students/StudentModuleService";
import SubjectStoreModule from "../subject/SubjectStoreModule";
import CourseAndSubjectModule from "../CourseAndSubject/CourseAndSubjectModule";
import axios from "axios";

export default {
  setup() {
    const TURMA_MODULE_NAME = "Turma";

    if (!store.hasModule(TURMA_MODULE_NAME)) {
      store.registerModule(TURMA_MODULE_NAME, ClassMateStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(TURMA_MODULE_NAME)) {
        store.unregisterModule(TURMA_MODULE_NAME);
      }
    });
    const formData = {
      name: "",
      description: "",
      department_id: "",
      capacity: "",
      level_id: "",
      entry_year: "",
      course_id: "",
      academic_year_id: "",
      period: "",
      semester_id: null,
    };
    const loading = ref(false);
    const departmentData = ref([]);
    const levels = ref([]);
    const courses = ref([]);
    const academic_year = ref([]);
    const semesters = ref([]);

    async function getCourses() {
      const resp = await CourseAndSubjectModule.actions.getCourses();
      courses.value = resp.data;
      console.log(resp.data);
    }

    async function getSemester() {
      const resp = await SubjectStoreModule.actions.getSemester();

      semesters.value = resp.data;
      console.log("semetres", resp.data);
    }

    async function sendData() {
      loading.value = true;

      try {
        if (
          formData.name == "" ||
          formData.description == "" ||
          formData.department_id == "" ||
          formData.capacity == ""
        ) {
          toastr.warning("Por favor preencha os campos obrigatorios");
          loading.value = false;

          return;
        }

        const response = await ClassMateStoreModule.actions.createTurma(
          formData
        );

        console.log(response);
        if (response) {
          toastr.success(response.data.success);
          loading.value = false;
          clearFields();
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    }

    onMounted(() => {
      getYearTilCurrent();
      getLevels();
      getCourses();
      getAcademicYear();
    });

    const years = ref([]);

    function getYearTilCurrent() {
      const currentYear = new Date().getFullYear();

      for (let year = currentYear; year >= 1900; year--) {
        years.value.push(year);
      }
    }

    function getDepartment() {
      store
        .dispatch("Turma/getDepartment")
        .then((response) => {
          console.log(response.data.data);
          departmentData.value = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getAcademicYear() {
      const resp = await ClassMateStoreModule.actions.getAcademicYear();
      academic_year.value = resp.data.data;
      console.log(resp);
    }

    function clearFields() {
      formData.name = "";
      formData.description = "";
      formData.capacity = "";
    }

    async function getLevels() {
      const resp = await StudentModuleService.actions.getLevels();
      levels.value = resp.data;
    }

    return {
      formData,
      sendData,
      loading,
      clearFields,
      getDepartment,
      departmentData,
      getYearTilCurrent,
      years,
      levels,
      getLevels,
      getCourses,
      courses,
      academic_year,
      getAcademicYear,
      getSemester,
      semesters,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    this.getDepartment();
    this.getSemester();
  },
};
</script>
  