import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { MainLayout } from "@/layouts";
import MyHome from "@/components/MyHome.vue";
import MyDepartment from "@/pages/department/MyDepartment.vue";
import AllDepartments from "@/pages/department/AllDepartments.vue";
import MyCourses from "@/pages/Courses/MyCourses.vue";
import AllCourses from "@/pages/Courses/AllCourses.vue";
import MyLogin from "@/pages/login/MyLogin.vue";
import MyInscriptions from "@/pages/inscriptions/MyInscriptions.vue";
import MyProfile from "@/pages/profile/MyProfile.vue";
import MySubject from "@/pages/subject/MySubject.vue";
import EditDepartment from "@/pages/department/EditDepartment.vue";
import EditCourse from "@/pages/Courses/EditCourse.vue";
import AllSubject from "@/pages/subject/AllSubject.vue";
import AllClassMates from "@/pages/Classmates/AllClassMates.vue";
import MyClassMates from "@/pages/Classmates/MyClassMates.vue";
import MyTeachers from "@/pages/teachers/MyTeachers.vue";
import AllTeachers from "@/pages/teachers/AllTeachers.vue";
import DashboardStudent from "@/pages/studentDashboard/DashboardStudent.vue";
import TeacherDashboard from "@/pages/TeacherDashboard/TeacherDashboard.vue";
import EditSubject from "@/pages/subject/EditSubject.vue";
import CourseAndSubject from "@/pages/CourseAndSubject/CourseAndSubject.vue";
import editClassMates from "@/pages/Classmates/editClassMates.vue";
import permissions from "@/pages/permissions/permissions.vue";
import MyRoles from "@/pages/Roles/MyRoles.vue";
import AllRoles from "@/pages/Roles/AllRoles.vue";
import EditTeacher from "@/pages/teachers/EditTeacher.vue";
import AlocateTeacher from "@/pages/teachers/AlocateTeacher.vue";
import MyProfileT from "@/pages/TeacherDashboard/MyProfile.vue";
import UpdatePassword from "@/pages/TeacherDashboard/UpdatePassword.vue";
import MyClassMatesTeacher from "@/pages/TeacherDashboard/MyClassMatesTeacher.vue";
import ClassMatesDetails from "@/pages/TeacherDashboard/ClassMatesDetails.vue";
import MySubjects from "@/pages/TeacherDashboard/MySubjects.vue";
import SubjetctDateils from "@/pages/TeacherDashboard/SubjetctDateils.vue";
import CreateLecture from "@/pages/TeacherDashboard/ClassManagements/CreateLecture.vue";
// import Employes from "@/pages/Employes/Emplo.vue";
import AlocateUser from "@/pages/Employes/AlocateUser.vue";
import CreateNewAssements from "@/pages/TeacherDashboard/AssessmentsManagments/CreateNewAssements.vue";
import ShowLectures from "@/pages/TeacherDashboard/ClassManagements/ShowLectures.vue";
import AllInscriptions from "@/pages/inscriptions/AllInscriptions.vue";
import InscriptionRender from "@/pages/inscriptions/InscriptionRender.vue";
import PayedInscription from "@/pages/inscriptions/PayedInscription.vue";
import PendentInscriptions from "@/pages/inscriptions/PendentInscriptions.vue";
import Candidate from "@/pages/inscriptions/Candidate.vue";
import ConcluededRenew from "@/pages/inscriptions/ConcluededRenew.vue";
import RenewPendents from "@/pages/inscriptions/RenewPendents.vue";
import MyProfileStudent from "@/pages/studentDashboard/MyProfileStudent.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: MyLogin,
    meta: {
      middleware: "guest",
      title: `Login`,
    },
  },

  {
    path: "/recover_password",
    name: "recover_password",
    component: () => import("@/pages/login/recoverPassword.vue"),
    meta: {
      middleware: "guest",
      title: `Recover`,
    },
  },

  {
    path: "/reset_password/:token?",
    name: "reset_password",
    component: () => import("../pages/login/reset_password.vue"),
    meta: {
      middleware: "guest",
      title: `New Password`,
    },
  },

  {
    path: "/confirm/:token?",
    name: "confirm",
    component: () => import("../pages/login/Confirmation.vue"),
    meta: {
      middleware: "guest",
      title: `Confirmation`,
    },
  },

  {
    path: "/inscription",
    name: "inscription",
    component: MyInscriptions,
    meta: {
      middleware: "guest",
      title: "Inscription",
    },
  },

  // {
  //   path:"/Home",
  //   name:"Home",
  //   component: DashboardStudent,
  //   meta:{
  //     title:"Student",
  //     requiresAuth:true,
  //     role:'Superadmin'
  //   }
  // },

  {
    path: "/Student",
    name: "Student",
    component: DashboardStudent,
    meta: {
      title: "Student",
      requiresAuth: true,
      role: "Student",
    },
    children: [
      {
        path: "/Student",
        component: MyProfileStudent,
      },
      {
        path: "/Student/assessmentResult",
        meta: {
          title: "Stundent Results",
        },
        component: () =>
          import(
            "../pages/studentDashboard/assessmentResult/assessmentResults.vue"
          ),
      },
      {
        path: "/assessmentResulExam",
        name: "Exam Results",
        component: () =>
          import(
            "@/pages/studentDashboard/assessmentResult/assessmentResultExame.vue"
          ),
      },
      {
        path: "/renew",
        name: "Renew",
        component: () => import("@/pages/studentDashboard/renew.vue"),
      },
    ],
  },

  {
    path: "/Teacher",
    name: "Teacher",
    component: TeacherDashboard,
    meta: {
      title: "Teacher",
      requiresAuth: true,
      role: "Teacher",
    },

    children: [
      {
        path: "",
        component: MyProfileT,
        meta: {
          title: "Teacher",
          requiresAuth: true,
          role: "Teacher",
        },
      },
      {
        path: "/myClassmates",
        name: "myClassmates",
        component: MyClassMatesTeacher,
        meta: {
          title: "My ClassMates",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "classmateDateils/:id_classmate",
        name: "classmateDateils",
        component: ClassMatesDetails,
        meta: {
          title: "Detalhes da Turma",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/mySubjects",
        name: "mySubjects",
        component: MySubjects,
        meta: {
          title: "Minhas Disciplinas",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/subjetctDateils/:id_subject",
        name: "subjetctDateils",
        component: SubjetctDateils,
        meta: {
          title: "Detalhes da Disciplinas",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/create-new-lecture",
        name: "create-new-lecture",
        component: CreateLecture,
        meta: {
          title: "Create Lecture",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/update-password",
        name: "password",
        component: UpdatePassword,
        meta: {
          title: "Update Password",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/AddAssessments/:subject_id/:classmate_id",
        name: "assessment_studant",
        component: CreateNewAssements,
        meta: {
          title: "Create Lecture",
          requiresAuth: true,
          role: "Teacher",
        },
      },

      {
        path: "/lectures",
        name: "lectures",
        component: ShowLectures,
        meta: {
          title: "List Lectures",
          requiresAuth: true,
          role: "Teacher",
        },
      },
    ],
  },

  {
    path: "/admin",
    component: MainLayout,
    meta: {
      title: "ISUPEC",
      middleware: "auth",
      requiresAuth: true,
      roles: ["Master", "Secretaria", "Admin"],
    },
    children: [
      {
        path: "/teacher",
        redirect: "/",
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: MyHome,
        requiresAuth: true,
      },
      {
        path: "/profile",
        component: MyProfile,
      },
      {
        path: "/departments",
        component: AllDepartments,
      },

      {
        path: "/courses",
        component: AllCourses,
      },

      {
        path: "/subject/",
        children: [
          {
            path: "",
            meta: {
              title: "Disciplinas",
            },
            component: AllSubject,
          },
          {
            path: "precedences",
            component: () => import("@/pages/subject/precedences.vue"),
          },
        ],
      },

      {
        path: "/services/",
        children: [
          {
            path: "",
            meta: {
              title: "Servicos",
            },
            component: () => import("@/pages/schoolServices/services.vue"),
          },

          {
            path: "references",
            meta: {
              title: "references",
            },
            component: () => import("@/pages/schoolServices/references.vue"),
          },
        ],
      },

      {
        path: "/employees",
        children: [
          {
            path: "",
            component: () => import("@/pages/Employes/Employes.vue"),
          },
        ],
      },

      {
        path: "/entities",
        component: () => import("@/pages/entities/mangeEntity.vue"),
        meta: {
          title: "Entities",
        },
      },

      {
        path: "/users/",
        children: [
          {
            path: "",
            component: () => import("@/pages/Users/Users.vue"),
          },
          {
            path: "groups",
            component: () => import("@/pages/Users/User_groups.vue"),
          },
          {
            path: "groups/permissions/:id",
            name: "permissions",
            component: () => import("@/pages/Users/Permissions.vue"),
          },
        ],
      },

      {
        path: "/all-subject",
        component: AllSubject,
      },
      {
        path: "edit-subject/:id_subject",
        name: "editSubject",
        component: EditSubject,
      },
      {
        path: "subject_course",
        name: "subject_course",
        component: CourseAndSubject,
      },
      {
        path: "/classmates",
        component: AllClassMates,
      },

      {
        path: "/teachers/",
        children: [
          {
            path: "",
            component: AllTeachers,
          },
          {
            path: "alocate/:id_teacher",
            name: "alocate",
            component: () => import("../pages/teachers/AlocateTeacher.vue"),
          },
        ],
      },
      {
        path: "/students",
        name: "novo-estudante",
        component: () => import("../pages/students/MyStudents.vue"),
      },
      {
        path: "/all-students",
        name: "estudantes",
        component: () => import("../pages/students/AllStudents.vue"),
      },
      {
        path: "/updateStudent/:id",
        name: "updateStudent",
        component: () => import("../pages/students/EditStudent.vue"),
      },

      {
        path: "/add-permissions",
        component: permissions,
      },

      {
        path: "/add-roles",
        component: MyRoles,
      },
      {
        path: "/all-roles",
        component: AllRoles,
      },

      {
        path: "/give-permissions",
        name: "give-permissions",
        component: () => import("@/pages/Employes/Emplo.vue"),
      },
      {
        path: "/AlocateUser/:user_id",
        name: "AlocateUser",
        component: AlocateUser,
      },
      {
        path: "/activities/",
        children: [
          {
            path: "",
            component: () => import("@/pages/Activities/ListActivities.vue"),
            meta: {
              title: "Activities",
            },
          },
          // {
          //   path:'add',
          //   component: () => import('@/pages/Activities/AddActivities.vue'),
          //   meta:{
          //     title:"Add Activities"
          //   }
          // }
        ],
      },
      {
        path: "/confirm_payment",
        component: () => import("@/pages/payments/confirm_payment.vue"),
      },

      {
        path: "/settings",
        component: () => import("@/pages/Settings/settings.vue"),
      },
      {
        path: "/all-inscriprions",
        name: "Inscriptions",
        component: InscriptionRender,
        // component:AllInscriptions,

        children: [
          {
            path: "",
            component: AllInscriptions,
          },
          {
            path: "/payedInscriptions",
            component: PayedInscription,
          },

          {
            path: "/pendentsInscriptions",
            component: PendentInscriptions,
          },
          {
            path: "/Candidate",
            component: Candidate,
          },
          {
            path: "/concludedRenew",
            component: ConcluededRenew,
          },
          {
            path: "/RenewPendents",
            component: RenewPendents,
          },
        ],
      },

      {
        path: "/payments/",
        children: [
          {
            path: "",
            component: () => import("@/pages/payments/payments.vue"),
            meta: {
              title: "Pagamentos",
            },
          },
          {
            path: "extract",
            component: () => import("@/pages/payments/extractByStudent.vue"),
            meta: {
              title: "Extractos",
            },
          },
          {
            path: "debt",
            component: () => import("@/pages/payments/DebtStudent.vue"),
            meta: {
              title: "Extractos",
            },
          },
          {
            path: "paymentInscriptions_renews",
            component: () =>
              import("@/pages/payments/paymentInscriptions_renews.vue"),
            meta: {
              title: "Extractos",
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  if (to.meta.requiresAuth && !isAuthenticated()) {
    // redirect user to the login page
    next("/login");
  } else if (to.meta.roles && !hasUserRole) {
    // reditrect user to unauthorized page
    next("/unauthorized");
  } else {
    //allow user to access user his role
    next();
  }
});

function isAuthenticated() {
  if (store.state.auth.authenticated) {
    return true;
  } else {
    return false;
  }
  // return true;
}

function hasUserRole(roles) {
  if (isAuthenticated()) {
    const role = roles.find(
      (item) => item == store.state.auth.user.data.role[0]
    );
    if (role) {
      return true;
    } else {
      return false;
    }
  }

  // console.log(store.state.auth.user.data.role[0]);
  // return true;
}
export default router;
