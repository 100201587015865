import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from '@/store/auth'
import student from '@/store/student'
import weightSubject from '@/store/weights.module';
import assessmentResults from '@/store/assessments';

const store = createStore({
    plugins:[
        createPersistedState()
    ],
    modules:{
        auth,
        student,
        weightSubject,
        assessmentResults
        
    }
})
export default store