<template>
  <div class="left side-menu">
    <div class="slimscroll-menu" id="remove-scroll">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu" id="side-menu">
          <li class="menu-title">Menu</li>

          <li>
            <router-link to="/Teacher" class="waves-effect"
              ><i class="fas fa-user-circle"></i><span> Perfil </span></router-link
            >
          </li> 
            <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-chalkboard-teacher	"></i>
              <span>
                Portal do Docente
                <span class="float-right menu-arrow">
                  <i class="mdi mdi-chevron-right"></i>
                </span>
              </span>
            </a>
            <ul class="submenu">
              <li>
                <router-link to="/myClassmates"><i class="mdi mdi-keyboard"></i> Lançamento de Avaliações</router-link>
              </li>
              <li>
                <router-link to="/AddAssessments"> <i class="mdi mdi-filter-variant "></i> Extrair Pautas Semestrais</router-link>
              </li>
               <li>
                <router-link to="/myClassmates"><i class='fas fa-file-pdf '></i> Pautas Oficiais</router-link>
              </li>
              <li>
                <router-link to="/lectures"><i class="mdi mdi-folder-open"></i> Material Académico</router-link>
              </li>
            </ul>
          </li>


          <!-- <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-building"></i>
              <span>
                Portal do Docente
                <span class="float-right menu-arrow">
                  <i class="mdi mdi-chevron-right"></i>
                </span>
              </span>
            </a>
            <ul class="submenu">
              <li>
                <router-link to="/myClassmates">Visualizar</router-link>
              </li>
              <li>
                <router-link to="/AddAssessments"
                  >Criar um avaliação </router-link
                >
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-list"></i>
              <span>
                Pautas
                <span class="float-right menu-arrow">
                  <i class="mdi mdi-chevron-right"></i>
                </span>
              </span>
            </a>
            <ul class="submenu">
              <li>
                <router-link to="/myClassmates">Visualizar</router-link>
              </li>
            </ul>
          </li> -->

          <!-- <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-book"></i>
              <span>
                Disciplinas
                <span class="float-right menu-arrow">
                  <i class="mdi mdi-chevron-right"></i>
                </span>
              </span>
            </a>
            <ul class="submenu">
              <li>
                <router-link to="/mySubjects">Visualizar</router-link>
              </li>
            </ul>
          </li> -->
          <!-- <li>
            <a href="javascript:void(0);" class="waves-effect"
              ><i class="fas fa-book"></i>
              <span>
                Aulas
                <span class="float-right menu-arrow">
                  <i class="mdi mdi-chevron-right"></i>
                </span>
              </span>
            </a>
            <ul class="submenu">
              <li>
                <router-link to="/mySubjects">Visualizar</router-link>
              </li>
                <li>
                <router-link to="/create-new-lecture">Adicionar</router-link>
              </li>
       
            </ul>
          </li> -->

 

         

          <li>
            <router-link to="/update-password" class="waves-effect"
              ><i class="fas fa-key"></i>
              <span>
                Alterar Senha
              </span>
            </router-link>
          </li>
        </ul>
      </div>
      <!-- Sidebar -->
      <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
  </div>
</template>

<script>
export default {
  // let user = store.state.user.auth.page
};
</script>
