<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Todos Departamentos</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Departamentos</li>
          <li class="breadcrumb-item active">Lista Departamentos</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="mb-2 col text-center text-sm-left align-items-center">
              <a
                href="javascript:void(0)"
                class="btn mr-1 btn-primary text-center text-sm-left text-white"
                @click="openModalDept"
              >
                <span class="fas fa-plus-circle"></span> Adicionar</a
              >
            </div>

            <div class="mb-2 col-12 col-sm col-lg-2 align-items-center">
              <form class=" ">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Pesquisar..."
                    v-model="search"
                  />
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Departamento</th>
                  <th>Descrição</th>
                  <th class="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dep, index) in department" :key="index">
                  <td>{{ 1 + index }}</td>
                  <td>{{ dep.dep_name }}</td>
                  <td v-if="dep.description == '' || dep.description == null">
                    -----------
                  </td>
                  <td v-else>{{ dep.description }}</td>

                  <td aria-colindex="3" role="cell" class="w-140px text-center">
                    <div class="action-column">
                      <a
                        @click="viewDept(index, dep.id)"
                        href="javascript:void(0)"
                        title="Visualizar Detalhes"
                        class="btn btn-sm mr-1 btn-info btn-sx"
                        style="font-size: 13px"
                        ><i class="fas fa-eye"></i
                      ></a>
                      <a
                        @click="editDept(index, dep.id)"
                        href="javascript:void(0)"
                        title="Editar"
                        class="btn btn-sm btn-warning mr-1"
                        style="font-size: 13px"
                        ><i class="fas fa-edit"></i
                      ></a>
                      <a
                        @click="confirmBeforeDelete(index, dep.id)"
                        title="Eliminar"
                        href="javascript:void(0)"
                        class="btn btn-danger btn-sm btn-xs"
                        style="font-size: 13px"
                        ><i class="fas fa-times"></i
                      ></a>
                    </div>
                  </td>
                </tr>
                <tr v-if="department.length == 0">
                  <td colspan="4" class="text-center">
                    <span> Ainda não tem registos</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <Page
              :total="total"
              :model-value="current_page"
              :page-size="parseInt(perPage)"
              @on-change="getAllDepartment"
              v-if="total"
              class="float-right"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalAddNewDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Novo Departamento</h5>
        <button
          type="button"
          @click="closeModalDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="row">
            <div class="col-lg-12 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nome do Departamento</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formDatas.dep_name"
                />
              </div>
              <p v-if="name_message_error" class="text-danger">
                {{ name_message_error }}
              </p>
            </div>
            <!-- <div class="col-lg-12 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Chefe do Departamento</label>
                <Select
                  v-model="formDatas.person_id"
                  style="border-radius: 16px"
                  placeholder="Buscar fucionario"
                  filterable
                  not-found-text="Fucionario nao encontrado"
                >
                  <Option
                    v-for="(p, index) in employees"
                    :value="p.id"
                    :key="index"
                  >
                    {{ p.fullName }}</Option
                  >
                </Select>
              </div>
              <p v-if="chefe_message_error" class="text-danger">
                {{ chefe_message_error }}
              </p>
            </div> -->

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Detalhes Departamento </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="formDatas.description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <Button
          @click="addNewDept"
          type="success"
          :loading="loadingServerStundent"
        >
          Adicionar
        </Button>

        <Button @click="closeModalDept" type="error"> Cancelar </Button>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalAddUpdateDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Actualizar dados do departamento</h5>
        <button
          type="button"
          @click="closeModalUpdateDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendDatas">
          <div class="row">
            <div class="col-lg-12 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nome do Departamento</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formDatas.dep_name"
                />
              </div>
              <p v-if="name_message_error" class="text-danger">
                {{ name_message_error }}
              </p>
            </div>
            <!-- <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Chefe do Departamento</label>
                <Select
                  v-model="formDatas.person_id"
                  style="border-radius: 16px"
                  placeholder="Buscar fucionario"
                  filterable
                  not-found-text="Fucionario nao encontrado"
                >
                  <Option
                    v-for="(p, index) in employees"
                    :value="p.id"
                    :key="index"
                  >
                    {{ p.fullName }}</Option
                  >
                </Select>
              </div>
              <p v-if="chefe_message_error" class="text-danger">
                {{ chefe_message_error }}
              </p>
            </div> -->

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Detalhes Departamento </label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="formDatas.description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <Button
          type="success"
          @click="UpdateDept"
          data-dismiss="modal"
          :loading="loadingServerStundent"
        >
          Actualizar
        </Button>

        <Button
          type="error"
          @click="closeModalUpdateDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalViewDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Visualizar detalhes do departamento</h5>
        <button
          type="button"
          @click="closeModalViewteDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="form-label">Nome do Departamento</label>
              <input
                type="text"
                class="form-control"
                v-model="formDatas.dep_name"
                disabled
              />
            </div>
          </div>
          <!-- <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="form-label">Chefe do Departamento</label>
              <Select
                v-model="formDatas.person_id"
                style="border-radius: 16px"
                placeholder="Buscar fucionario"
                filterable
                not-found-text="Fucionario nao encontrado"
                disabled
              >
                <Option
                  v-for="(p, index) in employees"
                  :value="p.id"
                  :key="index"
                >
                  {{ p.fullName }}</Option
                >
              </Select>
            </div>
          </div> -->

          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="form-label">Detalhes Departamento </label>
              <textarea
                class="form-control"
                rows="5"
                v-model="formDatas.description"
                disabled
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <Button
          type="error"
          @click="closeModalViewteDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>
  <Loading :loadingData="loadingServerStundent"></Loading>
</template>

<script setup>
import { ref, onUnmounted, watch, onMounted } from "vue";
import store from "@/store";
import DepartmentStoreModule from "./DepartmentStoreModule";
import toastr from "toastr";
import Loading from "../../components/Loading.vue";
import Swal from "sweetalert2";

const department = ref([]);
const total = ref(null);
const perPage = ref(10);
const current_page = ref();
const employees = ref(null);
const search = ref("");
const loadingServerStundent = ref(false);
const isModalAddNewDept = ref(false);
const isModalAddUpdateDept = ref(false);
const isModalViewDept = ref(false);

const name_message_error = ref("");
const chefe_message_error = ref("");

const formDatas = ref({
  dep_name: "",
  description: "",
});

function viewDept(index, dept_id) {
  isModalViewDept.value = true;

  formDatas.value.dep_name = department.value[index].dep_name;
  formDatas.value.description = department.value[index].description;
  // formDatas.value.person_id = department.value[index].person_id;
}

function closeModalViewteDept() {
  isModalViewDept.value = false;
  clearFields();
}

async function addNewDept() {
  try {
    loadingServerStundent.value = true;

    if (formDatas.value.dep_name == "") {
      loadingServerStundent.value = false;

      return (name_message_error.value =
        "O campo chefe do departmento nao estar vazio!");
    }

    var dept = department.value.find(
      (item) => item.dep_name == formDatas.value.dep_name
    );

    if (dept) {
      return toastr.info("Este departamento ja existe.");
    }

    const response = await DepartmentStoreModule.actions.createDepartamento(
      formDatas.value
    );

    if (response.data.success == true) {
      department.value.push(response.data.data);
      toastr.success(response.data.message);
      isModalAddNewDept.value = false;
    }

    clearFields();
  } catch (error) {
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

async function UpdateDept() {
  try {
    loadingServerStundent.value = true;

    if (formDatas.value.dep_name == "") {
      return (name_message_error.value =
        "O campo chefe do departmento nao estar vazio!");
    }

    if (formDatas.value.person_id == "") {
      return (chefe_message_error.value =
        "O campo chefe do departmento nao estar vazio!");
    }

    var dept = department.value.find(
      (item) =>
        item.dep_name == formDatas.value.dep_name &&
        item.id != formDatas.value.dept_id
    );

    if (dept) {
      return toastr.info("Este departamento ja existe.");
    }

    const response = await DepartmentStoreModule.actions.updateDepartment(
      formDatas.value
    );

    const index = department.value.findIndex(
      (item) => item.id == formDatas.value.dept_id
    );

    department.value[index].dep_name = formDatas.value.dep_name;
    department.value[index].description = formDatas.value.description;
    // department.value[index].person_id = formDatas.value.person_id;

    if (response.data.success == true) {
      toastr.success(response.data.message);
      isModalAddUpdateDept.value = false;
    }
  } catch (error) {
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

function editDept(index, dep_id) {
  isModalAddUpdateDept.value = true;

  formDatas.value.dept_id = dep_id;
  formDatas.value.dep_name = department.value[index].dep_name;
  formDatas.value.description = department.value[index].description;
  // formDatas.value.person_id = department.value[index].person_id;
}

function closeModalDept() {
  isModalAddNewDept.value = false;
  clearFields();
}

function closeModalUpdateDept() {
  isModalAddUpdateDept.value = false;
  clearFields();
}

function clearFields() {
  formDatas.value.dep_name = "";
  formDatas.value.description = "";
}

function openModalDept() {
  isModalAddNewDept.value = true;
}

async function getReponsavel() {
  try {
    loadingServerStundent.value = true;
    const resp = await DepartmentStoreModule.actions.getEmployee();
    employees.value = resp.data;
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

async function getAllDepartment(page = 1) {
  try {
    loadingServerStundent.value = true;

    const resp = await DepartmentStoreModule.actions.fetchDepartamentos({
      perPage: perPage.value,
      page: page,
      search: search.value,
    });
    department.value = resp.data.data;
    total.value = resp.data.total;
    perPage.value = resp.data.per_page;
    current_page.value = resp.data.current_page;

    console.log(resp.data.current_page);
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

function confirmBeforeDelete(index, dep_id) {
  Swal.fire({
    title: "Tem certeza que deseja eliminar este registro?",
    text: "Nao tera como reverter esta operacao!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim, Eliminar!",
    cancelButtonText: "Cancel!",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteDepartment(index, dep_id);
    }
  });
}

async function deleteDepartment(index, id) {
  try {
    loadingServerStundent.value = false;

    const response = await DepartmentStoreModule.actions.deleteDepartment(id);

    if (response.data == 1) {
      Swal.fire({
        title: "Eliminado!",
        text: "Registro Eliminado com Sucesso.",
        icon: "success",
      });
    }
    department.value.splice(index, 1);
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

watch(search, (newValue, oldValue) => {
  if (newValue.length >= 4 || oldValue.length >= 4) {
    getAllDepartment();
  } else if (newValue.length == 0 || oldValue.length == 0) {
    getAllDepartment();
  }
});

onMounted(() => {
  getAllDepartment();
  getReponsavel();
});
</script>
