<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Detalhes </h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Detalhes</li>
          <li class="breadcrumb-item active">Disciplinas</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
      <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6">
               <h5 class="page-title">Detalhes da Disciplina de {{subject_name}}</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
              <input
                type="text"
                class="form-control"
                v-model="search"
                placeholder="Pesquisar..."
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Titulo</th>
                  <th scope="col">Descrição</th>
                  <th scope="col">Avaliação</th>
                  <th scope="col">Ficheiro Anexo</th>
                  <th scope="col" colspan="2">Accção</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lect, index) in Datalectures" :key="lect.id">
                  <td>{{index + 1}}</td>
                  <td>{{lect.title}}</td>
                  <td>{{lect.description}}</td>
                  <td v-if="lect.is_assessment == 1"><span class="badge badge-success">Sim</span></td>
                  <td v-else><span class="badge badge-danger">Nao</span></td>
                  <td v-if="lect.attachmnts"><i class="mdi mdi-file-pdf-box text-danger font-18" ></i></td>
                  <td v-else>-----------------</td>
                  <td><a href="javascritp:void()">Remover</a></td>
                </tr>
              </tbody>
            </table>

            <!-- <Page :total="department.total" :model-value="department.current_page"  :page-size="parseInt(department.per_page)" @on-change="getAllDepartment" v-if="department.total"   class="float-right" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import TeacherDashStoreModule from './TeacherDashStoreModule';
import { useRoute } from 'vue-router';
export default {

    setup(){

        const Datalectures = ref([]);
        const subject_name = ref();
        const route = useRoute();

        onMounted(()=>{
            getLectures()
        })  

        async function getLectures(){

            const resp = await TeacherDashStoreModule.actions.getLectures(route.params.id_subject);
            Datalectures.value = resp.data.data;
            if(resp.data.data.length > 0){

              subject_name.value = resp.data.data[0].subject.name; 
            console.log(resp.data.data[0].subject.name);
            }
        } 

        return{
            Datalectures,
            getLectures,
            route,
            subject_name
        }
    }
};
</script>
