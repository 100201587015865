import axios from "axios";
import authHeader from "@/Api/AuthHeader";
import auth from "@/store/auth";

const URL_API = process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getSemester() {
      try {
        const response = await axios.get(URL_API + "semesters", {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    async getAcademicYear() {
      try {
        const response = await axios.get(URL_API + "academic_year", {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },
    async viewSubject(id_subject) {
      try {
        const response = await axios.get(
          URL_API + "disciplinas/" + id_subject,
          { headers: authHeader() }
        );
        return response;
      } catch (error) {
        return error;
      }
    },
    async getSemesters() {
      try {
        const response = await axios.get(URL_API + "get-semesters", {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    createDisciplina(TurmaData) {
      return axios.post(URL_API + `disciplinas`, TurmaData, {
        headers: authHeader(),
      });
    },

    fetchDisciplinas(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            URL_API +
              "disciplinas?page=" +
              queryParams.page +
              "&search=" +
              queryParams.search,
            { headers: authHeader() }
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchSubject(queryParams) {
      return axios.get(URL_API + "fetchSubject?search=" + queryParams.search, {
        headers: authHeader(),
      });
    },

    getAllCourrse() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API + "getCourse", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getSubjects() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API + "getSubjects", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    updateSubject(formData) {
      return axios.put(
        URL_API + `disciplinas/${formData.subject_id}`,
        formData,
        { headers: authHeader() }
      );
    },

    deleteSubject(id_subject) {
      return axios.delete(URL_API + `disciplinas/${id_subject}`, {
        headers: authHeader(),
      });
    },

    getAllSubjects() {
      return axios.get(URL_API + "getAllSubject", { headers: authHeader() });
    },

    async addSubjectPrecedente(data) {
      return await axios.post(URL_API + "subjectPrecente", data, {
        headers: authHeader(),
      });
    },

    async getSubjectPrecedente(data) {
      return await axios.get(
        URL_API + `subjectPrecente?page=${data.page}&search=${data.search}`,
        { headers: authHeader() }
      );
    },

    deletePrecedence(id) {
      return axios.delete(URL_API + `subjectPrecente/${id}`, {
        headers: authHeader(),
      });
    },
    renewSession() {
      return axios.post(URL_API + `renew-session`, { headers: authHeader() });
    },
  },
};
