<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-xxl-12 col-sm-12">
        <div class="card" style="margin-top: 15em">
          <div class="row">
            <div class="col-lg-12">
              <div class="card-body">
                <form action="#" method="post">
                  <!-- personal User data -->
                  <div v-show="firstInfoShow">
                    <h1 class="text-center mb-5">Dados Pessoais</h1>
                  </div>
                  <div class="row" v-show="firstInfoShow">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Nome Completo*</label>
                        <input
                          @input="onUserTypefullName"
                          type="text"
                          class="form-control"
                          v-model="userData.fullName"
                        />
                      </div>
                      <p v-if="fullname_message_error" style="color: red">
                        {{ fullname_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Data de Nasci.:*</label>
                        <input
                          @input="onUserTypeBithDate"
                          type="date"
                          name="datepicker"
                          class="datepicker-default form-control"
                          id="datepicker1"
                          v-model="userData.birthDate"
                        />
                      </div>
                      <p v-if="birthDate_message_error" style="color: red">
                        {{ birthDate_message_error }}
                      </p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Gênero*</label>
                        <select
                          @change="GenderChange"
                          class="form-control"
                          v-model="userData.gender"
                        >
                          <option value="Gender">Gênero</option>
                          <option value="Masculino">Masculino</option>
                          <option value="Feminino">Feminino</option>
                          <option value="Outro">Outro</option>
                        </select>
                      </div>
                      <p v-if="gender_message_error" style="color: red">
                        {{ gender_message_error }}
                      </p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Email*</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userData.email"
                          @input="validateEmail"
                        />
                      </div>
                      <p v-if="emailError" style="color: red">
                        {{ emailError }}
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Contacto*</label>
                        <input
                          type="text"
                          class="form-control"
                          @keypress="numberOnly"
                          pattern="/^[0-9]+$/"
                          maxlength="9"
                          v-model="userData.contacts.contact"
                          @input="validatePhoneNumber"
                        />
                      </div>
                      <p v-if="phoneNumberError" style="color: red">
                        {{ phoneNumberError }}
                      </p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Contacto Alternativo</label>
                        <input
                          type="text"
                          class="form-control"
                          @keypress="numberOnly"
                          pattern="/^[0-9]+$/"
                          maxlength="9"
                          v-model="userData.contacts.alternative_contact"
                          @input="validatePhoneNumberAlternative"
                        />
                      </div>
                      <p v-if="phoneNumberErrorAlternative" style="color: red">
                        {{ phoneNumberErrorAlternative }}
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label"
                          >Tem alugma deficiência?*</label
                        >
                        <select
                          @change="is_deficientChange($event)"
                          class="form-control"
                          v-model="userData.is_deficient"
                        >
                          <option value="-1">Selecione</option>
                          <option value="0">Não</option>
                          <option value="1">Sim</option>
                        </select>
                      </div>
                      <p v-if="isDeficient_message_error" style="color: red">
                        {{ isDeficient_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label"
                          >Selecione o tipo de deficiência</label
                        >
                        <select
                          class="form-control"
                          :disabled="deficientTypeDisabled"
                          v-model="userData.deficient_type"
                        >
                          <option value="" selected>Selecione o tipo</option>
                          <option value="Deficiencia Visual">
                            Deficiencia Visual
                          </option>
                          <option value="Cegueira">Cegueira</option>
                          <option value="Paraplegia">Paraplegia</option>
                          <option value="Paraparesia">Paraparesia</option>
                          <option value="Monoplegia">Monoplegia</option>
                          <option value="Baixa Visao">Baixa visao</option>
                          <option value="HipoMiopia">HipoMiopia</option>
                          <option value="HiperMiopia">HiperMiopia</option>
                          <option value="Surdez Condutiva">
                            Surdez Condutiva
                          </option>
                          <option value="Surdez Sensorioneural">
                            Surdez Sensorioneural
                          </option>
                          <option value="Surdez Mista">Surdez Mista</option>
                          <option value="Surdez Central">Surdez Central</option>
                          <option value="Outra">Outra</option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Nome do Pai*</label>
                        <input
                          @input="onUserTypeFatherName"
                          type="text"
                          class="form-control"
                          v-model="userData.fathers_filliation"
                        />
                      </div>
                      <p v-if="father_name_message_error" style="color: red">
                        {{ father_name_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Nome da Mãe*</label>
                        <input
                          @input="onUserTypeMotherName"
                          type="text"
                          class="form-control"
                          v-model="userData.mothers_filliation"
                        />
                      </div>
                      <p v-if="mother_name_message_error" style="color: red">
                        {{ mother_name_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <button
                        type="button"
                        class="btn btn-primary float-right"
                        v-on:click="handleNextInfo"
                      >
                        Próximo
                      </button>
                    </div>
                  </div>

                  <!-- End -->

                  <div v-show="secondInfoShow">
                    <h1 class="text-center mb-5">Documentação</h1>
                  </div>
                  <div class="row" v-show="secondInfoShow">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label"
                          >Selecione o tipo documento:*</label
                        >
                        <select
                          @change="ChangeDocType"
                          class="form-control"
                          v-model="userData.doc_type"
                        >
                          <option value="Class">
                            Selecione o tipo documento
                          </option>
                          <option value="Bilhete de Identidade">
                            Bilhete de Identidade
                          </option>
                        </select>
                      </div>
                      <p v-if="doc_message_error" style="color: red">
                        {{ doc_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Número do doc:*</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userData.doc_number"
                          maxlength="13"
                          @input="validateIdentificationNumber"
                        />
                      </div>
                      <p v-if="doc_number_message_error" style="color: red">
                        {{ doc_number_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-4">
                      <div class="form-group">
                        <label for="">País:*</label>
                        <select
                          @change="ChangeCountry"
                          v-model="userData.nactionality"
                          class="form-control"
                        >
                          <option value="Afghanistan">Afghanistan</option>
                          <option value="Åland Islands">Åland Islands</option>
                          <option value="Albania">Albania</option>
                          <option value="Algeria">Algeria</option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antarctica">Antarctica</option>
                          <option value="Antigua and Barbuda">
                            Antigua and Barbuda
                          </option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaijan">Azerbaijan</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrain">Bahrain</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Belize">Belize</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermuda">Bermuda</option>
                          <option value="Bhutan">Bhutan</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bosnia and Herzegovina">
                            Bosnia and Herzegovina
                          </option>
                          <option value="Botswana">Botswana</option>
                          <option value="Bouvet Island">Bouvet Island</option>
                          <option value="Brazil">Brazil</option>
                          <option value="British Indian Ocean Territory">
                            British Indian Ocean Territory
                          </option>
                          <option value="Brunei Darussalam">
                            Brunei Darussalam
                          </option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Cambodia">Cambodia</option>
                          <option value="Cameroon">Cameroon</option>
                          <option value="Canada">Canada</option>
                          <option value="Cape Verde">Cape Verde</option>
                          <option value="Cayman Islands">Cayman Islands</option>
                          <option value="Central African Republic">
                            Central African Republic
                          </option>
                          <option value="Chad">Chad</option>
                          <option value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Christmas Island">
                            Christmas Island
                          </option>
                          <option value="Cocos (Keeling) Islands">
                            Cocos (Keeling) Islands
                          </option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comoros">Comoros</option>
                          <option value="Congo">Congo</option>
                          <option value="Congo, The Democratic Republic of The">
                            Congo, The Democratic Republic of The
                          </option>
                          <option value="Cook Islands">Cook Islands</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Cote D'ivoire">Cote D'ivoire</option>
                          <option value="Croatia">Croatia</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Cyprus">Cyprus</option>
                          <option value="Czech Republic">Czech Republic</option>
                          <option value="Denmark">Denmark</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Dominican Republic">
                            Dominican Republic
                          </option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egypt">Egypt</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Equatorial Guinea">
                            Equatorial Guinea
                          </option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Ethiopia">Ethiopia</option>
                          <option value="Falkland Islands (Malvinas)">
                            Falkland Islands (Malvinas)
                          </option>
                          <option value="Faroe Islands">Faroe Islands</option>
                          <option value="Fiji">Fiji</option>
                          <option value="Finland">Finland</option>
                          <option value="France">France</option>
                          <option value="French Guiana">French Guiana</option>
                          <option value="French Polynesia">
                            French Polynesia
                          </option>
                          <option value="French Southern Territories">
                            French Southern Territories
                          </option>
                          <option value="Gabon">Gabon</option>
                          <option value="Gambia">Gambia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Germany">Germany</option>
                          <option value="Ghana">Ghana</option>
                          <option value="Gibraltar">Gibraltar</option>
                          <option value="Greece">Greece</option>
                          <option value="Greenland">Greenland</option>
                          <option value="Grenada">Grenada</option>
                          <option value="Guadeloupe">Guadeloupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guernsey">Guernsey</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guinea-bissau">Guinea-bissau</option>
                          <option value="Guyana">Guyana</option>
                          <option value="Haiti">Haiti</option>
                          <option value="Heard Island and Mcdonald Islands">
                            Heard Island and Mcdonald Islands
                          </option>
                          <option value="Holy See (Vatican City State)">
                            Holy See (Vatican City State)
                          </option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hong Kong">Hong Kong</option>
                          <option value="Hungary">Hungary</option>
                          <option value="Iceland">Iceland</option>
                          <option value="India">India</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="Iran, Islamic Republic of">
                            Iran, Islamic Republic of
                          </option>
                          <option value="Iraq">Iraq</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Isle of Man">Isle of Man</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japan">Japan</option>
                          <option value="Jersey">Jersey</option>
                          <option value="Jordan">Jordan</option>
                          <option value="Kazakhstan">Kazakhstan</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Kiribati">Kiribati</option>
                          <option
                            value="Korea, Democratic People's Republic of"
                          >
                            Korea, Democratic People's Republic of
                          </option>
                          <option value="Korea, Republic of">
                            Korea, Republic of
                          </option>
                          <option value="Kuwait">Kuwait</option>
                          <option value="Kyrgyzstan">Kyrgyzstan</option>
                          <option value="Lao People's Democratic Republic">
                            Lao People's Democratic Republic
                          </option>
                          <option value="Latvia">Latvia</option>
                          <option value="Lebanon">Lebanon</option>
                          <option value="Lesotho">Lesotho</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libyan Arab Jamahiriya">
                            Libyan Arab Jamahiriya
                          </option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lithuania">Lithuania</option>
                          <option value="Luxembourg">Luxembourg</option>
                          <option value="Macao">Macao</option>
                          <option
                            value="Macedonia, The Former Yugoslav Republic of"
                          >
                            Macedonia, The Former Yugoslav Republic of
                          </option>
                          <option value="Madagascar">Madagascar</option>
                          <option value="Malawi">Malawi</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Maldives">Maldives</option>
                          <option value="Mali">Mali</option>
                          <option value="Malta">Malta</option>
                          <option value="Marshall Islands">
                            Marshall Islands
                          </option>
                          <option value="Martinique">Martinique</option>
                          <option value="Mauritania">Mauritania</option>
                          <option value="Mauritius">Mauritius</option>
                          <option value="Mayotte">Mayotte</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Micronesia, Federated States of">
                            Micronesia, Federated States of
                          </option>
                          <option value="Moldova, Republic of">
                            Moldova, Republic of
                          </option>
                          <option value="Monaco">Monaco</option>
                          <option value="Mongolia">Mongolia</option>
                          <option value="Montenegro">Montenegro</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Morocco">Morocco</option>
                          <option value="Mozambican" selected>
                            Mo&ccedil;ambicana
                          </option>
                          <option value="Myanmar">Myanmar</option>
                          <option value="Namibia">Namibia</option>
                          <option value="Nauru">Nauru</option>
                          <option value="Nepal">Nepal</option>
                          <option value="Netherlands">Netherlands</option>
                          <option value="Netherlands Antilles">
                            Netherlands Antilles
                          </option>
                          <option value="New Caledonia">New Caledonia</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Niger">Niger</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk Island">Norfolk Island</option>
                          <option value="Northern Mariana Islands">
                            Northern Mariana Islands
                          </option>
                          <option value="Norway">Norway</option>
                          <option value="Oman">Oman</option>
                          <option value="Pakistan">Pakistan</option>
                          <option value="Palau">Palau</option>
                          <option value="Palestinian Territory, Occupied">
                            Palestinian Territory, Occupied
                          </option>
                          <option value="Panama">Panama</option>
                          <option value="Papua New Guinea">
                            Papua New Guinea
                          </option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Peru">Peru</option>
                          <option value="Philippines">Philippines</option>
                          <option value="Pitcairn">Pitcairn</option>
                          <option value="Poland">Poland</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Reunion">Reunion</option>
                          <option value="Romania">Romania</option>
                          <option value="Russian Federation">
                            Russian Federation
                          </option>
                          <option value="Rwanda">Rwanda</option>
                          <option value="Saint Helena">Saint Helena</option>
                          <option value="Saint Kitts and Nevis">
                            Saint Kitts and Nevis
                          </option>
                          <option value="Saint Lucia">Saint Lucia</option>
                          <option value="Saint Pierre and Miquelon">
                            Saint Pierre and Miquelon
                          </option>
                          <option value="Saint Vincent and The Grenadines">
                            Saint Vincent and The Grenadines
                          </option>
                          <option value="Samoa">Samoa</option>
                          <option value="San Marino">San Marino</option>
                          <option value="Sao Tome and Principe">
                            Sao Tome and Principe
                          </option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Serbia">Serbia</option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leone">Sierra Leone</option>
                          <option value="Singapore">Singapore</option>
                          <option value="Slovakia">Slovakia</option>
                          <option value="Slovenia">Slovenia</option>
                          <option value="Solomon Islands">
                            Solomon Islands
                          </option>
                          <option value="Somalia">Somalia</option>
                          <option value="South Africa">South Africa</option>
                          <option
                            value="South Georgia and The South Sandwich Islands"
                          >
                            South Georgia and The South Sandwich Islands
                          </option>
                          <option value="Spain">Spain</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="Sudan">Sudan</option>
                          <option value="Suriname">Suriname</option>
                          <option value="Svalbard and Jan Mayen">
                            Svalbard and Jan Mayen
                          </option>
                          <option value="Swaziland">Swaziland</option>
                          <option value="Sweden">Sweden</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="Syrian Arab Republic">
                            Syrian Arab Republic
                          </option>
                          <option value="Taiwan">Taiwan</option>
                          <option value="Tajikistan">Tajikistan</option>
                          <option value="Tanzania, United Republic of">
                            Tanzania, United Republic of
                          </option>
                          <option value="Thailand">Thailand</option>
                          <option value="Timor-leste">Timor-leste</option>
                          <option value="Togo">Togo</option>
                          <option value="Tokelau">Tokelau</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad and Tobago">
                            Trinidad and Tobago
                          </option>
                          <option value="Tunisia">Tunisia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turks and Caicos Islands">
                            Turks and Caicos Islands
                          </option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="Uganda">Uganda</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Emirates">
                            United Arab Emirates
                          </option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="United States">United States</option>
                          <option value="United States Minor Outlying Islands">
                            United States Minor Outlying Islands
                          </option>
                          <option value="Uruguay">Uruguay</option>
                          <option value="Uzbekistan">Uzbekistan</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Viet Nam">Viet Nam</option>
                          <option value="Virgin Islands, British">
                            Virgin Islands, British
                          </option>
                          <option value="Virgin Islands, U.S.">
                            Virgin Islands, U.S.
                          </option>
                          <option value="Wallis and Futuna">
                            Wallis and Futuna
                          </option>
                          <option value="Western Sahara">Western Sahara</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                      </div>
                      <p v-if="country_message_error" style="color: red">
                        {{ country_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label"
                          >Selecione o Província:*</label
                        >
                        <select
                          class="form-control"
                          v-model="userData.provice_id"
                          @change="ChangeProvince($event)"
                        >
                          <option value="0">Selecione o Província</option>
                          <option
                            :value="provinces.id"
                            v-for="provinces in provincesData"
                            :key="provinces.id"
                          >
                            {{ provinces.name }}
                          </option>
                        </select>
                      </div>
                      <p v-if="province_message_error" style="color: red">
                        {{ province_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Selecione o Distrito*</label>
                        <select
                          @change="ChangeDistrict"
                          :disabled="loadDistricts"
                          class="form-control"
                          v-model="userData.district_id"
                        >
                          <option value="0">Selecione o Distrito</option>
                          <option
                            :value="district.id"
                            v-for="district in districtData"
                            :key="district.id"
                          >
                            {{ district.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label for="">Bairro/AVENIDA:*</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="userData.address_location"
                          @input="onUserTypeAddressLocation"
                        />
                      </div>
                      <p v-if="address_message_error" style="color: red">
                        {{ address_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <button
                        type="button"
                        v-on:click="handleStepTwo"
                        class="btn btn-primary float-right"
                      >
                        Proximo
                      </button>
                      <button
                        type="button"
                        class="btn btn-light"
                        v-on:click="handleBackInfo"
                      >
                        Voltar
                      </button>
                    </div>
                  </div>

                  <div v-show="stepThreeInfo">
                    <h1 class="text-center mb-5">Nível Acadêmico</h1>
                  </div>
                  <div class="row" v-show="stepThreeInfo">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label"
                          >última escola que frequentou*</label
                        >
                        <input
                          @input="onUserTypeLastSchoolFrequency"
                          type="text"
                          class="form-control"
                          v-model="userData.last_school_frequency"
                        />
                      </div>
                      <p v-if="last_school_message_error" style="color: red">
                        {{ last_school_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Formacao:*</label>
                        <select
                          @change="ChangeTraining"
                          class="form-control"
                          v-model="userData.trainingArea"
                        >
                          <option value="Geral">Geral</option>
                          <option value="Tecnico">Técnico</option>
                        </select>
                      </div>
                      <p v-if="trainingArea_message_error" style="color: red">
                        {{ trainingArea_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Ano de conclusão:*</label>
                        <select
                          @change="changeYearLastSchool"
                          class="form-control"
                          v-model="userData.last_school_frequency_year"
                        >
                          <option value="Gender">Ano de Frequência*</option>
                          <option value="2023">2023</option>
                          <option value="2022">2022</option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                          <option value="2015">2015</option>
                          <option value="2014">2014</option>
                          <option value="2013">2013</option>
                          <option value="2012">2012</option>
                          <option value="2011">2011</option>
                          <option value="2010">2010</option>
                          <option value="2009">2009</option>
                          <option value="2008">2008</option>
                          <option value="2007">2007</option>
                          <option value="2006">2006</option>
                          <option value="2005">2005</option>
                          <option value="2004">2004</option>
                          <option value="2003">2003</option>
                          <option value="2002">2002</option>
                          <option value="2001">2001</option>
                          <option value="2000">2000</option>
                          <option value="1999">1999</option>
                        </select>
                      </div>
                      <p
                        v-if="last_school_year_message_error"
                        style="color: red"
                      >
                        {{ last_school_year_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <button
                        type="button"
                        v-on:click="handleStepThree"
                        class="btn btn-primary float-right"
                      >
                        Proximo
                      </button>
                      <button
                        type="button"
                        class="btn btn-light"
                        v-on:click="handleBackDocument"
                      >
                        Voltar
                      </button>
                    </div>
                  </div>

                  <div v-show="stepFourInfo">
                    <h1 class="text-center mb-5">Curso</h1>
                  </div>
                  <div class="row" v-show="stepFourInfo">
                    <div class="col-lg-4 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Selecione o Curso*</label>
                        <select
                          @change="ChangeCourse"
                          class="form-control"
                          v-model="userData.course_id"
                        >
                          <option value="Class">Selecione o Curso</option>
                          <option
                            :value="courses.id"
                            v-for="courses in DataCourse"
                            :key="courses.id"
                          >
                            {{ courses.name }}
                          </option>
                        </select>
                      </div>
                      <p v-if="course_message_error" style="color: red">
                        {{ course_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Selecione Nivel:*</label>
                        <select
                          @change="ChangeLevel"
                          class="form-control"
                          v-model="userData.level_id"
                        >
                          <option value="Class">Selecione o nivel</option>
                          <option
                            :value="l.id"
                            v-for="(l, index) in levels"
                            :key="index"
                          >
                            {{ l.name }}
                          </option>
                        </select>
                      </div>
                      <p v-if="level_message_error" style="color: red">
                        {{ level_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Selecione o Regime*</label>
                        <select
                          @change="ChangeScholarShift"
                          class="form-control"
                          v-model="userData.scholar_shift"
                        >
                          <option value="Class">Regime</option>
                          <option value="LABORAL">LABORAL</option>
                          <option value="POS-LABORAL">POS-LABORAL</option>
                        </select>
                      </div>
                      <p v-if="scholar_shift_message_error" style="color: red">
                        {{ scholar_shift_message_error }}
                      </p>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <button
                        type="button"
                        v-on:click="handleSubmitButton"
                        class="btn btn-primary float-right"
                      >
                        {{ loading == false ? "Criar" : "Criando..." }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-light"
                        v-on:click="handleBackSchool"
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </form>
                <div class="new-account mt-3 text-center">
                  <p>
                    Já tem uma conta?
                    <router-link style="color: #0093dd" to="/"
                      >Clique aqui</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loading :loadingData="loading"></Loading>
</template>

<script>
import axios from "axios";
import bridge from "../../Api/bridge";
import toastr from "toastr";
import StudentModuleService from "../students/StudentModuleService";
import Loading from "../../components/Loading.vue";
import Swal from "sweetalert2";
const URL_API = process.env.VUE_APP_URL_API;

export default {
  components: {
    Loading,
  },
  data() {
    return {
      firstInfoShow: true,
      secondInfoShow: false,
      stepThreeInfo: false,
      stepFourInfo: false,
      personalData: true,
      loadDistricts: true,
      schoolData: false,
      courseData: false,
      documentData: false,
      loading: false,
      deficientTypeDisabled: true,
      userData: {
        fullName: "",
        address_location: "",
        email: "",
        gender: "",
        doc_type: "",
        doc_number: "",
        nactionality: "",
        birthDate: "",
        last_school_frequency: "",
        level_type: "",
        last_school_frequency_year: "",
        fathers_filliation: "",
        mothers_filliation: "",
        childhood_location: "",
        is_deficient: "",
        deficient_type: "",
        course_id: "",
        contacts: {
          contact: "",
          alternative_contact: "",
        },
        provice_id: "",
        district_id: "",
        regime: "",
        regimeSuperior: "",
        scholar_shift: "",
        level_id: "",
        trainingArea: "",
      },
      levels: [],
      provincesData: [],
      districtData: [],
      DataCourse: [],

      // Validate variable
      emailError: "",
      phoneNumberError: "",
      phoneNumberErrorAlternative: "",
      birthDate_message_error: "",
      district_message_error: "",
      address_message_error: "",
      doc_message_error: "",
      gender_message_error: "",
      isDeficient_message_error: "",
      father_name_message_error: "",
      mother_name_message_error: "",
      country_message_error: "",
      province_message_error: "",
      fullname_message_error: "",
      nationality_message_error: "",
      number_message_error: "",
      doc_number_message_error: "",
      last_school_message_error: "",
      last_school_year_message_error: "",
      regime_message_error: "",
      course_message_error: "",
      level_message_error: "",
      scholar_shift_message_error: "",
      trainingArea_message_error: "",
    };
  },
  methods: {
    ChangeDocType() {
      this.onUserTypeDocument();
    },
    ChangeScholarShift() {
      this.onUserTypeScholarShift();
    },
    ChangeLevel() {
      this.onUserTypeLevel();
    },

    ChangeCourse() {
      this.onUserTypeCourse();
    },
    changeYearLastSchool() {
      this.onUserTypeLastSchoolYear();
    },
    ChangeTraining() {
      this.onUserTypeTrainingArea();
    },
    ChangeDistrict() {
      this.onUserTypeDistrict();
    },

    ChangeCountry() {
      this.onUserTypeCountry();
    },
    GenderChange(evt) {
      this.onUserTypeGender();
    },
    // Validacao no numero BI
    validateIdentificationNumber() {
      const regex = /^[0-9]{12}[a-zA-Z]$/;

      // Testar a expressão regular
      if (!regex.test(this.userData.doc_number)) {
        this.doc_number_message_error = "Número de identificação inválido!";

        return;
      } else {
        this.doc_number_message_error = "";
      }
    },

    validatePhoneNumber() {
      // Expressão regular para validar o número de telefone
      const phoneNumberRegex = /^(86)\d{8}$/;
      var firstTwoDigits = "";
      if (this.userData.contacts.contact.length >= 2) {
        firstTwoDigits = this.userData.contacts.contact.slice(0, 2);

        switch (firstTwoDigits) {
          case "84":
            this.phoneNumberError = null;

            break;
          case "85":
            this.phoneNumberError = null;

            break;
          case "86":
            this.phoneNumberError = null;

            break;
          case "87":
            this.phoneNumberError = null;

            break;
          case "82":
            this.phoneNumberError = null;

            break;
          case "83":
            this.phoneNumberError = null;

            break;

          default:
            this.phoneNumberError =
              "Por favor, insira um número de telefone válido.";

            break;
        }
      }
    },

    validatePhoneNumberAlternative() {
      // Expressão regular para validar o número de telefone
      var firstTwoDigits = "";

      if (this.userData.contacts.alternative_contact.length >= 2) {
        firstTwoDigits = this.userData.contacts.alternative_contact.slice(0, 2);

        switch (firstTwoDigits) {
          case "84":
            phoneNumberErrorAlternative.value = null;

            break;
          case "85":
            phoneNumberErrorAlternative.value = null;

            break;
          case "86":
            phoneNumberErrorAlternative.value = null;

            break;
          case "87":
            phoneNumberErrorAlternative.value = null;

            break;
          case "82":
            phoneNumberErrorAlternative.value = null;

            break;
          case "83":
            phoneNumberErrorAlternative.value = null;

            break;

          default:
            this.phoneNumberErrorAlternative =
              "Por favor, insira um número de telefone válido.";

            break;
        }
      }
    },

    // validate inputs
    onUserTypefullName() {
      if (this.userData.fullName != "") {
        this.fullname_message_error = "";
      }
    },

    onUserTypeFatherName() {
      if (this.userData.fathers_filliation !== "") {
        this.father_name_message_error = "";
      }
    },

    onUserTypeMotherName() {
      if (this.userData.mothers_filliation !== "") {
        this.mother_name_message_error = "";
      }
    },

    onUserTypeGender() {
      if (this.userData.gender !== "") {
        this.gender_message_error = "";
      }
    },

    onUserTypeBithDate() {
      if (this.userData.birthDate !== "") {
        this.birthDate_message_error = "";
      }
    },

    onUserTypeIsdeficient() {
      if (this.userData.is_deficient !== "") {
        this.isDeficient_message_error = "";
      }
    },

    onUserTypeLastSchoolFrequency() {
      if (this.userData.last_school_frequency !== "") {
        this.last_school_message_error = "";
      }
    },
    onUserTypeCourse() {
      if (this.userData.course_id !== "") {
        this.course_message_error = "";
      }
    },

    onUserTypeTrainingArea() {
      if (this.userData.trainingArea !== "") {
        this.trainingArea_message_error = "";
      }
    },

    onUserTypeLastSchoolYear() {
      if (this.userData.last_school_frequency_year !== "") {
        this.last_school_year_message_error = "";
      }
    },

    onUserTypeCountry() {
      if (this.userData.nactionality !== "") {
        this.country_message_error = "";
      }
    },

    onUserTypeProvince() {
      if (this.userData.provice_id !== "") {
        this.province_message_error = "";
      }
    },

    onUserTypeDistrict() {
      if (this.userData.district_id !== "") {
        this.district_message_error = "";
      }
    },

    onUserTypeAddressLocation() {
      if (this.userData.address_location !== "") {
        this.address_message_error = "";
      }
    },

    onUserTypeDocument() {
      if (this.userData.doc_type !== "") {
        this.doc_message_error = "";
      }
    },
    onUserTypeLevel() {
      if (this.userData.level_id !== "") {
        this.level_message_error = "";
      }
    },
    onUserTypeScholarShift() {
      if (this.userData.scholar_shift !== "") {
        this.scholar_shift_message_error = "";
      }
    },

    validateEmail() {
      // Expressão regular para validar o formato de um e-mail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Verifica se o e-mail atende ao formato esperado
      if (!emailRegex.test(this.userData.email)) {
        this.emailError = "Por favor, insira um endereço de e-mail válido.";
      } else {
        this.emailError = null;
      }
    },

    async getLevels() {
      const resp = await StudentModuleService.actions.getLevelsCandi();
      this.levels = resp.data;
    },
    clearInputs() {
      this.userData.address_location = "";
      this.userData.contacts.alternative_contact = "";
      this.userData.birthDate = "";
      this.userData.childhood_location = "";
      this.userData.contacts.contact = "";
      this.userData.course_id = "";
      this.userData.deficient_type = "";
      this.userData.district_id = "";
      this.userData.doc_number = "";
      this.userData.doc_type = "";
      this.userData.email = "";
      this.userData.fathers_filliation = "";
      this.userData.gender = "";
      this.userData.is_deficient = "";
      this.userData.last_school_frequency = "";
      this.userData.last_school_frequency_year = "";
      this.userData.level_type = "";
      this.userData.mothers_filliation = "";
      this.userData.nactionality = "";
      this.userData.fullName = "";
      this.userData.provice_id = "";
      this.userData.regime = "";
      this.userData.regimeSuperior = "";
    },
    handleNextInfo() {
      if (this.userData.fullName == "") {
        this.fullname_message_error =
          "O campo nome completo nao pode estar vazio!";
        return;
      }

      if (this.userData.birthDate == "") {
        this.birthDate_message_error =
          "O campo data de Nascimento nao pode estar vazio!";
        return;
      }

      if (this.userData.gender == "") {
        this.gender_message_error = "O campo genero nao pode estar vazio!";
        return;
      }

      if (this.userData.email == "") {
        this.emailError = "O campo email nao pode estar vazio!";
        return;
      }

      if (this.userData.contacts.contact == "") {
        this.phoneNumberError = "O campo contacto nao pode estar vazio!";
        return;
      }

      if (this.userData.is_deficient == "") {
        return (this.isDeficient_message_error =
          "O campo deficiencia nao pode estar vazio!");
      }

      if (this.userData.fathers_filliation == "") {
        this.father_name_message_error =
          "O campo nome do pai nao pode estar vazio!";
        return;
      }

      if (this.userData.mothers_filliation == "") {
        this.mother_name_message_error =
          "O campo nome da mae nao pode estar vazio!";
        return;
      }

      this.firstInfoShow = false;
      this.secondInfoShow = true;
      this.personalData = false;
      this.documentData = true;
    },

    handleStepTwo() {
      if (
        this.userData.doc_type == "" ||
        this.userData.doc_type !== "Bilhete de Identidade"
      ) {
        this.doc_message_error =
          "O campo tipo de documento nao pode estar vazio!";
        return;
      }

      if (this.userData.doc_number == "") {
        this.doc_number_message_error =
          "O campo numero do documento nao pode estar vazio!";
        return;
      }

      if (this.userData.nactionality == "") {
        this.country_message_error =
          "O campo nacionalidade nao pode estar vazio!";
        return;
      }

      if (this.userData.provice_id == "") {
        this.province_message_error = "O campo provincia nao pode estar vazio!";
        return;
      }

      if (this.userData.district_id == "") {
        this.district_message_error = "O campo districto nao pode estar vazio!";
        return;
      }

      if (this.userData.address_location == "") {
        this.address_message_error =
          "O campo bairro ou avenida nao estar vazio!";
        return;
      }

      this.secondInfoShow = false;
      this.stepThreeInfo = true;
      this.documentData = false;
      this.schoolData = true;
    },

    handleStepThree() {
      if (this.userData.last_school_frequency == "") {
        this.last_school_message_error =
          "O campo ultima escola nao pode estar vazio!";
        return;
      }

      if (this.userData.trainingArea == "") {
        this.trainingArea_message_error =
          "O campo formacao nao pode estar vazio!";
        return;
      }

      if (this.userData.last_school_frequency_year == "") {
        this.last_school_year_message_error =
          "O campo nacionalidade nao pode estar vazio!";
        return;
      }

      this.stepThreeInfo = false;
      this.stepFourInfo = true;
      this.schoolData = false;
      this.couseData = true;
    },

    // handleStepFour() {
    //   if (this.userData.course == "" || this.userData.regimeSuperior == "") {
    //     return bridge.warning(
    //       "Por favor preecha os campos obrigatorios",
    //       "Opsssiiii"
    //     );
    //   }
    // },

    handleBackInfo() {
      this.secondInfoShow = false;
      this.firstInfoShow = true;
      this.documentData = false;
      this.personalData = true;
    },

    handleBackDocument() {
      this.firstInfoShow = false;
      this.schoolData = false;
      this.documentData = true;
      this.secondInfoShow = true;
      this.stepThreeInfo = false;
    },

    handleBackSchool() {
      this.courseData = false;
      this.stepThreeInfo = true;
      this.schoolData = true;
      this.stepFourInfo = false;
    },

    goToFirstForm() {
      this.firstInfoShow = true;
      this.secondInfoShow = false;
      this.stepThreeInfo = false;
      this.stepFourInfo = false;
      this.personalData = true;
    },

    is_deficientChange(evt) {
      this.onUserTypeIsdeficient();
      if (evt.target.value < 0 || evt.target.value == 0) {
        this.deficientTypeDisabled = true;
      } else {
        this.deficientTypeDisabled = false;
      }
    },

    // Create new Candidate student
    async handleSubmitButton() {
      try {
        this.loading = true;

        if (this.userData.course_id == "") {
          this.course_message_error = "O campo curso nao pode estar vazio!";
          this.loading = false;
          return;
        }
        if (this.userData.level_id == "") {
          this.level_message_error = "O campo nivel nao pode estar vazio!";
          this.loading = false;
          return;
        }
        if (this.userData.scholar_shift == "") {
          this.scholar_shift_message_error =
            "O campo regime nao pode estar vazio!";
          this.loading = false;
          return;
        }

        this.couseData = true;
        const response = await axios.post(URL_API + "Candidate", this.userData);
        console.log(response.data);
        if (response.data.status == true) {
          Swal.fire({
            title: "A sua candidatura foi submetida sucesso!",
            text: "Por favor verifique a sua caixa de email.",
            icon: "success",
          });
          this.clearInputs();
          this.goToFirstForm();
        }
      } catch (error) {
        console.log(error.response);
        if (typeof error.response != "undefined") {
          Object.keys(error.response.data.errors).forEach((key) => {
            toastr.info(key, error.response.data.errors[key]);
          });
        }
      } finally {
        this.loading = false;
      }
    },

    async getProvinces() {
      await axios
        .get(URL_API + "provinces")
        .then((response) => {
          // console.log(response);

          this.provincesData = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async ChangeProvince(evt) {
      this.loadDistricts = true;
      this.onUserTypeProvince();
      await axios
        .get(URL_API + "district/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.districtData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getAllCourses() {
      await axios
        .get(URL_API + "getAllCourses")
        .then((response) => {
          // console.log(response);
          this.DataCourse = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    numberOnly($event) {
      let keycode = $event.keyCode ? $event.keyCode : $event.which;

      if ((keycode < 48 || keycode > 57) && keycode !== 46) {
        $event.preventDefault();
      }
    },
  },

  created() {
    this.getProvinces();
    this.getAllCourses();
    this.getLevels();
  },
};
</script>

<style scoped>
.bg-image {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  height: 100%;
}

.login {
  position: fixed;
  top: 40%;
  left: 47%;
  padding: auto;
  margin: auto;
}
.login img {
  width: 80px;
  height: 80px;
}

#processador {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;

  width: 100%;

  border-radius: 4px;
  height: 100%;
}

#processador {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  transition: height 0.2s linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

#processador {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: #fff;
}
</style>
