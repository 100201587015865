<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Disciplinas</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Professor</li>
          <li class="breadcrumb-item active">Disciplinas</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
      <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6">
              <h5 class="page-title">Lista de Disciplinas</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
              <input
                type="text"
                class="form-control"
                v-model="search"
                placeholder="Pesquisar..."
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Turma</th>
                  <th scope="col">Descricao</th>
                  <th scope="col" colspan="2">Accao</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sub, index) in dataSubjects" :key="sub.id">
                  <td>{{index + 1}}</td>
                  <td>{{sub.name}}</td>
                  <td>{{sub.description}}</td>
                  <td><router-link :to="{name:'subjetctDateils', params:{'id_subject':sub.id}}">Detalhes</router-link></td>
                </tr>
              </tbody>
            </table>

            <!-- <Page :total="department.total" :model-value="department.current_page"  :page-size="parseInt(department.per_page)" @on-change="getAllDepartment" v-if="department.total"   class="float-right" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import TeacherDashStoreModule from './TeacherDashStoreModule';
 export default {

    setup(){

        const dataSubjects = ref([]);
 
        onMounted(()=>{
            getSubjects()
        })  

        async function getSubjects(){

            const resp = await TeacherDashStoreModule.actions.getSubjects();
            dataSubjects.value = resp.data.data.subject;
            console.log(resp.data.data);
        } 

        return{
            dataSubjects,
            getSubjects,
         }
    }
};
</script>
