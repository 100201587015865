import axios from "axios";
import authHeader from "@/Api/AuthHeader";
const URL_API =  process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createTeacher(ctx, TeacherData) {
      return new Promise((resolve, reject) => {
        axios
          .post(URL_API+"professor", TeacherData, { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async fetchProvinces() {
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get("/api/provinces", { headers: authHeader() })
      //     .then((response) => resolve(response))
      //     .catch((error) => reject(error));
      // });

      try {
        const response = await axios.get(URL_API+"provinces/", {
          headers: authHeader(),
        });

        return response;
      } catch (error) {
        return error;
      }
    },

    getAllCourses() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+"getCourse", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async fetchDistricts(province_id) {
      try {
        const resp = await axios.get(URL_API+"district/" + province_id, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async fetchRoles() {
      try {
        const resp = await axios.get(URL_API+"roles", { headers: authHeader() });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async getRoleByUser(user_id) {
      try {
        const resp = await axios.get(URL_API+"getRoleByUser/" + user_id, {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    async alocateRole(roleData, user_id) {
      try {
        const resp = await axios.post(
          URL_API+"addUserRole",
          { role: roleData, user_id: user_id },
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    async addPermissions(permissionsData, user_id) {
      try {
        const resp = await axios.post(
          URL_API+"addPermissions",
          { permissions: permissionsData, user_id: user_id },
          { headers: authHeader() }
        );
        return resp;
      } catch (error) {
        return error;
      }
    },

    addUserPermissions(data){
      return axios.post(URL_API+`addUserPermissions`, data,{headers:authHeader()} )
    }, 


    async getPermissionRole(uuid){
      return axios.get(URL_API+`getPermissionRole/${uuid}`,{headers:authHeader()} )
    },

    getAllTeachers() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API+"professor", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    async ShowTeacher(teacher_id) {
      try {
        const response = await axios.get(URL_API+"professor/" + teacher_id, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    async updateTeacher(teacher_id, formData) {
      console.log(formData);
      try {
        const response = await axios.put(
          URL_API+"professor/" + teacher_id,
          formData,
          {
            headers: authHeader(),
          }
        );
        return response;
      } catch (error) {
        return error;
      }
    },

    async alocateData(dataAlocate, teacher_id) {
      try {
        const resp = await axios.post(
          URL_API+"alocateDataProfessor",
          { data: dataAlocate, id: teacher_id },
          {
            headers: authHeader(),
          }
        );

        return resp;
      } catch (error) {
        return error;
      }
    },

    createEmployee(datas) {
      return axios.post(URL_API+"employees", datas, {
        headers: authHeader(),
      });
    },

    async getEmployes() {
      try {
        const resp = await axios.get(URL_API+"listAdminUser", {
          headers: authHeader(),
        });
        return resp;
      } catch (error) {
        return error;
      }
    },

    getAllDepartment() {
      return axios.get(URL_API+"getAlldepartment", { headers: authHeader() });
    },

    listEmployees() {
      return axios.get(URL_API+"employees", { headers: authHeader() });
    },

    updateEmployee(employee, id) {
      return axios.patch(URL_API+`employees/${id}`, employee, {
        headers: authHeader(),
      });
    },

    deleteEmployee(uuid){
      return axios.delete(URL_API+`employees/${uuid}`, {
        headers: authHeader(),
      });
    },

    listAdminUser(){
      return axios.get(URL_API+`listAdminUser`, {
        headers: authHeader(),
      });
    },
    getAllEmployees(){
      return axios.get(URL_API+`getAllEmployees`, {
        headers: authHeader(),
      });
    },

    createUserSystem(data){
      return axios.post(URL_API+`users`, data, {
        headers: authHeader(),
      });
    },
    deleteUserSystem(id){
      return axios.delete(URL_API+`users/${id}`, {
        headers: authHeader(),
      });
    },

    updateUserSystem(data){
      return axios.patch(URL_API+`users/${data.userId}`, data, {
        headers: authHeader(),
      });
    },

    updateDataAccess(data){
      return axios.post(URL_API+`users/updateDataAccesss`, data, {
        headers: authHeader(),
      });
    }

  },
};
