<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Todas Disciplinas</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Disciplinas</li>
          <li class="breadcrumb-item active">Lista Disciplinas</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Gerenciamento de Permissoes</div>
        </div>
        <div class="card-body">
          <form @submit.prevent="senData">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione a função*</label>
                  <select class="form-control" v-model="userData.permission_id">
                    <option value="0">Selecione a função</option>
                    <option
                      :value="role.id"
                      v-for="role in roles"
                      :key="role.id"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label class="form-label">Selecione a permissoes*</label>
                  <Select
                    class="text-white"
                    multiple
                    v-model="userData.permissions"
                    placeholder="Selecione as permissoes"
                  >
                    <Option
                      :value="perm.id"
                      v-for="perm in permissions"
                      :key="perm.id"
                    >
                      {{ perm.name }}
                    </Option>
                  </Select>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <button
                type="button"
                v-on:click="handleSubmitButton"
                class="btn btn-primary float-right"
              >
                {{ loading == false ? "Adicionar" : "Adicionando..." }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onUnmounted } from "vue";
import permissionStoreModule from "./permissionStoreModule";
import store from "@/store";
import toastr from "toastr";

export default {
  setup() {
    const PERMISSOES_MODULE_NAME = "Permissoes";

    if (!store.hasModule(PERMISSOES_MODULE_NAME)) {
      store.registerModule(PERMISSOES_MODULE_NAME, permissionStoreModule);
    }

    onUnmounted(() => {
      if (store.hasModule(PERMISSOES_MODULE_NAME)) {
        store.unregisterModule(PERMISSOES_MODULE_NAME);
      }
    });

    const userData = {
      permissions: [],
      permission_id: null,
    };
    const permissions = ref([]);
    const roles = ref([]);
    const loading = ref(false);

    function getRoles() {
      store
        .dispatch("Permissoes/fetchRoles")
        .then((response) => {
          roles.value = response.data;
          userData.permission_id = response.data[4].id;

          console.log(response.data);
          console.log(response.data[4].id);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getAllpermissions() {
      const resp = await permissionStoreModule.actions.getAllPermissions();
      permissions.value = resp.data;
      console.log(resp.data);
    }

    async function senData(){

      loading.value = true;

      if(userData.permissions == null || userData.permission_id == null){
        toastr.warning("Por favor informe os campos obrigatorios...")
        return;
      }

      const resp = await permissionStoreModule.createPermission(formData);
      console.log(resp);

    }

    return {
      getRoles,
      userData,
      roles,
      loading,
      permissions,
      getAllpermissions,
      senData
    };
  },

  created() {
    this.getAllpermissions();
    this.getRoles();
  },
};
</script>
