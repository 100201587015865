<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Todas Professores</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Professores</li>
          <li class="breadcrumb-item active">Lista Professores</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="row tab-content">
        <div id="list-view" class="tab-pane fade active show col-lg-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div
                  class="mb-2 col text-center text-sm-left align-items-center"
                >
                  <a
                    href="javascript:void(0)"
                    class="btn mr-1 btn-primary text-center text-sm-left text-white"
                    @click="openModalAddNewClassmate"
                  >
                    <span class="fas fa-plus-circle"></span> Adicionar</a
                  >
                </div>

                <div class="mb-2 col-12 col-sm col-lg-2 align-items-center">
                  <form class=" ">
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Pesquisar..."
                        v-model="searchQuery"
                      />
                      <div class="input-group-append">
                        <button type="submit" class="btn btn-primary">
                          <i class="fa fa-search"></i>
                        </button>

                        <!-- <button type="button" class="btn ml-1 bg-primary">
                          <i class="fa fa-search-plus text-white"></i>
                          <span class="text-white">Busca avançada </span>
                        </button> -->
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- <div class="card-header">
              <div class="row">
                <div class="col-sm">
                  <h4 class="card-title">Todos Professores</h4>
                </div>

                <div class="col-sm-6">
                  <router-link
                    to="/teachers"
                    class="btn btn-primary float-right"
                    >+ Adicionar Professor</router-link
                  >
                </div>
              </div>
            </div> -->
            <div class="card-body">
              <div class="table-responsive">
                <table id="example3" class="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nome</th>
                      <th>Sexo</th>
                      <th>Contacto</th>
                      <th>Email</th>
                      <th>Data Entrada</th>
                      <th class="text-center">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(teach, index) in dataTeachers" :key="teach">
                      <td>{{ index + 1 }}</td>
                      <td>{{ teach.fullName }}</td>
                      <td>{{ teach.gender }}</td>
                      <td>{{ teach.contact }}</td>
                      <td>{{ teach.email }}</td>
                      <td>{{ formatDates(teach.created_at) }}</td>

                      <td
                        aria-colindex="3"
                        role="cell"
                        class="w-140px text-center"
                      >
                        <div class="action-column">
                          <router-link
                            title="Alocar Professor"
                            :to="{
                              name: 'alocate',
                              params: { id_teacher: teach.uuid },
                            }"
                            class="btn btn-sm mr-1 btn-success btn-xs"
                            style="font-size: 13px"
                            ><i class="fas fa-network-wired text-white"></i>
                          </router-link>
                          <a
                            title="Visualizar detalhes"
                            @click="view(index, teach.id)"
                            class="btn btn-sm mr-1 btn-info btn-xs"
                            style="font-size: 13px"
                            ><i class="fa fa-eye text-white"></i>
                          </a>
                          <a
                            title="Editar"
                            @click="edit(index, teach.id)"
                            class="btn btn-sm mr-1 btn-warning btn-xs"
                            style="font-size: 13px"
                            ><i class="fa fa-edit text-white"></i>
                          </a>
                          <a
                            title="Eliminar"
                            @click="confirmBeforeDelete(index, teach.id)"
                            class="btn btn-sm mr-1 btn-danger btn-xs"
                            style="font-size: 13px"
                            ><i class="fa fa-times text-white"></i>
                          </a>
                        </div>
                      </td>

                      <!-- <td>
                          <router-link :to="{name:'alocate', params:{id_teacher:teach.id}}" class="btn btn-sm btn-success">
                           <i class="fas fa-network-wired text-white"></i>
                          </router-link>

                          <router-link
                          :to="{name:'editTeacher', params:{id_teacher:teach.id}}"
                             class="btn btn-sm btn-primary"
                            ><i class="fas fa-edit"></i
                          ></router-link>
                          <a
                            href="javascript:void(0);"
                            class="btn btn-sm btn-danger ml-1"
                            ><i class="fas fa-trash-alt"></i
                          ></a>
                        </td> -->
                    </tr>
                    <tr v-if="dataTeachers.length == 0">
                      <td colspan="8" class="text-center">
                        <span style="font-style: italic"
                          >Ainda nao tem registos</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Page
                  :total="total"
                  :model-value="current_page"
                  :page-size="parseInt(per_page)"
                  @on-change="getAllTeachers"
                  v-if="total > 0"
                  class="float-right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalAddNewDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Adicionar Professor</h5>
        <button
          type="button"
          @click="closeModalDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-3 mb-2 bg-card">
            <img
              src="../../../public/assets/images/userProfile.svg"
              width="133"
              height="133"
              class="rounded-circle"
              alt=""
            />
          </div>
          <div class="col-lg-9">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="">Nome Completo</label>
                  <input
                    v-model="userData.fullName"
                    type="text"
                    class="form-control"
                    @input="onUserTypefullName"
                  />
                </div>
                <p v-if="fullname_message_error" style="color: red">
                  {{ fullname_message_error }}
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-4 col-lg-12">
            <div class="col-lg-6 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Nome do Pai</label>
                <input
                  v-model="userData.fathers_filliation"
                  type="text"
                  class="form-control"
                  @input="onUserTypeFatherName"
                />
              </div>
              <p v-if="father_name_message_error" style="color: red">
                {{ father_name_message_error }}
              </p>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Nome da Mãe</label>
                <input
                  v-model="userData.mothers_filliation"
                  type="text"
                  class="form-control"
                  @input="onUserTypeMotherName"
                />
              </div>
              <p v-if="mother_name_message_error" style="color: red">
                {{ mother_name_message_error }}
              </p>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Genero</label>
                <select
                  @change="changeGender"
                  class="form-control"
                  v-model="userData.gender"
                >
                  <option value="">Genero</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                  <option value="Outro">Outro</option>
                </select>
              </div>
              <p v-if="gender_message_error" style="color: red">
                {{ gender_message_error }}
              </p>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Data de Nasci.:</label>
                <input
                  v-model="userData.birthDate"
                  type="date"
                  class="form-control"
                  @input="onUserTypeBithDate"
                />
              </div>
              <p v-if="birthDate_message_error" style="color: red">
                {{ birthDate_message_error }}
              </p>
            </div>

            <div class="col-lg-4">
              <div class="form-group">
                <label for="">Contacto:</label>
                <input
                  v-model="userData.contacts.contact"
                  type="text"
                  @keypress="numberOnly"
                  pattern="/^[0-9]+$/"
                  maxlength="9"
                  class="form-control"
                  @input="validatePhoneNumber"
                />
              </div>
              <p v-if="phoneNumberError" style="color: red">
                {{ phoneNumberError }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Contacto Alternativo:</label>

                <input
                  type="text"
                  class="form-control"
                  @keypress="numberOnly"
                  pattern="/^[0-9]+$/"
                  maxlength="9"
                  v-model="userData.contacts.alternative_contact"
                  @input="validatePhoneNumberAlternative"
                />
              </div>
              <p v-if="phoneNumberErrorAlternative" style="color: red">
                {{ phoneNumberErrorAlternative }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Email:</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="userData.email"
                  @input="validateEmail"
                />
              </div>
              <p v-if="emailError" style="color: red">{{ emailError }}</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Tem alugma deficiencia?*</label>
                <select
                  class="form-control"
                  v-model="userData.is_deficient"
                  @change="is_deficientChange($event)"
                >
                  <option value="">Selecione</option>
                  <option value="0">Nao</option>
                  <option value="1">Sim</option>
                </select>
              </div>
              <p v-if="isDeficient_message_error" style="color: red">
                {{ isDeficient_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label"
                  >Selecione o tipo de deficiencia</label
                >
                <select
                  class="form-control"
                  v-model="userData.deficient_type"
                  :disabled="deficientTypeDisabled"
                >
                  <option value="" selected>Selecione o tipo</option>
                  <option value="Deficiencia Visual">Deficiencia Visual</option>
                  <option value="Cegueira">Cegueira</option>
                  <option value="Paraplegia">Paraplegia</option>
                  <option value="Paraparesia">Paraparesia</option>
                  <option value="Monoplegia">Monoplegia</option>
                  <option value="Baixa Visao">Baixa visao</option>
                  <option value="HipoMiopia">HipoMiopia</option>
                  <option value="HiperMiopia">HiperMiopia</option>
                  <option value="Surdez Condutiva">Surdez Condutiva</option>
                  <option value="Surdez Sensorioneural">
                    Surdez Sensorioneural
                  </option>
                  <option value="Surdez Mista">Surdez Mista</option>
                  <option value="Surdez Central">Surdez Central</option>
                </select>
              </div>
              <p v-if="deficientType_message_error" style="color: red">
                {{ deficientType_message_error }}
              </p>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="form-group">
              <label class="form-label">Selecione o departamento*</label>
              <Select
                @on-select="OnUserChangeDept"
                v-model="userData.department_id"
                style="border-radius: 16px"
                placeholder="Buscar departamento"
                filterable
                not-found-text="Departamento nao encontrado"
              >
                <Option :value="dep.id" v-for="dep in department" :key="dep.id">
                  {{ dep.dep_name }}
                </Option>
              </Select>
            </div>
            <p v-if="dept_message_error" style="color: red">
              {{ dept_message_error }}
            </p>
          </div>

          <div class="row col-lg-12 mt-4">
            <!-- <Tabs :animated="false"></ -->
            <Tabs :animated="false" class="col-lg-12">
              <TabPane label="Habilitações" name="tab1">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="">Última Escola:*</label>
                      <input
                        @input="onUserTypeLastSchoolFrequency"
                        type="text"
                        v-model="userData.last_school_frequency"
                        class="form-control"
                      />
                    </div>
                    <p v-if="last_school_message_error" style="color: red">
                      {{ last_school_message_error }}
                    </p>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label for="">Área de Formação:*</label>
                      <input
                        @input="onUserTypeTrainingArea"
                        v-model="userData.trainingArea"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <p v-if="trainingArea_message_error" style="color: red">
                      {{ trainingArea_message_error }}
                    </p>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label for="">Ano de Conclusão:*</label>
                      <select
                        @change="onUserTypeLastSchoolYear"
                        class="form-control"
                        v-model="userData.last_school_frequency_year"
                      >
                        <option :value="y" v-for="y in years" :key="y">
                          {{ y }}
                        </option>
                      </select>
                    </div>
                    <p v-if="last_school_year_message_error" style="color: red">
                      {{ last_school_year_message_error }}
                    </p>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label for="">Tem Certificado:*</label>
                      <select
                        class="form-control"
                        @change="onUserTypeHasCertificate"
                        v-model="userData.hasCertificate"
                      >
                        <option value="1">Sim</option>
                        <option value="0">Nao</option>
                      </select>
                    </div>
                    <p v-if="hasCertificate_message_error" style="color: red">
                      {{ hasCertificate_message_error }}
                    </p>
                  </div>
                </div>
              </TabPane>

              <TabPane label="Endereço" name="tab2">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">País</label>
                      <select
                        @change="changeContry"
                        v-model="userData.nactionality"
                        class="form-control"
                      >
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option
                          value="Macedonia, The Former Yugoslav Republic of"
                        >
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Moçambique" selected>Moçambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option
                          value="South Georgia and The South Sandwich Islands"
                        >
                          South Georgia and The South Sandwich Islands
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                    <p v-if="country_message_error" style="color: red">
                      {{ country_message_error }}
                    </p>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">Provincia</label>
                      <select
                        class="form-control"
                        v-model="userData.province_id"
                        @change="ChangeProvince"
                        :disabled="enableProviceAndDistrict"
                      >
                        <option value="Class">Selecione o Provincia</option>
                        <option
                          :value="p.id"
                          v-for="(p, index) in provinces"
                          :key="index"
                        >
                          {{ p.name }}
                        </option>
                      </select>
                    </div>
                    <p v-if="province_message_error" style="color: red">
                      {{ province_message_error }}
                    </p>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">Distrito</label>
                      <select
                        class="form-control"
                        v-model="userData.district_id"
                        @change="OnUserChangeDistrict"
                        :disabled="enableProviceAndDistrict"
                      >
                        <option value="Class">Selecione o Distrito</option>
                        <option
                          :value="d.id"
                          v-for="(d, index) in districtData"
                          :key="index"
                        >
                          {{ d.name }}
                        </option>
                      </select>
                    </div>
                    <p v-if="district_message_error" style="color: red">
                      {{ district_message_error }}
                    </p>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">Bairro/Avenida</label>
                      <input
                        @input="onUserTypeAddressLocation"
                        v-model="userData.address_location"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <p v-if="address_message_error" style="color: red">
                      {{ address_message_error }}
                    </p>
                  </div>
                  <div class="col-lg-1">
                    <div class="form-group">
                      <label for="">Quart.:</label>
                      <input
                        v-model="userData.block"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <div class="form-group">
                      <label for="">Nc</label>
                      <input
                        v-model="userData.house_number"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane label="Documentos de Identificação" name="tab3">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="">Tipo Doc.Ident.:</label>
                      <select
                        @change="onUserTypeDocument"
                        class="form-control"
                        v-model="userData.doc_type"
                      >
                        <option value="">Selecione o tipo documento</option>
                        <option value="Bilhete de Identidade">
                          Bilhete de Identidade
                        </option>
                      </select>
                    </div>
                    <p v-if="doc_message_error" style="color: red">
                      {{ doc_message_error }}
                    </p>
                  </div>

                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="">N do Documento:</label>
                      <input
                        v-model="userData.doc_number"
                        type="text"
                        class="form-control"
                        maxlength="13"
                        @input="validateIdentificationNumber"
                      />
                    </div>
                    <p v-if="doc_number_message_error" style="color: red">
                      {{ doc_number_message_error }}
                    </p>
                  </div>
                </div>
              </TabPane>
              <!-- Vai servir para criar uma outra tab de alocacao do professor -->
            </Tabs>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <Button
          type="success"
          @click="addNewRow"
          data-dismiss="modal"
          :loading="loadingServerStundent"
        >
          Adicionar
        </Button>

        <Button
          type="error"
          @click="closeModalDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalAddUpdateDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Actualizar dados</h5>
        <button
          type="button"
          @click="closeModalUpdateDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-3 mb-2 bg-card">
            <img
              src="../../../public/assets/images/userProfile.svg"
              width="133"
              height="133"
              class="rounded-circle"
              alt=""
            />
          </div>
          <div class="col-lg-9">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="">Nome Completo</label>
                  <input
                    v-model="userData.fullName"
                    type="text"
                    class="form-control"
                    @input="onUserTypefullName"
                  />
                </div>
                <p v-if="fullname_message_error" style="color: red">
                  {{ fullname_message_error }}
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-4 col-lg-12">
            <div class="col-lg-6 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Nome do Pai</label>
                <input
                  v-model="userData.fathers_filliation"
                  type="text"
                  class="form-control"
                  @input="onUserTypeFatherName"
                />
              </div>
              <p v-if="father_name_message_error" style="color: red">
                {{ father_name_message_error }}
              </p>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Nome da Mãe</label>
                <input
                  v-model="userData.mothers_filliation"
                  type="text"
                  class="form-control"
                  @input="onUserTypeMotherName"
                />
              </div>
              <p v-if="mother_name_message_error" style="color: red">
                {{ mother_name_message_error }}
              </p>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Genero</label>
                <select
                  @change="changeGender"
                  class="form-control"
                  v-model="userData.gender"
                >
                  <option value="">Genero</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                  <option value="Outro">Outro</option>
                </select>
              </div>
              <p v-if="gender_message_error" style="color: red">
                {{ gender_message_error }}
              </p>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Data de Nasci.:</label>
                <input
                  @input="onUserTypeBithDate"
                  v-model="userData.birthDate"
                  type="date"
                  class="form-control"
                />
              </div>
              <p v-if="birthDate_message_error" style="color: red">
                {{ birthDate_message_error }}
              </p>
            </div>

            <div class="col-lg-4">
              <div class="form-group">
                <label for="">Contacto:</label>
                <input
                  v-model="userData.contacts.contact"
                  type="text"
                  @keypress="numberOnly"
                  pattern="/^[0-9]+$/"
                  maxlength="9"
                  class="form-control"
                  @input="validatePhoneNumber"
                />
              </div>
              <p v-if="phoneNumberError" style="color: red">
                {{ phoneNumberError }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Contacto Alternativo:</label>

                <input
                  type="text"
                  class="form-control"
                  @keypress="numberOnly"
                  pattern="/^[0-9]+$/"
                  maxlength="9"
                  v-model="userData.contacts.alternative_contact"
                  @input="validatePhoneNumberAlternative"
                />
              </div>
              <p v-if="phoneNumberErrorAlternative" style="color: red">
                {{ phoneNumberErrorAlternative }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Email:</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="userData.email"
                  @input="validateEmail"
                />
              </div>
              <p v-if="emailError" style="color: red">{{ emailError }}</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Tem alugma deficiencia?*</label>
                <select
                  class="form-control"
                  v-model="userData.is_deficient"
                  @change="is_deficientChange($event)"
                >
                  <option value="">Selecione</option>
                  <option value="0">Nao</option>
                  <option value="1">Sim</option>
                </select>
              </div>
              <p v-if="isDeficient_message_error" style="color: red">
                {{ isDeficient_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label"
                  >Selecione o tipo de deficiencia</label
                >
                <select
                  class="form-control"
                  v-model="userData.deficient_type"
                  :disabled="deficientTypeDisabled"
                >
                  <option value="" selected>Selecione o tipo</option>
                  <option value="Deficiencia Visual">Deficiencia Visual</option>
                  <option value="Cegueira">Cegueira</option>
                  <option value="Paraplegia">Paraplegia</option>
                  <option value="Paraparesia">Paraparesia</option>
                  <option value="Monoplegia">Monoplegia</option>
                  <option value="Baixa Visao">Baixa visao</option>
                  <option value="HipoMiopia">HipoMiopia</option>
                  <option value="HiperMiopia">HiperMiopia</option>
                  <option value="Surdez Condutiva">Surdez Condutiva</option>
                  <option value="Surdez Sensorioneural">
                    Surdez Sensorioneural
                  </option>
                  <option value="Surdez Mista">Surdez Mista</option>
                  <option value="Surdez Central">Surdez Central</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="form-group">
              <label class="form-label">Selecione o departamento*</label>
              <Select
                @on-select="OnUserChangeDept"
                v-model="userData.department_id"
                style="border-radius: 16px"
                placeholder="Buscar departamento"
                filterable
                not-found-text="Departamento nao encontrado"
              >
                <Option :value="dep.id" v-for="dep in department" :key="dep.id">
                  {{ dep.dep_name }}
                </Option>
              </Select>
            </div>
            <p v-if="dept_message_error" style="color: red">
              {{ dept_message_error }}
            </p>
          </div>

          <div class="row col-lg-12 mt-4">
            <!-- <Tabs :animated="false"></ -->
            <Tabs :animated="false" class="col-lg-12">
              <TabPane label="Habilitações">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="">Última Escola:*</label>
                      <input
                        @input="onUserTypeLastSchoolFrequency"
                        type="text"
                        v-model="userData.last_school_frequency"
                        class="form-control"
                      />
                    </div>
                    <p v-if="last_school_message_error" style="color: red">
                      {{ last_school_message_error }}
                    </p>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label for="">Área de Formação:*</label>
                      <input
                        v-model="userData.trainingArea"
                        @input="onUserTypeTrainingArea"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <p v-if="trainingArea_message_error" style="color: red">
                      {{ trainingArea_message_error }}
                    </p>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label for="">Ano de Conclusão:*</label>
                      <select
                        @change="onUserTypeLastSchoolYear"
                        class="form-control"
                        v-model="userData.last_school_frequency_year"
                      >
                        <option :value="y" v-for="y in years" :key="y">
                          {{ y }}
                        </option>
                      </select>
                    </div>
                    <p v-if="last_school_year_message_error" style="color: red">
                      {{ last_school_year_message_error }}
                    </p>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label for="">Tem Certificado:*</label>
                      <select
                        @input="onUserTypeHasCertificate"
                        class="form-control"
                        v-model="userData.hasCertificate"
                      >
                        <option value="1">Sim</option>
                        <option value="0">Nao</option>
                      </select>
                    </div>
                    <p v-if="hasCertificate_message_error" style="color: red">
                      {{ hasCertificate_message_error }}
                    </p>
                  </div>
                </div>
              </TabPane>

              <TabPane label="Endereço">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">País</label>
                      <select
                        @change="onUserTypeCountry"
                        v-model="userData.nactionality"
                        class="form-control"
                      >
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option
                          value="Macedonia, The Former Yugoslav Republic of"
                        >
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Moçambique" selected>Moçambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option
                          value="South Georgia and The South Sandwich Islands"
                        >
                          South Georgia and The South Sandwich Islands
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                    <p v-if="country_message_error" style="color: red">
                      {{ country_message_error }}
                    </p>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">Provincia</label>
                      <select
                        class="form-control"
                        v-model="userData.province_id"
                        @change="ChangeProvince"
                        :disabled="enableProviceAndDistrict"
                      >
                        <option value="Class">Selecione o Provincia</option>
                        <option
                          :value="p.id"
                          v-for="(p, index) in provinces"
                          :key="index"
                        >
                          {{ p.name }}
                        </option>
                      </select>
                    </div>
                    <p v-if="province_message_error" style="color: red">
                      {{ province_message_error }}
                    </p>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">Distrito</label>
                      <select
                        @change="OnUserChangeDistrict"
                        class="form-control"
                        v-model="userData.district_id"
                        :disabled="enableProviceAndDistrict"
                      >
                        <option value="Class">Selecione o Distrito</option>
                        <option
                          :value="d.id"
                          v-for="(d, index) in districtData"
                          :key="index"
                        >
                          {{ d.name }}
                        </option>
                      </select>
                    </div>
                    <p v-if="district_message_error" style="color: red">
                      {{ district_message_error }}
                    </p>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">Bairro/Avenida</label>
                      <input
                        @input="onUserTypeAddressLocation"
                        v-model="userData.address_location"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <p v-if="address_message_error" style="color: red">
                      {{ address_message_error }}
                    </p>
                  </div>
                  <div class="col-lg-1">
                    <div class="form-group">
                      <label for="">Quart.:</label>
                      <input
                        v-model="userData.block"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <div class="form-group">
                      <label for="">Nc</label>
                      <input
                        v-model="userData.house_number"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane label="Documentos de Identificação">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="">Tipo Doc.Ident.:</label>
                      <select
                        @change="onUserTypeDocument"
                        class="form-control"
                        v-model="userData.doc_type"
                      >
                        <option value="">Selecione o tipo documento</option>
                        <option value="Bilhete de Identidade">
                          Bilhete de Identidade
                        </option>
                      </select>
                    </div>
                    <p v-if="doc_message_error" style="color: red">
                      {{ doc_message_error }}
                    </p>
                  </div>

                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="">N do Documento:</label>
                      <input
                        v-model="userData.doc_number"
                        type="text"
                        class="form-control"
                        maxlength="13"
                        @input="validateIdentificationNumber"
                      />
                    </div>
                    <p v-if="doc_number_message_error" style="color: red">
                      {{ doc_number_message_error }}
                    </p>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <Button
          type="success"
          @click="updateRow"
          data-dismiss="modal"
          :loading="loadingServerStundent"
        >
          Actualizar
        </Button>

        <Button
          type="error"
          @click="closeModalUpdateDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalViewDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Visualizar Detalhes</h5>
        <button
          type="button"
          @click="closeModalViewteDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-3 mb-2 bg-card">
            <img
              src="../../../public/assets/images/userProfile.svg"
              width="133"
              height="133"
              class="rounded-circle"
              alt=""
            />
          </div>
          <div class="col-lg-9">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="">Nome Completo</label>
                  <input
                    :disabled="disableInput"
                    v-model="userData.fullName"
                    type="text"
                    class="form-control"
                  />
                </div>
                <p v-if="fullname_message_error" style="color: red">
                  {{ fullname_message_error }}
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-4 col-lg-12">
            <div class="col-lg-6 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Nome do Pai</label>
                <input
                  :disabled="disableInput"
                  v-model="userData.fathers_filliation"
                  type="text"
                  class="form-control"
                />
              </div>
              <p v-if="father_name_message_error" style="color: red">
                {{ father_name_message_error }}
              </p>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Nome da Mãe</label>
                <input
                  :disabled="disableInput"
                  v-model="userData.mothers_filliation"
                  type="text"
                  class="form-control"
                />
              </div>
              <p v-if="mother_name_message_error" style="color: red">
                {{ mother_name_message_error }}
              </p>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Genero</label>
                <select
                  :disabled="disableInput"
                  class="form-control"
                  v-model="userData.gender"
                >
                  <option value="">Genero</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                </select>
              </div>
              <p v-if="gender_message_error" style="color: red">
                {{ gender_message_error }}
              </p>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Data de Nasci.:</label>
                <input
                  :disabled="disableInput"
                  v-model="userData.birthDate"
                  type="date"
                  class="form-control"
                />
              </div>
              <p v-if="birthDate_message_error" style="color: red">
                {{ birthDate_message_error }}
              </p>
            </div>

            <div class="col-lg-4">
              <div class="form-group">
                <label for="">Contacto:</label>
                <input
                  :disabled="disableInput"
                  v-model="userData.contacts.contact"
                  type="text"
                  @keypress="numberOnly"
                  pattern="/^[0-9]+$/"
                  maxlength="9"
                  class="form-control"
                  @input="validatePhoneNumber"
                />
              </div>
              <p v-if="phoneNumberError" style="color: red">
                {{ phoneNumberError }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Contacto Alternativo:</label>

                <input
                  :disabled="disableInput"
                  type="text"
                  class="form-control"
                  @keypress="numberOnly"
                  pattern="/^[0-9]+$/"
                  maxlength="9"
                  v-model="userData.contacts.alternative_contact"
                  @input="validatePhoneNumberAlternative"
                />
              </div>
              <p v-if="phoneNumberErrorAlternative" style="color: red">
                {{ phoneNumberErrorAlternative }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label for="">Email:</label>
                <input
                  :disabled="disableInput"
                  type="email"
                  class="form-control"
                  v-model="userData.email"
                  @input="validateEmail"
                />
              </div>
              <p v-if="emailError" style="color: red">{{ emailError }}</p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label">Tem alugma deficiencia?*</label>
                <select
                  :disabled="disableInput"
                  class="form-control"
                  v-model="userData.is_deficient"
                  @change="is_deficientChange($event)"
                >
                  <option value="">Selecione</option>
                  <option value="0">Nao</option>
                  <option value="1">Sim</option>
                </select>
              </div>
              <p v-if="isDeficient_message_error" style="color: red">
                {{ isDeficient_message_error }}
              </p>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <div class="form-group">
                <label class="form-label"
                  >Selecione o tipo de deficiencia</label
                >
                <select
                  class="form-control"
                  v-model="userData.deficient_type"
                  :disabled="deficientTypeDisabled"
                >
                  <option value="" selected>Selecione o tipo</option>
                  <option value="Deficiencia Visual">Deficiencia Visual</option>
                  <option value="Cegueira">Cegueira</option>
                  <option value="Paraplegia">Paraplegia</option>
                  <option value="Paraparesia">Paraparesia</option>
                  <option value="Monoplegia">Monoplegia</option>
                  <option value="Baixa Visao">Baixa visao</option>
                  <option value="HipoMiopia">HipoMiopia</option>
                  <option value="HiperMiopia">HiperMiopia</option>
                  <option value="Surdez Condutiva">Surdez Condutiva</option>
                  <option value="Surdez Sensorioneural">
                    Surdez Sensorioneural
                  </option>
                  <option value="Surdez Mista">Surdez Mista</option>
                  <option value="Surdez Central">Surdez Central</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="form-group">
              <label class="form-label">Selecione o departamento*</label>
              <Select
                :disabled="disableInput"
                v-model="userData.department_id"
                placeholder="Selecione o Departmanto"
              >
                <Option :value="dep.id" v-for="dep in department" :key="dep.id">
                  {{ dep.dep_name }}
                </Option>
              </Select>
            </div>
          </div>

          <div class="row col-lg-12 mt-4">
            <!-- <Tabs :animated="false"></ -->
            <Tabs :animated="false" class="col-lg-12">
              <TabPane label="Habilitações">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="">Última Escola:*</label>
                      <input
                        :disabled="disableInput"
                        type="text"
                        v-model="userData.last_school_frequency"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label for="">Área de Formação:*</label>
                      <input
                        :disabled="disableInput"
                        v-model="userData.trainingArea"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label for="">Ano de Conclusão:*</label>
                      <select
                        :disabled="disableInput"
                        class="form-control"
                        v-model="userData.last_school_frequency_year"
                      >
                        <option :value="y" v-for="y in years" :key="y">
                          {{ y }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <div class="form-group">
                      <label for="">Tem Certificado:*</label>
                      <select
                        @change="onUserTypeHasCertificate"
                        :disabled="disableInput"
                        class="form-control"
                        v-model="userData.hasCertificate"
                      >
                        <option value="1">Sim</option>
                        <option value="0">Nao</option>
                      </select>
                    </div>
                    <p v-if="hasCertificate_message_error" style="color: red">
                      {{ hasCertificate_message_error }}
                    </p>
                  </div>
                </div>
              </TabPane>

              <TabPane label="Endereço">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">País</label>
                      <select
                        @change="changeContry"
                        :disabled="disableInput"
                        v-model="userData.nactionality"
                        class="form-control"
                      >
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">
                          Christmas Island
                        </option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option
                          value="Macedonia, The Former Yugoslav Republic of"
                        >
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Moçambique" selected>Moçambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">
                          Netherlands Antilles
                        </option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option
                          value="South Georgia and The South Sandwich Islands"
                        >
                          South Georgia and The South Sandwich Islands
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">
                          United States Minor Outlying Islands
                        </option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Wallis and Futuna">
                          Wallis and Futuna
                        </option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                    <p v-if="country_message_error" style="color: red">
                      {{ country_message_error }}
                    </p>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">Provincia</label>
                      <select
                        :disabled="disableInput"
                        class="form-control"
                        v-model="userData.province_id"
                        @change="ChangeProvince"
                      >
                        <option value="Class">Selecione o Provincia</option>
                        <option
                          :value="p.id"
                          v-for="(p, index) in provinces"
                          :key="index"
                        >
                          {{ p.name }}
                        </option>
                      </select>
                    </div>
                    <p v-if="province_message_error" style="color: red">
                      {{ province_message_error }}
                    </p>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">Distrito</label>
                      <select
                        :disabled="disableInput"
                        class="form-control"
                        v-model="userData.district_id"
                      >
                        <option value="Class">Selecione o Distrito</option>
                        <option
                          :value="d.id"
                          v-for="(d, index) in districtData"
                          :key="index"
                        >
                          {{ d.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label for="">Bairro</label>
                      <input
                        :disabled="disableInput"
                        v-model="userData.address_location"
                        type="text"
                        class="form-control"
                      />
                    </div>
                    <p v-if="address_message_error" style="color: red">
                      {{ address_message_error }}
                    </p>
                  </div>
                  <div class="col-lg-1">
                    <div class="form-group">
                      <label for="">Quart.:</label>
                      <input
                        :disabled="disableInput"
                        v-model="userData.block"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-lg-1">
                    <div class="form-group">
                      <label for="">Nc</label>
                      <input
                        :disabled="disableInput"
                        v-model="userData.house_number"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane label="Documentos de Identificação">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="">Tipo Doc.Ident.:</label>
                      <select
                        :disabled="disableInput"
                        class="form-control"
                        v-model="userData.doc_type"
                      >
                        <option value="">Selecione o tipo documento</option>
                        <option value="Bilhete de Identidade">
                          Bilhete de Identidade
                        </option>
                      </select>
                    </div>
                    <p v-if="doc_message_error" style="color: red">
                      {{ doc_message_error }}
                    </p>
                  </div>

                  <div class="col-lg-3">
                    <div class="form-group">
                      <label for="">N do Documento:</label>
                      <input
                        :disabled="disableInput"
                        v-model="userData.doc_number"
                        type="text"
                        class="form-control"
                        maxlength="13"
                        @input="validateIdentificationNumber"
                      />
                    </div>
                    <p v-if="doc_number_message_error" style="color: red">
                      {{ doc_number_message_error }}
                    </p>
                  </div>
                </div>
              </TabPane>
              <!-- 
              <TabPane label="Alocar a Turma">
                <div class="row">
                  <div class="row mt-4 col-lg-12">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Selecione o Curso*</label>
                        <select
                          :disabled="disableInput"
                          class="form-control"
                          v-model="userData.course_id"
                        >
                          <option value="Class">Selecione o Curso</option>
                          <option
                            :value="c.id"
                            v-for="(c, index) in courseData"
                            :key="index"
                          >
                            {{ c.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Selecione Nivel*</label>
                        <select
                          :disabled="disableInput"
                          class="form-control"
                          v-model="userData.level_id"
                        >
                          <option value="Class">Selecione o Curso</option>
                          <option
                            :value="l.id"
                            v-for="(l, index) in levels"
                            :key="index"
                          >
                            {{ l.name }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="form-group">
                        <label class="form-label">Semestre*</label>
                        <select
                          :disabled="disableInput"
                          class="form-control"
                          v-model="userData.semester_id"
                        >
                          <option value="Class">Selecione o semestre</option>
                          <option
                            :value="s.id"
                            v-for="(s, index) in semesters"
                            :key="index"
                          >
                            {{ s.semester_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane> -->
            </Tabs>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <Button
          type="error"
          @click="closeModalViewteDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Fechar
        </Button>
      </div>
    </div>
  </div>
  <Loading :loadingData="loadingServerStundent"></Loading>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import TeacherStoreModule from "./TeacherStoreModule";
import Loading from "../../components/Loading.vue";
import toastr from "toastr";
import axios from "axios";
import StudentModuleService from "../students/StudentModuleService";
import DepartmentStoreModule from "../department/DepartmentStoreModule";
import Swal from "sweetalert2";
import { formatDates } from "../../modules/customers.js";

const isModalAddNewDept = ref(false);
const isModalAddUpdateDept = ref(false);
const isModalViewDept = ref(false);
const years = ref([]);

const total = ref(null);
const perPage = ref(10);
const current_page = ref();
const searchQuery = ref("");
const enableProviceAndDistrict = ref(false);

const dataTeachers = ref([]);
const userData = ref({
  id: "",
  person_id: null,
  fullName: "",
  address_location: "",
  email: "",
  gender: "",
  doc_type: "",
  doc_number: "",
  nactionality: "Moçambique",
  birthDate: "",
  last_school_frequency: "",
  level_type: "",
  last_school_frequency_year: "",
  fathers_filliation: "",
  mothers_filliation: "",
  childhood_location: "",
  is_deficient: "",
  deficient_type: "",
  permissions: "",
  province_id: "",
  district_id: "",
  role: "",
  regimeSuperior: "",
  department_id: 0,
  childhood_location: "",
  contacts: {
    contact: "",
    alternative_contact: "",
  },
  trainingArea: "",
  hasCertificate: "",
  house_number: "",
  block: "",
});

const disableInput = true;
const emailError = ref("");
const phoneNumberError = ref("");
const phoneNumberErrorAlternative = ref("");
const deficientType_message_error = ref("");
const birthDate_message_error = ref("");
const district_message_error = ref("");
const address_message_error = ref("");
const doc_message_error = ref("");
const gender_message_error = ref("");
const isDeficient_message_error = ref("");
const father_name_message_error = ref("");
const mother_name_message_error = ref("");
const country_message_error = ref("");
const province_message_error = ref("");
const fullname_message_error = ref("");
const nationality_message_error = ref("");
const endereco_message_error = ref("");
const number_message_error = ref("");
const doc_number_message_error = ref("");
const last_school_message_error = ref("");
const last_school_year_message_error = ref("");
const trainingArea_message_error = ref("");
const hasCertificate_message_error = ref("");
const classemate_message_error = ref("");
const dateEnd_message_error = ref("");
const emissionLocation_message_error = ref("");
const dept_message_error = ref("");

const department = ref([]);
const loadingServerStundent = ref(false);

const provinces = ref([]);
const province_id = ref();
const districtData = ref([]);
const deficientTypeDisabled = ref(true);

const URL_API = process.env.VUE_APP_URL_API;

watch(searchQuery, (newValue, oldValue) => {
  if (newValue.length >= 4 || oldValue.length >= 4) {
    fetchTeacher();
  } else if (newValue.length == 0 || oldValue.length == 0) {
    fetchTeacher();
  }
});

function view(index, id) {
  // userData.value = dataTeachers.value[index];
  // console.log(dataTeachers.value[index]);
  getDisitrictbyId(dataTeachers.value[index].province_id);
  userData.value.id = id;
  userData.value.person_id = dataTeachers.value[index].person_id;
  userData.value.fullName = dataTeachers.value[index].fullName;
  userData.value.address_location = dataTeachers.value[index].address_location;
  userData.value.email = dataTeachers.value[index].email;
  userData.value.gender = dataTeachers.value[index].gender;
  userData.value.doc_type = dataTeachers.value[index].doc_type;
  userData.value.doc_number = dataTeachers.value[index].doc_number;
  userData.value.nactionality = dataTeachers.value[index].nactionality;
  userData.value.birthDate = dataTeachers.value[index].birthDate;
  userData.value.last_school_frequency =
    dataTeachers.value[index].last_school_frequency;
  userData.value.last_school_frequency_year =
    dataTeachers.value[index].last_school_frequency_year;
  userData.value.fathers_filliation =
    dataTeachers.value[index].fathers_filliation;
  userData.value.mothers_filliation =
    dataTeachers.value[index].mothers_filliation;
  userData.value.is_deficient = dataTeachers.value[index].is_deficient;
  userData.value.deficient_type = dataTeachers.value[index].deficient_type;
  userData.value.province_id = dataTeachers.value[index].province_id;
  userData.value.district_id = dataTeachers.value[index].district_id;
  userData.value.department_id = dataTeachers.value[index].department_id;
  userData.value.childhood_location =
    dataTeachers.value[index].childhood_location;
  userData.value.contacts.contact = dataTeachers.value[index].contact;
  userData.value.contacts.alternative_contact =
    dataTeachers.value[index].alternative_contact;
  userData.value.trainingArea = dataTeachers.value[index].trainingArea;
  userData.value.hasCertificate = dataTeachers.value[index].hasCertificate;
  userData.value.house_number = dataTeachers.value[index].house_number;
  userData.value.block = dataTeachers.value[index].block;

  isModalViewDept.value = true;
}

function edit(index, id) {
  getDisitrictbyId(dataTeachers.value[index].province_id);
  userData.value.id = id;
  userData.value.person_id = dataTeachers.value[index].person_id;
  userData.value.fullName = dataTeachers.value[index].fullName;
  userData.value.address_location = dataTeachers.value[index].address_location;
  userData.value.email = dataTeachers.value[index].email;
  userData.value.gender = dataTeachers.value[index].gender;
  userData.value.doc_type = dataTeachers.value[index].doc_type;
  userData.value.doc_number = dataTeachers.value[index].doc_number;
  userData.value.nactionality = dataTeachers.value[index].nactionality;
  userData.value.birthDate = dataTeachers.value[index].birthDate;
  userData.value.last_school_frequency =
    dataTeachers.value[index].last_school_frequency;
  userData.value.last_school_frequency_year =
    dataTeachers.value[index].last_school_frequency_year;
  userData.value.fathers_filliation =
    dataTeachers.value[index].fathers_filliation;
  userData.value.mothers_filliation =
    dataTeachers.value[index].mothers_filliation;
  userData.value.is_deficient = dataTeachers.value[index].is_deficient;
  userData.value.deficient_type = dataTeachers.value[index].deficient_type;
  userData.value.province_id = dataTeachers.value[index].province_id;
  userData.value.district_id = dataTeachers.value[index].district_id;
  userData.value.department_id = dataTeachers.value[index].department_id;
  userData.value.childhood_location =
    dataTeachers.value[index].childhood_location;
  userData.value.contacts.contact = dataTeachers.value[index].contact;
  userData.value.contacts.alternative_contact =
    dataTeachers.value[index].alternative_contact;
  userData.value.trainingArea = dataTeachers.value[index].trainingArea;
  userData.value.hasCertificate = dataTeachers.value[index].hasCertificate;
  userData.value.house_number = dataTeachers.value[index].house_number;
  userData.value.block = dataTeachers.value[index].block;

  isModalAddUpdateDept.value = true;
}

//  update row

async function updateRow() {
  try {
    loadingServerStundent.value = true;
    if (userData.value.fullName == "") {
      loadingServerStundent.value = false;
      fullname_message_error.value =
        "O campo nome completo nao pode estar vazio!";
      return;
    }

    if (userData.value.fathers_filliation == "") {
      loadingServerStundent.value = false;
      father_name_message_error.value =
        "O campo nome do pai nao pode estar vazio!";
      return;
    }

    if (userData.value.mothers_filliation == "") {
      loadingServerStundent.value = false;
      mother_name_message_error.value =
        "O campo nome da mae nao pode estar vazio!";
      return;
    }

    if (userData.value.gender == "") {
      loadingServerStundent.value = false;
      gender_message_error.value = "O campo genero nao pode estar vazio!";
      return;
    }

    if (userData.value.birthDate == "") {
      loadingServerStundent.value = false;
      birthDate_message_error.value =
        "O campo data de Nascimento nao pode estar vazio!";
      return;
    }

    if (userData.value.contacts.contact == "") {
      loadingServerStundent.value = false;
      phoneNumberError.value = "O campo contacto nao pode estar vazio!";
      return;
    }

    if (userData.value.email == "") {
      loadingServerStundent.value = false;
      emailError.value = "O campo email nao pode estar vazio!";
      return;
    }

    if (userData.value.is_deficient != 0 && userData.value.is_deficient != 1) {
      loadingServerStundent.value = false;
      isDeficient_message_error.value =
        "O campo deficiencia nao pode estar vazio!";
      return;
    }
    if (
      userData.value.department_id == "" ||
      userData.value.department_id == null
    ) {
      loadingServerStundent.value = false;

      return (dept_message_error.value =
        "O campo departamento nao pode estar vazio!");
    }

    if (userData.value.last_school_frequency == "") {
      loadingServerStundent.value = false;
      last_school_message_error.value =
        "O campo ultima escola nao pode estar vazio!";
      return;
    }

    if (userData.value.last_school_frequency_year == "") {
      loadingServerStundent.value = false;
      last_school_year_message_error.value =
        "O campo ano de termino nao pode estar vazio!";
      return;
    }

    if (userData.value.nactionality == "") {
      loadingServerStundent.value = false;
      country_message_error.value =
        "O campo nacionalidade nao pode estar vazio!";
      return;
    }

    if (userData.value.nactionality == "Moçambique") {
      if (userData.value.province_id == "") {
        loadingServerStundent.value = false;
        province_message_error.value =
          "O campo provincia nao pode estar vazio!";
        return;
      }

      if (userData.value.district_id == "") {
        loadingServerStundent.value = false;
        district_message_error.value =
          "O campo districto nao pode estar vazio!";
        return;
      }
    }

    if (userData.value.address_location == "") {
      loadingServerStundent.value = false;
      address_message_error.value = "O campo bairro nao estar vazio!";
      return;
    }

    if (userData.value.doc_type == "") {
      loadingServerStundent.value = false;
      doc_message_error.value = "O campo tipo de bilhete nao estar vazio!";
      return;
    }

    if (userData.value.doc_number == "") {
      loadingServerStundent.value = false;
      number_message_error.value =
        "O campo numero do documento nao pode estar vazio!";
      return;
    }

    if (userData.value.trainingArea == "") {
      loadingServerStundent.value = false;
      trainingArea_message_error.value =
        "O campo area formacao nao pode estar vazio!";
      return;
    }

    if (userData.value.hasCertificate == "") {
      loadingServerStundent.value = false;
      hasCertificate_message_error.value =
        "O campo certificado nao pode estar vazio!";
      return;
    }

    const resp = await TeacherStoreModule.actions.updateTeacher(userData.value);
    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      let index = dataTeachers.value.findIndex(
        (item) => item.id === userData.value.id
      );

      dataTeachers.value[index].id = userData.value.id;
      dataTeachers.value[index].person_id = userData.value.person_id;
      dataTeachers.value[index].fullName = userData.value.fullName;
      dataTeachers.value[index].address_location =
        userData.value.address_location;
      dataTeachers.value[index].email = userData.value.email;
      dataTeachers.value[index].gender = userData.value.gender;
      dataTeachers.value[index].doc_type = userData.value.doc_type;
      dataTeachers.value[index].doc_number = userData.value.doc_number;
      dataTeachers.value[index].nactionality = userData.value.nactionality;
      dataTeachers.value[index].birthDate = userData.value.birthDate;
      dataTeachers.value[index].last_school_frequency =
        userData.value.last_school_frequency;
      dataTeachers.value[index].last_school_frequency_year =
        userData.value.last_school_frequency_year;
      dataTeachers.value[index].fathers_filliation =
        userData.value.fathers_filliation;
      dataTeachers.value[index].mothers_filliation =
        userData.value.mothers_filliation;
      dataTeachers.value[index].is_deficient = userData.value.is_deficient;
      dataTeachers.value[index].deficient_type = userData.value.deficient_type;
      dataTeachers.value[index].province_id = userData.value.province_id;
      dataTeachers.value[index].district_id = userData.value.district_id;
      dataTeachers.value[index].department_id = userData.value.department_id;
      dataTeachers.value[index].childhood_location =
        userData.value.childhood_location;
      dataTeachers.value[index].contact = userData.value.contacts.contact;
      dataTeachers.value[index].alternative_contact =
        userData.value.contacts.alternative_contact;
      dataTeachers.value[index].trainingArea = userData.value.trainingArea;
      dataTeachers.value[index].hasCertificate = userData.value.hasCertificate;
      dataTeachers.value[index].house_number = userData.value.house_number;
      dataTeachers.value[index].block = userData.value.block;

      isModalAddUpdateDept.value = false;

      clearInputs();
    } else {
      toastr.error(resp.data.message);
    }
  } catch (error) {
    console.log(error);
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loadingServerStundent.value = false;
  }
}

async function deleteRow(index, id) {
  try {
    loadingServerStundent.value = true;
    dataTeachers.value.splice(index, 1);

    const response = await TeacherStoreModule.actions.deleteTeacher(id);
    if (response.data > 0) {
      toastr.success("Registro Eliminado com Sucesso.");
    }
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

function confirmBeforeDelete(index, id) {
  Swal.fire({
    title: "Tem certeza que deseja eliminar este registro?",
    text: "Nao tera como reverter esta operacao!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim, Eliminar!",
    cancelButtonText: "Cancel!",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteRow(index, id);
    }
  });
}
// add new row
async function addNewRow() {
  try {
    loadingServerStundent.value = true;

    if (userData.value.fullName == "") {
      loadingServerStundent.value = false;
      fullname_message_error.value =
        "O campo nome completo nao pode estar vazio!";
      return;
    }

    if (userData.value.fathers_filliation == "") {
      loadingServerStundent.value = false;
      father_name_message_error.value =
        "O campo nome do pai nao pode estar vazio!";
      return;
    }

    if (userData.value.mothers_filliation == "") {
      loadingServerStundent.value = false;
      mother_name_message_error.value =
        "O campo nome da mae nao pode estar vazio!";
      return;
    }

    if (userData.value.gender == "") {
      loadingServerStundent.value = false;
      gender_message_error.value = "O campo genero nao pode estar vazio!";
      return;
    }

    if (userData.value.birthDate == "") {
      loadingServerStundent.value = false;
      birthDate_message_error.value =
        "O campo data de Nascimento nao pode estar vazio!";
      return;
    }

    if (userData.value.contacts.contact == "") {
      loadingServerStundent.value = false;
      phoneNumberError.value = "O campo contacto nao pode estar vazio!";
      return;
    }

    if (userData.value.email == "") {
      loadingServerStundent.value = false;
      emailError.value = "O campo email nao pode estar vazio!";
      return;
    }

    if (userData.value.is_deficient == "") {
      loadingServerStundent.value = false;
      isDeficient_message_error.value =
        "O campo deficiencia nao pode estar vazio!";
    }
    if (
      userData.value.department_id == "" ||
      userData.value.department_id == null
    ) {
      loadingServerStundent.value = false;

      return (dept_message_error.value =
        "O campo departamento nao pode estar vazio!");
    }

    if (userData.value.last_school_frequency == "") {
      loadingServerStundent.value = false;
      last_school_message_error.value =
        "O campo ultima escola nao pode estar vazio!";
      return;
    }

    if (userData.value.last_school_frequency_year == "") {
      loadingServerStundent.value = false;
      last_school_year_message_error.value =
        "O campo ano de termino nao pode estar vazio!";
      return;
    }

    if (userData.value.hasCertificate == "") {
      loadingServerStundent.value = false;
      hasCertificate_message_error.value =
        "O campo certificado nao pode estar vazio!";
      return;
    }

    if (userData.value.nactionality == "") {
      loadingServerStundent.value = false;
      country_message_error.value =
        "O campo nacionalidade nao pode estar vazio!";
      return;
    }

    if (userData.value.nactionality == "Moçambique") {
      if (userData.value.province_id == "") {
        loadingServerStundent.value = false;
        province_message_error.value =
          "O campo provincia nao pode estar vazio!";
        return;
      }

      if (userData.value.district_id == "") {
        loadingServerStundent.value = false;
        district_message_error.value =
          "O campo districto nao pode estar vazio!";
        return;
      }
    }

    if (userData.value.address_location == "") {
      loadingServerStundent.value = false;
      address_message_error.value = "O campo bairro nao estar vazio!";
      return;
    }

    if (userData.value.doc_type == "") {
      loadingServerStundent.value = false;
      doc_message_error.value = "O campo tipo de bilhete nao estar vazio!";
      return;
    }

    if (userData.value.doc_number == "") {
      loadingServerStundent.value = false;
      doc_number_message_error.value =
        "O campo numero do documento nao pode estar vazio!";
      return;
    }

    if (userData.value.trainingArea == "") {
      loadingServerStundent.value = false;
      trainingArea_message_error.value =
        "O campo area formacao nao pode estar vazio!";
      return;
    }

    const resp = await TeacherStoreModule.actions.createTeacher(userData.value);

    if (resp.data.status == true) {
      toastr.success(resp.data.message);

      dataTeachers.value.push({
        id: resp.data.data.teacher_id,
        uuid: resp.data.data.uuid,
        person_id: resp.data.data.person_id,
        fullName: userData.value.fullName,
        address_location: userData.value.address_location,
        email: userData.value.email,
        gender: userData.value.gender,
        doc_type: userData.value.doc_type,
        doc_number: userData.value.doc_number,
        nactionality: userData.value.nactionality,
        birthDate: userData.value.birthDate,
        last_school_frequency: userData.value.last_school_frequency,
        last_school_frequency_year: userData.value.last_school_frequency_year,
        fathers_filliation: userData.value.fathers_filliation,
        mothers_filliation: userData.value.mothers_filliation,
        is_deficient: userData.value.is_deficient,
        deficient_type: userData.value.deficient_type,
        province_id: userData.value.province_id,
        district_id: userData.value.district_id,
        department_id: userData.value.department_id,
        childhood_location: userData.value.childhood_location,
        contact: userData.value.contacts.contact,
        alternative_contact: userData.value.contacts.alternative_contact,
        trainingArea: userData.value.trainingArea,
        hasCertificate: userData.value.hasCertificate,
        house_number: userData.value.house_number,
        block: userData.value.block,
        created_at: resp.data.data.created_at,
      });
      isModalAddNewDept.value = false;

      clearInputs();
    }
  } catch (error) {
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loadingServerStundent.value = false;
  }
}

// clear inputs
function clearInputs() {
  userData.value.fullName = "";
  userData.value.address_location = "";
  userData.value.contacts.alternative_contact = "";
  userData.value.birthDate = "";
  userData.value.childhood_location = "";
  userData.value.contacts.contact = "";
  userData.value.course_id = "";
  userData.value.deficient_type = "";
  userData.value.province_id = "";
  userData.value.district_id = "";
  userData.value.department_id = null;
  userData.value.doc_number = "";
  userData.value.doc_type = "";
  userData.value.email = "";
  userData.value.fathers_filliation = "";
  userData.value.gender = "";
  userData.value.is_deficient = "";
  userData.value.last_school_frequency = "";
  userData.value.last_school_frequency_year = "";
  userData.value.level_type = "";
  userData.value.mothers_filliation = "";
  userData.value.nactionality = "Moçambique";
  userData.value.name = "";
  userData.value.regime = "";
  userData.value.regimeSuperior = "";
  userData.value.trainingArea = "";
  userData.value.hasCertificate = "Nao";
  userData.value.house_number = "";
  userData.value.block = "";
}

function closeModalViewteDept() {
  isModalViewDept.value = false;
  clearInputs();
}

function closeModalDept() {
  isModalAddNewDept.value = false;
  clearInputs();
}
function closeModalUpdateDept() {
  isModalAddUpdateDept.value = false;
  clearInputs();
}

function openModalAddNewClassmate() {
  isModalAddNewDept.value = true;
  clearInputs();
}

async function getAllTeachers(page = 1) {
  try {
    loadingServerStundent.value = true;

    const resp = await TeacherStoreModule.actions.getAllTeachers({
      page: page,
      search: searchQuery.value,
    });
    dataTeachers.value = resp.data.data;
    total.value = resp.data.meta.total;
    perPage.value = resp.data.meta.per_page;
    current_page.value = resp.data.meta.current_page;
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

async function fetchTeacher(page = 1) {
  try {
    loadingServerStundent.value = true;

    const resp = await TeacherStoreModule.actions.fetchTeacher({
      page: page,
      search: searchQuery.value,
    });
    dataTeachers.value = resp.data.data;
    total.value = resp.data.meta.total;
    perPage.value = resp.data.meta.per_page;
    current_page.value = resp.data.meta.current_page;
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

async function getAllDepartment() {
  try {
    loadingServerStundent.value = true;

    const resp = await DepartmentStoreModule.actions.getAlldepartment();

    department.value = resp.data.data;
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

// Responsavel por gerar os anos partindo do ano corrente
function getYearTilCurrent() {
  const currentYear = new Date().getFullYear();

  for (let year = currentYear; year >= 1900; year--) {
    years.value.push(year);
  }
}

// Busca as provincias
async function getProvinces() {
  const resp = await StudentModuleService.actions.getProvinces();
  provinces.value = resp.data;
  console.log(resp.data);
}

// Busca os distritos atraves do id da provincia
async function getDisitrictbyId(province_id) {
  loadingServerStundent.value = true;
  await axios
    .get(URL_API + "district/" + province_id)
    .then((response) => {
      districtData.value = response.data;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      loadingServerStundent.value = false;
    });
}

async function getDisitrict() {
  await axios
    .get(URL_API + "district/")
    .then((response) => {
      districtData.value = response.data;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

// Busca os provincias quando ha alternancia de provincia no select
async function ChangeProvince(evt) {
  province_message_error.value = "";

  await axios
    .get(URL_API + "district/" + evt.target.value)
    .then((response) => {
      districtData.value = response.data;
      console.log(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
}

// Validacao no numero BI
function validateIdentificationNumber() {
  const regex = /^[0-9]{12}[a-zA-Z]$/;

  // Testar a expressão regular
  if (!regex.test(userData.value.doc_number)) {
    doc_number_message_error.value = "Número de identificação inválido!";

    return;
  } else {
    doc_number_message_error.value = "";
  }
}

function validatePhoneNumber() {
  // Expressão regular para validar o número de telefone
  const phoneNumberRegex = /^(86)\d{8}$/;
  var firstTwoDigits = "";
  if (userData.value.contacts.contact.length >= 2) {
    firstTwoDigits = userData.value.contacts.contact.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        phoneNumberError.value = null;

        break;
      case "85":
        phoneNumberError.value = null;

        break;
      case "86":
        phoneNumberError.value = null;

        break;
      case "87":
        phoneNumberError.value = null;

        break;
      case "82":
        phoneNumberError.value = null;

        break;
      case "83":
        phoneNumberError.value = null;

        break;

      default:
        phoneNumberError.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}

function validatePhoneNumberAlternative() {
  // Expressão regular para validar o número de telefone
  var firstTwoDigits = "";

  if (userData.value.contacts.alternative_contact.length >= 2) {
    firstTwoDigits = userData.value.contacts.alternative_contact.slice(0, 2);

    switch (firstTwoDigits) {
      case "84":
        phoneNumberErrorAlternative.value = null;

        break;
      case "85":
        phoneNumberErrorAlternative.value = null;

        break;
      case "86":
        phoneNumberErrorAlternative.value = null;

        break;
      case "87":
        phoneNumberErrorAlternative.value = null;

        break;
      case "82":
        phoneNumberErrorAlternative.value = null;

        break;
      case "83":
        phoneNumberErrorAlternative.value = null;

        break;

      default:
        phoneNumberErrorAlternative.value =
          "Por favor, insira um número de telefone válido.";

        break;
    }
  }
}

// validate inputs
function onUserTypefullName() {
  if (userData.value.fullName != "") {
    fullname_message_error.value = "";
  }
}

function onUserTypeFatherName() {
  if (userData.value.fathers_filliation !== "") {
    father_name_message_error.value = "";
  }
}

function onUserTypeMotherName() {
  if (userData.value.mothers_filliation !== "") {
    mother_name_message_error.value = "";
  }
}

function onUserTypeGender() {
  if (userData.value.gender !== "") {
    gender_message_error.value = "";
  }
}

function onUserTypeBithDate() {
  if (userData.value.birthDate !== "") {
    birthDate_message_error.value = "";
  }
}

function onUserTypeIsdeficient() {
  if (userData.value.is_deficient !== "") {
    isDeficient_message_error.value = "";
  }
}

function onUserTypeLastSchoolFrequency() {
  if (userData.value.last_school_frequency !== "") {
    last_school_message_error.value = "";
  }
}

function onUserTypeTrainingArea() {
  if (userData.value.trainingArea !== "") {
    trainingArea_message_error.value = "";
  }
}

function onUserTypeLastSchoolYear(event) {
  if (userData.value.last_school_frequency_year !== "") {
    last_school_year_message_error.value = "";
  }
}

function onUserTypeHasCertificate() {
  if (userData.value.hasCertificate !== "") {
    hasCertificate_message_error.value = "";
  }
}

function onUserTypeCountry() {
  if (userData.value.nactionality !== "") {
    country_message_error.value = "";
  }
}

function onUserTypeProvince() {
  if (userData.value.province_id !== "") {
    province_message_error.value = "";
  }
}

function onUserTypeDistrict() {
  if (userData.value.district_id !== "") {
    district_message_error.value = "";
  }
}

function onUserTypeAddressLocation() {
  if (userData.value.address_location !== "") {
    address_message_error.value = "";
  }
}

function onUserTypeDocument() {
  if (userData.value.doc_type !== "") {
    doc_message_error.value = "";
  }
}

function onUserTypeClassemate() {
  if (userData.value.classmate_id !== "") {
    classemate_message_error.value = "";
  }
}

function changeGender(event) {
  if (event.target.value != "") {
    gender_message_error.value = null;
  }
}

function OnUserChangeDept(event) {
  if (event.label != "") {
    dept_message_error.value = null;
  }
}

function OnUserChangeDistrict(event) {
  if (event.target.value !== "") {
    district_message_error.value = "";
  }
}

function changeContry(event) {
  try {
    console.log(event.target.value == "Moçambique");
    if (event.target.value == "Moçambique") {
      enableProviceAndDistrict.value = false;
      country_message_error.value = "";
    } else {
      enableProviceAndDistrict.value = true;
      userData.value.district_id = null;
      userData.value.province_id = null;
      district_message_error.value = "";
      province_message_error.value = "";
      country_message_error.value = "";
    }
  } catch (error) {
    console.log(error);
  }
}
function validateEmail() {
  // Expressão regular para validar o formato de um e-mail
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Verifica se o e-mail atende ao formato esperado
  if (!emailRegex.test(userData.value.email)) {
    emailError.value = "Por favor, insira um endereço de e-mail válido.";
  } else {
    emailError.value = null;
  }
}
function is_deficientChange(evt) {
  if (evt.target.value != "" && evt.target.value == 1) {
    deficientTypeDisabled.value = false;
    isDeficient_message_error.value = "";
  } else {
    deficientTypeDisabled.value = true;
    isDeficient_message_error.value = "";
    userData.value.deficient_type = "";
    deficientType_message_error.value = "";
  }
  // if (evt.target.value == "") {
  //   deficientTypeDisabled.value = true;
  //   isDeficient_message_error.value = "";
  // } else {
  //   deficientTypeDisabled.value = false;
  //   isDeficient_message_error.value = "";
  // }
}
function numberOnly($event) {
  let keycode = $event.keyCode ? $event.keyCode : $event.which;

  if ((keycode < 48 || keycode > 57) && keycode !== 46) {
    $event.preventDefault();
  }
}
onMounted(() => {
  getAllTeachers();
  getYearTilCurrent();
  getProvinces();
  getAllDepartment();
});
</script>
