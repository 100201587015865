<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Gerenciar Cursos</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item"><a href="#">Cursos</a></li>
          <li class="breadcrumb-item active">Gerenciar Cursos</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="mb-2 col text-center text-sm-left align-items-center">
              <a
                href="javascript:void(0)"
                class="btn mr-1 btn-primary text-center text-sm-left text-white"
                @click="openModalAddNewClassmate"
              >
                <span class="fas fa-plus-circle"></span> Adicionar</a
              >
            </div>

            <div class="mb-2 col-12 col-sm col-lg-4 align-items-center">
              <form class=" ">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Pesquisar..."
                    v-model="search"
                  />
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-primary">
                      <i class="fa fa-search"></i>
                    </button>

                    <button type="button" class="btn ml-1 bg-primary">
                      <i class="fa fa-search-plus text-white"></i>
                      <span class="text-white">Busca avançada </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table
              id="example5"
              class="table table-hover"
              style="min-width: 845px"
            >
              <thead>
                <tr>
                  <th>#</th>
                  <th>Curso</th>
                  <th>Engenharia</th>
                  <th>Descrição</th>
                  <th class="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(c, index) in course" :key="index">
                  <td>{{ 1 + index }}</td>
                  <td>{{ c.name }}</td>
                  <td v-if="c.engineering == 1"><span>Sim</span></td>
                  <td v-else><span>Nao</span></td>
                  <td v-if="c.description">{{ c.description }}</td>
                  <td v-else>------</td>

                  <td aria-colindex="3" role="cell" class="w-140px text-center">
                    <div class="action-column">
                      <a
                        @click="viewCourse(index, c.id)"
                        class="btn btn-sm mr-1 btn-info btn-xs"
                        style="font-size: 13px"
                        ><i class="fa fa-eye text-white"></i>
                      </a>
                      <a
                        @click="editCourse(index, c.id)"
                        class="btn btn-sm mr-1 btn-warning btn-xs"
                        style="font-size: 13px"
                        ><i class="fa fa-edit text-white"></i>
                      </a>
                      <a
                        @click="confirmBeforeDelete(index, c.id)"
                        class="btn btn-sm mr-1 btn-danger btn-xs"
                        style="font-size: 13px"
                        ><i class="fa fa-times text-white"></i>
                      </a>
                    </div>
                  </td>
                  <!-- <td>
                    <router-link
                      :to="{ name: 'editCourse', params: { id_course: c.id } }"
                      class="btn btn-sm btn-primary"
                      ><i class="fas fa-edit"></i
                    ></router-link>&nbsp;
                    <a
                      @click="isdeleteCourse(c.id, index)"
                      href="javascript:void(0);"
                      class="btn btn-sm btn-danger"
                      ><i class="fas fa-trash-alt"></i
                    ></a>
                  </td> -->
                </tr>
                <tr v-if="course.length == 0">
                  <td colspan="4" class="text-center">
                    <span style="font-style: italic">
                      Ainda não tem registos</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <Page
              :total="total"
              :model-value="current_page"
              :page-size="parseInt(per_page)"
              @on-change="getAllCourse"
              v-if="total > 0"
              class="float-right"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalAddNewDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Novo Curso</h5>
        <button
          type="button"
          @click="closeModalDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendData">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nome do Curso</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.name"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Departamento do Curso</label>
                <select class="form-control" v-model="formData.department_id">
                  <option v-for="d in departments" :value="d.id" :key="d.id">
                    {{ d.dep_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Engenharia</label>
                <select class="form-control" v-model="formData.engineering">
                  <option value="1">Sim</option>
                  <option value="0">Nao</option>
                </select>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Descrição do Curso</label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="formData.description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <Button
          type="success"
          @click="addNewCourse"
          data-dismiss="modal"
          :loading="loadingServerStundent"
        >
          Adicionar
        </Button>

        <Button
          type="error"
          @click="closeModalDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalAddUpdateDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Actualizar dados curso</h5>
        <button
          type="button"
          @click="closeModalUpdateDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendData">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nome do Curso</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.name"
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Departamento do Curso</label>
                <select class="form-control" v-model="formData.department_id">
                  <option v-for="d in departments" :value="d.id" :key="d.id">
                    {{ d.dep_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Engenharia</label>
                <select class="form-control" v-model="formData.engineering">
                  <option value="1">Sim</option>
                  <option value="0">Nao</option>
                </select>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Descrição do Curso</label>
                <textarea
                  class="form-control"
                  rows="5"
                  v-model="formData.description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <Button
          type="success"
          @click="updateCourseData"
          data-dismiss="modal"
          :loading="loadingServerStundent"
        >
          Actualizar
        </Button>

        <Button
          type="error"
          @click="closeModalUpdateDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>

  <!-- Modal para update do novo estudante -->
  <div id="modal-overlay" v-show="isModalViewDept">
    <div class="container mt-2 pb-3 bg-white">
      <div class="modal-header">
        <h5 class="modal-title">Visualizar detalhes do curso</h5>
        <button
          type="button"
          @click="closeModalViewteDept"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="sendData">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Nome do Curso</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="formData.name"
                  disabled
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Departamento do Curso</label>
                <select
                  disabled
                  class="form-control"
                  v-model="formData.department_id"
                >
                  <option v-for="d in departments" :value="d.id" :key="d.id">
                    {{ d.dep_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-label">Engenharia</label>
                <select
                  disabled
                  class="form-control"
                  v-model="formData.engineering"
                >
                  <option value="1">Sim</option>
                  <option value="0">Nao</option>
                </select>
              </div>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form-label">Descrição do Curso</label>
                <textarea
                  disabled
                  class="form-control"
                  rows="5"
                  v-model="formData.description"
                ></textarea>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <Button
          type="error"
          @click="closeModalViewteDept"
          class="btn btn-danger"
          data-dismiss="modal"
        >
          Cancelar
        </Button>
      </div>
    </div>
  </div>
  <Loading :loadingData="loadingServerStundent"></Loading>
</template>

<script setup>
import { ref, onUnmounted, watch, onMounted } from "vue";
import store from "@/store";
import CourseStoreModule from "./CourseStoreModule";
import toastr from "toastr";
import Loading from "../../components/Loading.vue";
import Swal from "sweetalert2";

const course = ref([]);
const total = ref(null);
const perPage = ref(10);
const current_page = ref();
const searchQuery = ref("");
const search = ref("");
const loadingServerStundent = ref(false);
const isModalAddNewDept = ref(false);
const isModalAddUpdateDept = ref(false);
const isModalViewDept = ref(false);
const formData = ref({
  name: "",
  description: "",
  department_id: null,
  engineering: null,
  course_id: "",
});
const departments = ref([]);

function clearInputs() {
  formData.value.name = "";
  formData.value.description = "";
  formData.value.department_id = null;
  formData.value.engineering = null;
  formData.value.course_id = null;
}

async function getAllDepartment() {
  try {
    loadingServerStundent.value = true;
    const response = await CourseStoreModule.actions.getDepartments();

    departments.value = response.data.data;
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

async function getAllCourse(page = 1) {
  await CourseStoreModule.actions
    .fetchCourses(null, {
      perPage: perPage.value,
      page: page,
      search: search.value,
    })
    .then((response) => {
      course.value = response.data.data;

      total.value = course.value.total;
      current_page.value = course.value.current_page;
      perPage.value = course.value.perPage;
    })
    .catch((error) => {
      console.log(error);
      toastr.error("Error na busca de dados do curso");
    });
}

watch(search, (newValue, oldValue) => {
  if (newValue.length >= 4 || oldValue.length >= 4) {
    getAllCourse();
  } else if (newValue.length == 0 || oldValue.length == 0) {
    getAllCourse();
  }
});

function viewCourse(index, id) {
  isModalViewDept.value = true;

  formData.value.name = course.value[index].name;
  formData.value.description = course.value[index].description;
  formData.value.department_id = course.value[index].department_id;
  formData.value.engineering = course.value[index].engineering;

  formData.value.course_id = id;
}
function closeModalViewteDept() {
  isModalViewDept.value = false;
  clearInputs();
}

function closeModalDept() {
  isModalAddNewDept.value = false;
  clearInputs();
}
function closeModalUpdateDept() {
  isModalAddUpdateDept.value = false;
  clearInputs();
}

function editCourse(index, id) {
  isModalAddUpdateDept.value = true;

  formData.value.name = course.value[index].name;
  formData.value.description = course.value[index].description;
  formData.value.department_id = course.value[index].department_id;
  formData.value.engineering = parseInt(course.value[index].engineering);
  formData.value.course_id = id;
}

async function updateCourseData() {
  try {
    loadingServerStundent.value = true;

    let courseIndex = course.value.findIndex(
      (item) => item.id === formData.value.course_id
    );

    var course_name = course.value.find(
      (item) =>
        item.name == formData.value.name && item.id != formData.value.course_id
    );

    if (course_name) {
      return toastr.info("Este curso ja existe, tente outro");
    }

    course.value[courseIndex].name = formData.value.name;
    course.value[courseIndex].description = formData.value.description;
    course.value[courseIndex].id = formData.value.course_id;
    course.value[courseIndex].engineering = formData.value.engineering;

    const resp = await CourseStoreModule.actions.updateCourse(formData.value);

    if (resp.data.status == true) {
      toastr.success(resp.data.message);
      isModalAddUpdateDept.value = false;
      clearInputs();
    } else {
      toastr.error(resp.data.message);
    }
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

async function addNewCourse() {
  try {
    loadingServerStundent.value = true;

    var course_name = course.value.find(
      (item) => item.name == formData.value.name
    );

    if (course_name) {
      return toastr.info("Este curso ja existe, tente outro");
    }

    const resp = await CourseStoreModule.actions.createCourse(formData.value);

    if (resp.data.status == true) {
      course.value.push(resp.data.data);
      toastr.success(resp.data.message);
      isModalAddNewDept.value = false;
      clearInputs();
    }
  } catch (error) {
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loadingServerStundent.value = false;
  }
}

async function deleteTurma(index, id) {
  try {
    loadingServerStundent.value = true;
    course.value.splice(index, 1);

    const response = await CourseStoreModule.actions.deleteCourse(id);

    if (response.data > 0) {
      Swal.fire({
        title: "Eliminado!",
        text: "Registro Eliminado com Sucesso.",
        icon: "success",
      });
    }

    console.log(response.data);
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}
function openModalAddNewClassmate() {
  isModalAddNewDept.value = true;
}

function confirmBeforeDelete(index, id) {
  Swal.fire({
    title: "Tem certeza que deseja eliminar este registro?",
    text: "Nao tera como reverter esta operacao!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim, Eliminar!",
    cancelButtonText: "Cancel!",
  }).then((result) => {
    if (result.isConfirmed) {
      deleteTurma(index, id);
    }
  });
}

onMounted(() => {
  getAllCourse();
  getAllDepartment();
});
</script>


<style scope>
.modal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100%;
  transition: height 0.2s linear;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}

.modal {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: #fff;
}

.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;

  width: 100%;

  border-radius: 4px;
  height: 100%;
}
</style>