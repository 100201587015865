import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";
const URL_API = process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getAllStudents() {
      await axios
        .get(URL_API + "estudantes", { headers: authHeader() })
        .then((response) => {
          console.log(response);
        });
    },

    async viewEstudante(id) {
      const resp = await axios.get(URL_API + `estudantes/${id}`, {
        headers: authHeader(),
      });

      return resp;
    },

    async createInscritpion(datas) {
      try {
        const resp = await axios.post(URL_API + "estudantes", datas, {
          headers: authHeader(),
        });
        return resp;
      } catch (err) {
        return err;
      }
    },

    async updateEstudante(estudante, id) {
      return axios.patch(URL_API + `estudantes/${id}`, estudante, {
        headers: authHeader(),
      });
    },

    updateEstudanteClassmate(classmateData, id) {
      return axios.post(
        URL_API + `updateClassmateStudant/${id}`,
        classmateData,
        { headers: authHeader() }
      );
    },

    async deleteStudent(id) {
      await axios.delete(URL_API + `estudantes/${id}`);
    },

    async getProvinces() {
      try {
        const resp = await axios.get(URL_API + "provinces");

        return resp;
      } catch (error) {
        return error;
      }
    },

    async ChangeProvince(evt) {
      this.loadDistricts = true;
      console.log(evt.target.value);

      await axios
        .get(URL_API + "district/" + evt.target.value)
        .then((response) => {
          // console.log(response);
          this.districtData = response.data;
          this.loadDistricts = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getAllCourses() {
      try {
        const resp = await axios.get(URL_API + "getAllCourses");

        return resp;
      } catch (error) {
        return error;
      }
    },
    async getSemesters() {
      try {
        const resp = await axios.get(URL_API + "semesters", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getLevels() {
      try {
        const resp = await axios.get(URL_API + "levels", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },
    async getLevelsCandi() {
      try {
        const resp = await axios.get(URL_API + "getlevels");

        return resp;
      } catch (error) {
        return error;
      }
    },

    async academicYears() {
      try {
        const resp = await axios.get(URL_API + "academic-years", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getClassmate() {
      try {
        const resp = await axios.get(URL_API + "getTurmas", {
          headers: authHeader(),
        });

        return resp;
      } catch (error) {
        return error;
      }
    },

    async getAllStudent(data) {
      try {
        const resp = await axios.get(
          URL_API + `estudantes?page=${data.page}&search=${data.search}`,
          {
            headers: authHeader(),
          }
        );

        return resp.data;
      } catch (error) {
        return error;
      }
    },

    async fetchStudent(data) {
      try {
        const resp = await axios.get(
          URL_API + `fetchStudent?search=${data.search}`,
          {
            headers: authHeader(),
          }
        );

        return resp.data;
      } catch (error) {
        return error;
      }
    },

    // Get student withou paginate
    getAllStudentWithoutPaginate() {
      return axios.get(URL_API + `getStudantesWithoutPaginate`, {
        headers: authHeader(),
      });
    },

    deleteStudent(student_id) {
      return axios.delete(URL_API + `estudantes/${student_id}`, {
        headers: authHeader(),
      });
    },
  },
};
