<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nome</th>
          <th scope="col">Curso</th>
          <th scope="col">Nivel Academico</th>
          <th scope="col">Regime</th>
          <th scope="col">Ano Academico</th>
          <th scope="col">Semestre</th>
          <th scope="col">Tipo de Inscricao</th>
          <th scope="col">Ano de Entrada</th>
          <th scope="col">Estudante Activo</th>
          <th scope="col">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(i, index) in inscriptionsData" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ i.fullName }}</td>
          <td>{{ i.course }}</td>
          <td>{{ i.level }}</td>
          <td>{{ i.scholar_shift }}</td>
          <td>{{ i.academic_year }}&#176;</td>
          <td>{{ i.semester }}&#176;</td>
          <td>{{ i.inscriptionType }}</td>
          <td>{{ i.ano_entrada }}</td>

          <td v-if="i.isActive == 0">
            <span class="badge badge-danger">Inactivo</span>
          </td>
          <td v-else><span class="badge badge-success">Activo</span></td>
          <td aria-colindex="3" role="cell" class="w-140px text-center">
            <div class="action-column">
              <a
                title="Aprovar Candidatura"
                @click="approveCandidate(index, i.id, i.person_id, i.course_id)"
                class="btn btn-sm mr-1 btn-success btn-xs"
                style="font-size: 13px"
                ><i class="fa fa-check text-white"></i>
              </a>
              <a
                title="reprovar Candidatura"
                @click="
                  repproveCandidate(index, i.id, i.person_id, i.course_id)
                "
                class="btn btn-sm mr-1 btn-danger btn-xs"
                style="font-size: 13px"
                ><i class="fa fa-times text-white"></i>
              </a>
            </div>
          </td>

          <!-- <td>
            <div>
               <div class="col-lg-12 col-xl-12 col-sm-12 col-md-12">
                <select
                  class="form-control"
                  style="border-radius: 16px"
                  @change="
                    handleCandidate($event, i.id, i.person.id, i.course_id)
                  "
                >
                  <option value="0">Selecione</option>
                  <option value="Aproved">Aprovar Candidatura</option>
                  <option value="dined">Reprovar Candidatura</option>
                </select>
              </div>
            </div>
          </td> -->
        </tr>
        <tr v-if="inscriptionsData.length == 0">
          <td colspan="12" class="text-center" style="font-style: italic">
            <span>Ainda não tem registro</span>
          </td>
        </tr>
      </tbody>
    </table>
    <Page
      :total="total"
      :model-value="current_page"
      :page-size="parseInt(per_page)"
      @on-change="getCandidateInscriptions"
      v-if="total > 0"
      class="float-right"
    />
  </div>
  <Loading :loadingData="loading"></Loading>
</template>
<script setup>
import { onMounted, ref } from "vue";
import InscriptionStoreModule from "./InscriptionStoreModule";
import Loading from "../../components/Loading.vue";
import Swal from "sweetalert2";
import toastr from "toastr";

const loading = ref(false);
const total = ref(null);
const current_page = ref(null);
const per_page = ref(null);
const inscriptionsData = ref([]);
const loadingServerStundent = ref(false);
onMounted(() => {
  getCandidateInscriptions();
});

async function getCandidateInscriptions(page = 1) {
  try {
    loading.value = true;
    let tipo_inscricao = "candidaturas";
    const resp = await InscriptionStoreModule.actions.getCandidateInscriptions({
      page: page,
    });
    inscriptionsData.value = resp.data.data;
    total.value = resp.data.meta.total;
    per_page.value = resp.data.meta.per_page;
    current_page.value = resp.data.meta.current_page;
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
}

async function approved(index, inscription_id, person_id, course_id) {
  try {
    loadingServerStundent.value = true;
    const data = {
      inscription_id: inscription_id,
      inscriptionType: "Inscrição",
      course_id: course_id,
    };

    inscriptionsData.value.splice(index, 1);

    const resp = await InscriptionStoreModule.actions.aproveInscription(
      data,
      person_id
    );

    if (resp.data.path) {
      toastr.success("Candidatura Aprovada com Sucesso.");
    }

    console.log(resp.data);

    downloadPDF(resp.data.path);
  } catch (error) {
    console.log(error);
    if (typeof error.response != "undefined") {
      Object.keys(error.response.data.errors).forEach((key) => {
        toastr.info(key, error.response.data.errors[key]);
      });
    }
  } finally {
    loadingServerStundent.value = false;
  }
}
async function approveCandidate(index, inscription_id, person_id, course_id) {
  Swal.fire({
    title: "Tem certeza que deseja aprovar esta candidatura?",
    text: "Nao tera como reverter esta operacao!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim, Eliminar!",
    cancelButtonText: "Cancel!",
  }).then((result) => {
    if (result.isConfirmed) {
      approved(index, inscription_id, person_id, course_id);
    }
  });
}

// Implentar a reprovacao da candidatura
async function repproveCandidate(index, inscription_id, person_id, course_id) {
  try {
    loadingServerStundent.value = true;
    const data = {
      inscription_id: inscription_id,
      inscriptionType: "Inscricao",
      course_id: course_id,
    };
    const resp = await InscriptionStoreModule.actions.aproveInscription(
      data,
      person_id
    );

    downloadPDF(resp.data.path);

    console.log(resp.data.path);
  } catch (error) {
    console.log(error);
  } finally {
    loadingServerStundent.value = false;
  }
}

// async function handleCandidate(event, inscription_id, person_id, course_id) {
//   try {
//     if (event.target.value == "Aproved") {
//       event.target.value = "0";

//       const data = {
//         inscription_id: inscription_id,
//         inscriptionType: "Inscricao",
//         course_id: course_id,
//       };
//       const resp = await InscriptionStoreModule.actions.aproveInscription(
//         data,
//         person_id
//       );

//       downloadPDF(resp.data.path);

//       console.log(resp.data.path);
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }

function downloadPDF(pdfPath) {
  const link = document.createElement("a");
  link.href = pdfPath;
  link.target = "_blank";
  link.download = "ficha_pagamento.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
</script>