<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Alocar Funcionarios</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Funcionario</li>
          <li class="breadcrumb-item active">Alocar Funcionario</li>
        </ol>
      </div>
    </div>
    <!-- end row -->
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="row tab-content">
        <div id="list-view" class="tab-pane fade active show col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4>{{ userData.fullname }}</h4>

              <div class="col-md-12 mt-5">
                <label class="form-label">Previlegio</label>
                <header class="row float-right addCursor" @click="openModal(1)">
                  <i class="fas fa-plus text-white"></i>
                </header>
              </div>
              <div class="form-group col-md-12 row">
                <div
                  class="departemanto">
                  <span class="text-white">
                    {{ TeacherRoleAdded }}
                  </span>

                  <span class="btn-remove">
                    <i
                      class="fas fa-times text-danger"
                      @click="removeSubject(1)"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="col-md-12 text-center mt-5" v-if="TeacherRoleAdded == ''">
                <span class="text-white" style="font-size: 11px"
                  >Ainda sem Previlegio...</span
                >
              </div>

              <div class="col-md-12 mt-5">
                <label class="form-label">Permissoes</label>
                <header class="row float-right addCursor" @click="openModal(2)">
                  <i class="fas fa-plus text-white"></i>
                </header>
              </div>
              <div class="form-group col-md-12 row">
                <div
                  class="departemanto"
                  v-for="(perm, index) in permissoes"
                  :key="index"
                >
                  <span class="text-white">
                    {{ perm.name }}
                  </span>

                  <span class="btn-remove">
                    <i
                      class="fas fa-times text-danger"
                      @click="removeRole(perm.id, index)"
                    ></i>
                  </span>
                </div>
              </div>

              <div
                class="col-md-12 text-center mt-5"
                v-if="permissoes.length  == 0"
              >
                <span class="text-white" style="font-size: 11px"
                  >Ainda sem Permissoes...</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="isClose" class="modal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Alocar {{ title }}</h5>
            <button
              type="button"
              @click="closeModal"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="col-lg-12 col-md-12 col-sm-12 mt-3"
                 v-if="title == 'Previlegio'"
              >
                <div class="form-group">
                  <label class="form-label">Selecione a função*</label>
                  <select class="form-control" v-model="TeacherRole">
                    <option value="0">Selecione a função</option>
                    <option
                      :value="role.name"
                      v-for="role in roles"
                      :key="role.id"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div
                class="col-lg-12 col-md-12 col-sm-12 mt-3"
                v-if="title == 'Permissoes'"
              >
                <div class="form-group">
                  <label class="form-label">Selecione a permissoes*</label>
                  <Select
                    class="text-white"
                    multiple
                    v-model="permissoes"
                    placeholder="Selecione as permissoes"
                  >
                    <Option
                      :value="perm.id"
                      v-for="perm in permissions"
                      :key="perm.id"
                    >
                      {{ perm.name }}
                    </Option>
                  </Select>
                </div>
              </div>
            </form>
          </div>

          <div class="modal-footer">
            <button
              v-if="title == 'Previlegio'"
              type="button"
              @click="handleSubmitAlocateRole"
              class="btn btn-success"
            >
              {{ isAlocar == false ? "Alocar" : "Alocando..." }}
            </button>

            <button
              v-if="title == 'Permissoes'"
              type="button"
              @click="HandleAddPermissions"
              class="btn btn-success"
            >
              {{ isAlocar == false ? "Alocar" : "Alocando..." }}
            </button>

            <button
              @click="closeModal"
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Nao
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import toastr from "toastr";
import permissionStoreModule from "../permissions/permissionStoreModule";
import EmployesStoreModule from "./EmployesStoreModule";
 
export default {
  setup() {
    const isClose = ref(false);
    const isAlocar = ref(false);
    const title = ref("");
    const dataAlocate = ref([]);
    const permissions = ref([]);
    const TeacherRole = ref('');
    const route = useRoute();
    const roles = ref([]);
    const permissoes = ref([]);
    const userData = ref({
      fullname:'',
      email:''
    });
    const TeacherRoleAdded = ref(null);

    onMounted(() => {
      getRoles();
      getAllpermissions();
      getRoleByUser();
    });


    async function getRoleByUser(){

      const resp = await EmployesStoreModule.actions.getRoleByUser(route.params.user_id);

      TeacherRoleAdded.value = resp.data.roles[0].name
      permissoes.value =  resp.data.permissions
      userData.value = resp.data
      console.log(resp.data.fullname);

    }

    async function handleSubmitAlocateRole() {
      isAlocar.value = true;

      const resp = await EmployesStoreModule.actions.alocateRole(
        TeacherRole.value,
        route.params.user_id
      );

      isAlocar.value = false;
    }


    async function HandleAddPermissions(){

      isAlocar.value = true;

      const resp = await EmployesStoreModule.actions.addPermissions(
        permissoes.value,
        route.params.user_id
        );
      console.log(resp);
      isAlocar.value = false;
    }
    async function handleSubmitAlocate() {
      isAlocar.value = true;

      const resp = await EmployesStoreModule.actions.alocateData(
        dataAlocate.value,
        route.params.id_teacher
      );
      if (resp.status) {
        toastr.success(resp.message);
        ShowProfessor();
      } else {
        toastr.error("Ocorreu um erro ao tentar adicionar disciplinas");
      }
      // console.log(resp);
      isAlocar.value = false;
    }

    async function getAllpermissions() {
      const resp = await permissionStoreModule.actions.getAllPermissions();
      permissions.value = resp.data;
      console.log(resp.data);
    }

    async function getRoles() {
      const resp = await EmployesStoreModule.actions.fetchRoles();
      roles.value = resp.data;
      // userData.permission_id = resp.data[4].id;
      console.log(resp.data);
    }

    async function removeClassmate(classmate_id, index) {
      classmates.value.splice(index, 1);
      const resp = await TeacherStoreModule.actions.removeTeacherFromClassmate(
        classmate_id,
        route.params.id_teacher
      );
    }

    async function removeSubject(subject_id, index) {
      subjectAlocate.value.splice(index, 1);
      const resp = await TeacherStoreModule.actions.removeSubjectFromTeacher(
        subject_id,
        route.params.id_teacher
      );
    }

    async function removeRole() {}

    function closeModal() {
      isClose.value = false;
    }

    function openModal(id) {
      if (id == 1) {
        title.value = "Previlegio"; 
      } else {
        title.value = "Permissoes";
      }
      isClose.value = true;
    }

    async function getAllpermissions() {
      const resp = await permissionStoreModule.actions.getAllPermissions();
      permissions.value = resp.data;
      console.log(resp.data);
    }

    return {
      userData,
      isClose,
      isAlocar,
      closeModal,
      openModal,
      title,
      handleSubmitAlocate,
      dataAlocate,
      removeClassmate,
      removeSubject,
      removeRole,
      permissions,
      getAllpermissions,
      getRoles,
      roles,
      TeacherRole,
      handleSubmitAlocateRole,
      permissoes,
      getRoleByUser,
      TeacherRoleAdded,
      HandleAddPermissions
    };
  },
};
</script>
<style scoped>
.departemanto {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 20px;
  margin-top: 14px;
  margin-left: 2px;
  background-color: #1a1c2a;
  color: #fff;
  width: 150px;
}
.departemanto span {
  color: #fff;
}
.btn-remove,
.addCursor {
  cursor: pointer;
}
</style>
