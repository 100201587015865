<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Detalhes</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Detalhes</li>
          <li class="breadcrumb-item active">Turma</li>
        </ol>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8 col-lg-12 col-md-12">
      <div class="card">
        <div class="row">
          <div class="col-md-4">
            <div class="card-header">
              <h3 class="card-title font-14">
                Informa&ccedil;&otilde;es da turma
              </h3>
            </div>
            <div class="card-body">
              <ul class="list-unstyled">
                <li class="mb-2">
                  <h4 class="text-white">{{}}</h4>
                  <small class="text-white text-center">{{}}</small>
                </li>
                <li class="mb-2">
                  <strong class="text-white">Nome da turma: </strong
                  ><span class="text-white">{{}}</span>
                </li>
                <li class="mb-2">
                  <strong class="text-white">Descri&ccedil;ao: </strong
                  ><span class="text-white">{{}}</span>
                </li>
                <li class="mb-2">
                  <strong class="text-white">Nivel: </strong
                  ><span class="text-white">{{}}</span>
                </li>
                <li class="mb-2">
                  <strong class="text-white">Ano: </strong
                  ><span class="text-white">{{}}</span>
                </li>
              </ul>
              <button
                class="btn btn-primary btn-md"
                data-toggle="collapse"
                data-target="#collapseExample"
              >
                Adicionar Formula de Avaliacao

                <span>
                  <i class="mdi mdi-chevron-down"></i>
                </span>
              </button>
            </div>
          </div>

          <div class="col-md-8">
            <div class="card-header">
              <h3 class="card-title font-14">
                Informa&ccedil;&otilde;es adicionais
              </h3>
            </div>
            <div class="card-body">
              <table class="table">
                <ul class="list-unstyled">
                  <li class="mb-2">
                    <strong class="text-white"
                      >Dados relacionados com avaliacoes: </strong
                    ><br />
                    <div
                      v-for="(form, index) in classmateFormula"
                      :key="index"
                      class="p-1"
                    >
                      <span
                        class="float-right mr-2"
                        style="cursor: pointer"
                        @click="removeDataTest(form.id, index)"
                        ><i class="fas fa-trash-alt text-danger"></i
                      ></span>
                      <span
                        class="float-right mr-2"
                        style="cursor: pointer"
                        @click="editDataTest(form.id, index)"
                        ><i class="fas fa-edit text-primary"></i
                      ></span>
                      <span class="text-white badge badge-primary p-1"
                        >{{ form.assessment_type }} =
                        {{ form.percentage }}%</span
                      >
                      <br />
                      <hr class="text-muted" />
                    </div>
                  </li>
                </ul>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isEdit == true">
    <div class="card-header">
      <h3 class="card-title font-14">Actualizar os dados</h3>
    </div>

    <div class="card card-body">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-label">Titulo da avaliacao</label>
            <select class="form-control" v-model="testDataEdit.assessment_type">
              <option value="MINI-TESTE">MINI-TESTE</option>
              <option value="TESTE-1">TESTE-1</option>
              <option value="TESTE-2">TESTE-2</option>
              <option value="TESTE-3">TESTE-3</option>
              <option value="TRABALHO-PESQUISA">TRABALHO-PESQUISA</option>
            </select>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="form-group">
            <label class="form-label">Percentagem</label>
            <input
              type="text"
              class="form-control"
              placeholder="Informe a percentagem"
              v-model="testDataEdit.percentage"
            />
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12">
        <button
          type="button"
          class="btn btn-primary float-right"
          v-if="loading == false"
          @click="updateTestData(testDataEdit.id)"
        >
          Actualizar
        </button>
        <button type="button" class="btn btn-success float-right" v-else>
          Actualizando...
        </button>
      </div>
    </div>
  </div>

  <div class="collapse" id="collapseExample">
    <div class="card-header">
      <h3 class="card-title font-14">
        Adicionar Informa&ccedil;&otilde;es das avaliacoes
      </h3>
    </div>

    <div class="card card-body">
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <div class="form-group">
              <label class="form-label"
                >Titulo da avaliacao {{ testData.classmate_id }}</label
              >
              <select class="form-control" v-model="testData.assessment_type">
                <option value="MINI-TESTE">MINI-TESTE</option>
                <option value="TESTE-1">TESTE-1</option>
                <option value="TESTE-2">TESTE-2</option>
                <option value="TESTE-3">TESTE-3</option>
                <option value="TRABALHO-PESQUISA">TRABALHO-PESQUISA</option>
              </select>
            </div>

            <label class="form-label">Percentagem</label>
            <input
              type="text"
              class="form-control"
              placeholder="Informe a percentagem"
              v-model="testData.percentage"
            />
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12">
            <button
              type="submit"
              class="btn btn-primary float-right"
              v-if="loading == false"
              @click="submitData"
            >
              Adicionar
            </button>
            <button type="button" class="btn btn-success float-right" v-else>
              Aguarde
            </button>
          </div>
        </div>

        <!-- <div class="form-group" v-for="(i, index) in datatTeste.testData" :key="index">
            <span class="float-right" v-if="countTestPercent > 1" style="cursor:pointer" @click="remove(index)"><i class="fas fa-times text-white"></i></span>

            <div class="form-group">
                  <label class="form-label">Titulo do teste</label>
                  <select class="form-control"  >
                    <option :value="datatTeste.testData.titulo">Teste 1</option>
                    </select>
            </div>

            <label class="form-label">Percentagem</label>
            <input type="text" class="form-control" placeholder="Informe a percentagem" v-model="datatTeste.testData.percentegemTeste1" />

         
 
          </div>
           
           <div class="col-lg-12 col-md-12 col-sm-12" v-if="countTestPercent <= 2" @click="addPercenteTeste">
                <button type="submit" class="btn btn-primary float-right">Adicionar <span><i class="mdi mdi-plus"></i> </span></button>
            </div>
        </div> -->

        <div class="col-lg-6">
          <div
            class="form-group"
            v-for="(i, index) in datatTeste.trabalho"
            :key="index"
          >
            <span
              class="float-right"
              v-if="countTrabPercent > 1"
              :id="'remove' + index"
              style="cursor: pointer"
              @click="removeTrabalho(index)"
              ><i class="fas fa-times text-white"></i
            ></span>

            <div class="form-group">
              <label class="form-label">Titulo do trabalho</label>
              <select class="form-control">
                <option value="trabalho 1">trabalho 1</option>
                <option value="trabalho 2">trabalho 2</option>
                <option value="trabalho 3">trabalho 3</option>
              </select>
            </div>

            <label class="form-label">Percentagem</label>
            <input type="text" class="form-control" />
          </div>

          <div
            class="col-lg-12 col-md-12 col-sm-12"
            v-if="countTrabPercent <= 2"
            @click="addPercenteTrabalho"
          >
            <button type="submit" class="btn btn-primary float-right">
              Adicionar <span><i class="mdi mdi-plus"></i> </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6">
              <h5 class="page-title">Detalhes da Turma</h5>
            </div>
          </div>
        </div>

        <!-- <div class="row pt-2">
          <div class="col-sm-3 col-lg-3">
            <div class="card">
              <div class="card-haeading p-4 text-center">
                <div class="mini-stat-icon">
                  <i
                    class="mdi mdi-account-multiple-outline bg-primary text-white"
                  ></i>
                </div>
                <div>
                  <h5 class="font-16 text-center">
                    {{ dataStudents.length }}
                  </h5>
                </div>
                <h3 class="mt-2 font-12">Total de Estudantes</h3>
              </div>
            </div>
          </div>

            <div class="col-sm-3 col-lg-3">
            <div class="card">
              <div class="card-haeading p-4 text-center">
                <div class="mini-stat-icon">
                  <i
                    class="mdi mdi-account-multiple-outline bg-primary text-white"
                  ></i>
                </div>
                <div>
                  <h5 class="font-16 text-center">
                    0
                   </h5>
                </div>
                  <h3 class="mt-2 font-12">Total de Graduados</h3>

              </div>
            </div>
          </div> 
          <div class="col-lg-4" style="height: 200px">
            <Doughnut :data="data" :options="options" />
          </div>

          <div class="col-sm-3 col-lg-5">
            <div class="card">
              <div class="card-haeading p-4 text-center">
               
                <div>
                  <button class="btn btn-primary"> Adicionar Formula da avaliacao</button>
               
                </div>
 
              </div>
            </div>
          </div> 

          <div class="col-lg-3"></div>
        </div> -->

        <div class="card-body">
          <div class="form-group float-right m-2">
            <form action="" role="search" class="app-search">
              <input
                type="text"
                class="form-control"
                placeholder="Pesquisar..."
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form>
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nome Completo</th>
                  <th scope="col">Genero</th>
                  <th scope="col">Turma</th>
                  <th scope="col">Nivel Academico</th>
                  <th scope="col">Ano Academico</th>
                  <th scope="col">Ano Entrada</th>
                  <th scope="col">Accoes</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(students, index) in dataStudents" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ students.pessoa.fullName }}</td>
                  <td>{{ students.pessoa.gender }}</td>
                  <td>{{ students.turma.name }}</td>
                  <td>{{ students.turma.level.name }}</td>
                  <td>{{ students.turma.academic_year.designation }}</td>
                  <td>{{ students.turma.entry_year }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'AddAssessments',
                        params: { id_classmate: students.turma.id },
                      }"
                      >Lancamentos</router-link
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <Page :total="department.total" :model-value="department.current_page"  :page-size="parseInt(department.per_page)" @on-change="getAllDepartment" v-if="department.total"   class="float-right" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import TeacherDashStoreModule from "./TeacherDashStoreModule";
import { useRoute } from "vue-router";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";
import toastr from "toastr";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  components: {
    Doughnut,
  },
  setup() {
    const dataStudents = ref([]);
    var countTestPercent = ref(1);
    var countTrabPercent = ref(1);
    const route = useRoute();
    const options = ref({
      responsive: true,
      maintainAspectRatio: false,
    });

    const loading = ref(false);
    const isEdit = ref(false);

    const testDataEdit = ref({
      id: "",
      classmate_id: route.params.id_classmate,
      assessment_type: "",
      percentage: "",
    });

    const testData = ref({
      classmate_id: route.params.id_classmate,
      assessment_type: "",
      percentage: "",
    });

    const classmateFormula = ref([]);

    const datatTeste = ref({
      testData: [
        {
          titulo: "",
          percentegemTeste1: "",
        },
      ],

      trabalho: [
        {
          titulo: "",
          percentegemTrabalho: 0,
        },
      ],
    });

    onMounted(() => {
      getFormula();
    });

    async function submitData() {
      loading.value = true;
      const resp = await TeacherDashStoreModule.actions.submitData(
        testData.value
      );

      if (resp.data.message) {
        toastr.success(resp.data.message);
        getFormula();
      } else {
        toastr.error(
          "Desculpe-nos ocorreu um erro ao tentar adicionar os dados, tente mais tarde..."
        );
      }

      loading.value = false;
    }

    async function getFormula() {
      const resp = await TeacherDashStoreModule.actions.getAllFormulas();
      classmateFormula.value = resp.data;
      console.log(resp);
    }

    async function removeDataTest(id, index) {
      const resp = await TeacherDashStoreModule.actions.DeleteDataTest(id);

      classmateFormula.value.splice(index, 1);
      console.log(resp);
    }

    async function editDataTest(id, index) {
      isEdit.value = true;
      classmateFormula.value.forEach((element) => {
        if (element.id === id) {
          testDataEdit.value.assessment_type = element.assessment_type;
          testDataEdit.value.percentage = element.percentage;
          testDataEdit.value.id = element.id;
        }
      });
    }

    async function updateTestData(id) {
      loading.value = true;

      const resp = await TeacherDashStoreModule.actions.updateFormula(
        testDataEdit.value,
        id
      );
      if (resp.data == 1) {
        getFormula();
        isEdit.value = false;
      }
      loading.value = false;
    }

    function addPercenteTeste() {
      countTestPercent.value += 1;
      datatTeste.value.testData.push({ percentegemTeste1: "" });
    }

    function remove(index) {
      datatTeste.value.testData.splice(index, 1);
      countTestPercent.value -= 1;
    }

    function addPercenteTrabalho() {
      countTrabPercent.value += 1;
      datatTeste.value.trabalho.push({ percentegemTrabalho: "" });
    }

    function removeTrabalho(index) {
      datatTeste.value.trabalho.splice(index, 1);
      countTrabPercent.value -= 1;
    }

    const data = {
      datasets: [
        {
          backgroundColor: ["#41B883", "#E46651"],
          data: [40, 20],
        },
      ],
      labels: ["Homens", "Mulheres"],
    };

    onMounted(() => {
      getStudentByClassMates();
    });

    async function getStudentByClassMates() {
      const resp = await TeacherDashStoreModule.actions.getStudentByClassMates(
        route.params.id_classmate
      );
      dataStudents.value = resp.data.data;
      console.log(resp.data.data[0]);
    }

    return {
      dataStudents,
      getStudentByClassMates,
      route,
      options,
      data,
      datatTeste,
      addPercenteTeste,
      countTestPercent,
      remove,
      addPercenteTrabalho,
      removeTrabalho,
      countTrabPercent,
      testData,
      loading,
      submitData,
      getFormula,
      classmateFormula,
      removeDataTest,
      editDataTest,
      isEdit,
      testDataEdit,
      updateTestData,
    };
  },
};
</script>
