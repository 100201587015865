import axios from "axios";
const URL_API = process.env.VUE_APP_URL_API;

export const formatDates = (dateFormat) => {
  const data = new Date(dateFormat);
  const day = data.getDate();
  const month = data.getMonth() + 1;
  const year = data.getFullYear();
  const hours = data.getHours();
  const minutes = data.getMinutes();
  const seconds = data.getSeconds();

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export function moneyFormat(money) {
  const formatter = new Intl.NumberFormat("MZ", {
    style: "currency",
    currency: "MZN",
  });

  return formatter.format(money);
}

// Funcao para formatar valor sem o mz
export function numberFormat(
  number,
  decimals = 2,
  decimalSeparator = ".",
  thousandSeparator = ","
) {
  number = parseFloat(number);

  if (isNaN(number)) {
    return "";
  }
}

// return years
export const getYearTilCurrent = () => {
  const currentYear = new Date().getFullYear();
  let years = [];

  for (let year = currentYear; year >= 1900; year--) {
    years.push(year);
  }

  return years;
};

// return macthed Month
export const findMonth = (month) => {
  switch (month) {
    case 1:
      return "Janeiro";
      break;
    case 2:
      return "Fevereiro";

      break;

    case 3:
      return "Março";

      break;
    case 4:
      return "Abril";

      break;

    case 5:
      return "Maio";

      break;

    case 6:
      return "Junho";

      break;

    case 7:
      return "Julho";

      break;

    case 8:
      return "Agosto";

      break;
    case 9:
      return "outubro";

      break;
    case 10:
      return "Setembro";

      break;
    case 11:
      return "Novembro";

      break;
    case 12:
      return "Dezembro";

      break;
    default:
      break;
  }
};

export const numberOnly = ($event) => {
  let keycode = $event.keyCode ? $event.keyCode : $event.which;

  if ((keycode < 48 || keycode > 57) && keycode !== 46) {
    $event.preventDefault();
  }
};

// get data from localstorage
let storedDistricts = JSON.parse(localStorage.getItem("districts")) || [];
/**
 * Responsavel por armazenar o distritos no localstorage
 * @returns districts object
 *
 */
export const districts = async () => {
  try {
    if (storedDistricts.length > 0) {
      return storedDistricts;
    }

    const districts = await axios.get(URL_API + "districts");

    localStorage.setItem("districts", JSON.stringify(districts.data.data));
    return districts;
  } catch (error) {
    console.log(error);
  }
};
