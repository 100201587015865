<template>
  <footer class="footer">
Todos Direitos Reservados © {{year}}  
  <p class="text-center">Sistema Desenvolvido Pela Equipa da Isupec</p>
  </footer>
</template>

<script setup>
  let year = new Date().getFullYear()
</script>
