import router from "@/router";
import axios from "axios";

const URL_API = process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    role_permission: {},
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    role_permission(state) {
      return state.role_permission;
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_USER_ROLE_PERMISSION(state, value) {
      state.role_permission = value;
    },
  },
  actions: {
    async login({ commit }, dat) {
      try {
        const response = await axios.get(URL_API + "user", {
          headers: {
            Authorization: "Bearer " + dat.access_token,
          },
        });

        const data = response.data;
        commit("SET_USER", data);
        commit("SET_AUTHENTICATED", true);
        await this.dispatch("auth/RolePermissions", {
          dat,
          role: data.data.role[0],
        });

        if (
          data.data.role[0] == "Admin" ||
          data.data.role[0] == "Secretaria" ||
          data.data.role[0] == "Master" ||
          data.data.role[0] == "Testers"
        ) {
          // router.push({ path: '/dashboard' });
          window.location.href = "/dashboard";
        } else if (data.data.role[0] == "student") {
          // router.push({ path: '/Home' });
          window.location.href = "/Home";
        } else if (data.data.role[0] == "Teacher") {
          router.push({ path: "/Teacher" });
        } else {
          router.push({ path: "/Student" });
        }
      } catch (error) {
        commit("SET_USER", {});
        commit("SET_AUTHENTICATED", false);
      }
    },

    async RolePermissions({ commit }, { dat, role }) {
      try {
        const response = await axios.get(URL_API + `getRoleGroup/${role}`, {
          headers: {
            Authorization: "Bearer " + dat.access_token,
          },
        });

        const data = response.data;
        commit("SET_USER_ROLE_PERMISSION", data);
      } catch (error) {
        commit("SET_USER_ROLE_PERMISSION", {});
      }
    },

    logout({ commit }) {
      commit("SET_USER", {});
      commit("SET_USER_ROLE_PERMISSION", {});
      commit("SET_AUTHENTICATED", false);
    },
  },
};
