

export default {
    namespaced:true,
    state:{
        subject_weight:[]
    },
    getters:{

    },
    mutations:{
        SET_WEIGHTS(state, payload){

            state.subject_weight = payload;
        },
        SET_WEIGHT_EMPTY(state, payload){
            state.subject_weight = payload;
        }

    },
    actions:{
        add_weights({commit}, payload){
            commit("SET_WEIGHTS", payload);
        },
        Empty_weight({commit}){
            commit("SET_WEIGHT_EMPTY", []);
        }
    }
}

 