<template>
  <div class="page-title-box">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h4 class="page-title">Aulas</h4>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-right">
          <li class="breadcrumb-item"><a href="#">Inicio</a></li>
          <li class="breadcrumb-item">Professor</li>
          <li class="breadcrumb-item active">Aulas</li>
        </ol>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-sm-6">
              <h5 class="page-title">Material das Aulas</h5>
            </div>
            
          </div>
        </div>
 
        <div class="card-body">
          <div class="form-group float-right m-2">
            <button class="btn btn-primary" @click="addLecture"> <i class="fas fa-plus"></i> Novo ficheiro</button>
            <!-- <form action="" role="search" class="app-search">
              <input
                type="text"
                class="form-control"
                 placeholder="Pesquisar..."
              />
              <button type="submit"><i class="fa fa-search"></i></button>
            </form> -->
          </div>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Título</th>
                  <th scope="col">Descrição</th>
                  <th scope="col">Formato</th>
                  <th scope="col">Acções</th>
                
                </tr>
              </thead>
              <tbody>
                <tr v-for="(lect, index) in lectures" :key="index">
                  <td>{{ index + 1 }}</td>
                   <td>{{lect.title}}</td>
                   <td>{{lect.description}}</td>
                   <td>{{formatoFile(lect.attachmnts)}}</td>
                   <td>
                   <button class="btn btn-success" title="Baixar o ficheiro">
                   <i class="fas fa-download"></i> 
                   </button>
                   &nbsp;
                    <button class="btn btn-info" title="Editar o fichiro">
                    <i class="fas fa-edit"></i>
                    </button>
                    &nbsp;
                   <button title="Eliminar o ficheiro" class="btn btn-danger"> <i class="fas fa-trash-alt "></i></button>
                  </td>
                </tr>
              </tbody>
            </table>

            <!-- <Page :total="department.total" :model-value="department.current_page"  :page-size="parseInt(department.per_page)" @on-change="getAllDepartment" v-if="department.total"   class="float-right" /> -->
          </div>
        </div>
      </div>
    </div>
  </div>

        
</template>

<script setup>
import { onMounted, ref } from 'vue'
import axios from 'axios';
import authHeader from '@/Api/AuthHeader';
import { useRoute, useRouter } from 'vue-router';
  

  const router = useRouter();
  const lectures = ref([]);

        onMounted(()=>{
            getLectures();
        })


        async function getLectures(){

            const resp = axios.get('/api/get-all_lectures', {headers:authHeader()})
            .then((response)=>{
              lectures.value = response.data.data 
                console.log(response);
            })
            .catch((error)=>{
                console.log(error);
            })

        }

        function addLecture(){

          router.push("/create-new-lecture")

        }

        function formatoFile(file){
          const format = file.split('.').pop();
          const formatArchive = format.toLowerCase();
           return formatArchive;

        }

   
</script>