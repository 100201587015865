import axios from "axios";
import authHeader from "@/Api/AuthHeader";

const URL_API = process.env.VUE_APP_URL_API;

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createTurma(TurmaData) {
      return axios.post(URL_API + "turmas", TurmaData, {
        headers: authHeader(),
      });
    },

    getDepartment() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API + "getAlldepartment", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getAllTurmas(queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            URL_API +
              "turmas?page=" +
              queryParams.page +
              "&search=" +
              queryParams.search,
            { headers: authHeader() }
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // Get All classmates without pagination
    getTurmas() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API + "getTurmas", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getAcademicYear() {
      return new Promise((resolve, reject) => {
        axios
          .get(URL_API + "academic_year", { headers: authHeader() })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // updateTurma(ctx, dataTurma) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .put("/api/turmas/" + dataTurma.id, dataTurma.data, {
    //         headers: authHeader(),
    //       })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },

    updateTurma(dataTurma) {
      return axios.patch(
        URL_API + `turmas/${dataTurma.classmate_id}`,
        dataTurma,
        {
          headers: authHeader(),
        }
      );
    },

    // viewClassMates(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get("/api/turmas/" + id, { headers: authHeader() })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },

    async viewClassMates(id) {
      try {
        const response = await axios.get(URL_API + "turmas/" + id, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    async deleteTurma(id) {
      try {
        const response = await axios.delete(URL_API + "turmas/" + id, {
          headers: authHeader(),
        });
        return response;
      } catch (error) {
        return error;
      }
    },

    getTurmaWithDetails(data) {
      return axios.get(
        URL_API + `getTurmaWithDetails?page=${data.page}&search=${data.search}`,
        { headers: authHeader() }
      );
    },
  },
};
